import React, { useState, useEffect } from "react";

import {
  Layout,
  Button,
  Table,
  Row,
  Col,
  Card,
  Space,
  Radio,
  Modal,
  Input,
  Checkbox,
  message,
  Upload,
} from "antd";
import {
  DownloadOutlined,
  PlayCircleFilled,
  PauseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import _ from "lodash";
import moment from "moment";

import useRecorder from "./useRecorder";
import Audio from "./Audio";
import axios from "axios";

import BugEditForm from "./BugEditForm";
import firebase from "firebase";
import { db, storage } from "utils/firebase";
import useAuth from "hooks/useAuth";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import "./styles.less";
import { useNavigate } from "react-router";

import UserTranscriptsLayout from "../../../components/web/Layout/UserTranscripts";
import { IS_NTTDATA_ON_PREMISE } from "utils/constants";

const { confirm } = Modal;

const { Dragger } = Upload;

const { REACT_APP_TENANT_ID } = process.env;

const Users = () => {
  const { t } = useTranslation();
  const language = getLanguages(t);

  const navigate = useNavigate();

  const { user, token } = useAuth();
  const [lang, setLang] = useState("jp");

  let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();
  const [startRec, setStartRec] = useState(false);

  //Audio file
  const [baseAudioUrl, setBaseAudioUrl] = useState("");
  const [audioName, setAudioName] = useState("");

  const [transcript, setTranscript] = useState("");
  const [msgRec, setMsgRec] = useState(language.secLimit);
  const [timeoutIdRec, setTimeoutIdRec] = useState(-1);
  const [timeIntervalIdRec, setTimeIntervalIdRec] = useState(-1);

  const [base64data, setBase64data] = useState();
  const [checked, setChecked] = useState(false);
  const [transcriptEdit, setTranscriptEdit] = useState("");

  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [bug, setBug] = useState();
  const [bugs, setBugs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dicts, setDicts] = useState([]);

  useEffect(() => {
    if (user) {
      setLoading(true);
      if (!IS_NTTDATA_ON_PREMISE) {
        db.collection("bugs")
          .where("user", "==", user?.uid)
          .orderBy("createdAt", "desc")
          .onSnapshot(async (snapshot) => {
            console.log(snapshot.docs);
            console.log(user);
            const bugs = await Promise.all(
              snapshot.docs.map(async (doc) => {
                return {
                  id: doc.id,
                  ...doc.data(),
                  base64data: await storage
                    .ref("audio")
                    .child(doc.data()?.base64data)
                    .getDownloadURL(),
                  createdAt: doc.data()?.createdAt.toDate(),
                };
              })
            ).catch((err) => {
              return [];
            });

            setBugs(bugs);
          });

        db.doc(`users/${user.uid}`)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              return;
            }
            setDicts(doc.data()?.dicts || []);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    setMsgRec(language.secLimit);
  }, [language.secLimit]);

  const columns = [
    {
      title: language.createdAt,
      align: "center",
      dataIndex: "createdAt",
      width: "5%",
      render: (record) => {
        return (
          <div>
            <div>{moment(record).format("YYYY-MM-DD HH:mm")}</div>
          </div>
        );
      },
    },
    {
      title: language.transcript,
      dataIndex: "Transcript",
      width: "20%",
      render: (text, record, index) => {
        return (
          <div style={{ whiteSpace: "pre-line" }}>{record.transcript}</div>
        );
      },
    },
    {
      title: language.correctedTranscript,
      dataIndex: "transcriptEdit",
      width: "20%",
      render: (text, record, index) => {
        return (
          <div style={{ whiteSpace: "pre-line" }}>{record.transcriptEdit}</div>
        );
      },
    },
    {
      title: language.audio,
      align: "center",
      width: "3%",
      render: (record) => {
        return (
          <audio
            id="audio_player"
            preload="none"
            src={record?.base64data}
            controls="controls"
            autobuffer="autobuffer"
          />
        );
      },
    },
    {
      title: language.actions,
      align: "center",
      width: "5%",
      render: (record) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClickCapture={() => {
                setBug(record);
                setIsUpdateModal(true);
              }}
            >
              {language.btnEdit}
            </Button>
            <Button
              icon={<DeleteOutlined />}
              type="link"
              danger
              onClickCapture={() => {
                confirm({
                  title: language.doYouWantToDeleteTheseItems,
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    setLoading(true);
                    db.doc(`/bugs/${record?.id}`)
                      .delete()
                      .then(async (doc) => {
                        await storage.refFromURL(record?.base64data).delete();
                        setLoading(false);
                      })
                      .catch((err) => {
                        setLoading(false);
                        console.log(err);
                      });
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              }}
            >
              {language.btnDelete}
            </Button>
          </div>
        );
      },
    },
  ];

  const onSelectFile = (e) => {
    if (e.file.name) {
      const fileURL = URL.createObjectURL(e.file);
      setBaseAudioUrl(fileURL);
      setAudioName(e.file.name);
    }
  };

  const props = {
    name: "file",
    multiple: false,
    customRequest: onSelectFile,
    showUploadList: false,
  };

  const startRecord = () => {
    if (startRec) {
      clearTimeout(timeoutIdRec);
      clearInterval(timeIntervalIdRec);
      setStartRec(false);
      stopRecording();
    } else {
      //log.debug("[App] start record " + isRecording);
      setStartRec(true);
      startRecording();
      let count = 600;
      setMsgRec(`${count} ${language.seconds} .`);
      const intevalRec = setInterval(() => {
        count -= 1;
        if (count % 3 === 0) {
          setMsgRec(`${count} ${language.seconds} .`);
        } else if (count % 3 === 1) {
          setMsgRec(`${count} ${language.seconds} . .`);
        } else {
          setMsgRec(`${count} ${language.seconds} . . .`);
        }
      }, 1000);
      setTimeIntervalIdRec(intevalRec);
      setTimeoutIdRec(
        setTimeout(() => {
          //log.debug("[App] auto stop");
          setStartRec(false);
          stopRecording();
          clearInterval(intevalRec);
        }, 600000)
      );
    }
  };

  useEffect(() => {
    setBaseAudioUrl(audioURL);
    setAudioName(language.recordAudio);
  }, [audioURL]);

  //Transcript
  useEffect(() => {
    const sendData = async () => {
      if (baseAudioUrl?.length > 0) {
        setTranscript(language.predicting);
        const blob = await fetch(baseAudioUrl).then((r) => r.blob());
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          setBase64data(base64data);

          axios
            .post(`${process.env.REACT_APP_MEETING_API_URL}/transcript`, {
              audio: base64data,
              lang,
              dict: dicts.map((item) => {
                let word2 =
                  item?.word2 === undefined || item?.word2 === ""
                    ? "*"
                    : item?.word2;
                let word4 =
                  item?.word4 === undefined || item?.word4 === ""
                    ? "*"
                    : item?.word4;

                let word =
                  item.word1 +
                  ", " +
                  word2 +
                  ", " +
                  item.word3 +
                  ", " +
                  word4 +
                  ", " +
                  item.word5;
                return word;
              }),
            })
            .then((res) => {
              setTranscript(res.data.transcript);
              setTranscriptEdit(res.data.transcript);
            });
        };
      }
    };
    sendData();
  }, [baseAudioUrl]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const createBug = async (transcriptEdit, transcript, base64data, user) => {
    setLoading(true);
    await db
      .collection("bugs")
      .add({
        transcriptEdit,
        transcript,
        createdAt: firebase.firestore.Timestamp.now(),
        user: user?.uid,
        tenantId: REACT_APP_TENANT_ID,
      })
      .then(async (doc) => {
        const fileName = `${doc.id}.wav`;
        const file = dataURLtoFile(base64data, fileName);
        await storage.ref(`audio/${fileName}`).put(file);

        await db
          .collection("bugs")
          .doc(doc.id)
          .update({
            base64data: `${fileName}`,
          });

        setLoading(false);
        setChecked(false);
        message.success(language.bugSuccess);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <UserTranscriptsLayout />
      <Layout className="jobs-page">
        <Layout style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Card style={{ marginBottom: 18 }} bordered={false}>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              justify="space-between"
            >
              <Col className="gutter-row" span={12}>
                {language.selectLanguage}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24} style={{ position: "relative" }}>
                <Radio.Group
                  onChange={(e) => setLang(e.target.value)}
                  value={lang}
                >
                  <Space direction="vertical">
                    <Radio value={"jp"}>{language.japanese}</Radio>
                    <Radio value={"vi"}>{language.vietnamese}</Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: 24 }}>
              <Col span={12}>
                {/* <Input
                      id='audio'
                      name="audio"
                      placeholder="Audio"
                      accept="audio/*" 
                      //accept="video/mp4,video/x-m4v,video/*, audio/*" 
                      //accept='audio/webm, video/webm'
                      type="file"
                      hidden
                      onClick={(e) => {
                        e.currentTarget.value = null;
                      }}
                      onChange={onSelectFile}
                    /> */}
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <DownloadOutlined
                      style={{ fontSize: 36, color: "inherit" }}
                    />
                  </p>
                  <p className="ant-upload-text">
                    {language.selectDragDropAudio}
                  </p>
                </Dragger>
                {/* <label htmlFor="audio">
                      <Card style={{height: 140}}>
                        <p style={{textAlign: 'center'}}>
                        <DownloadOutlined style={{fontSize: 36}} />
                        </p>
                        <p style={{textAlign: 'center'}}>{audioURL ? <span style={{fontWeight: '600'}}>{audioName}</span> : language.selectDragDropAudio}</p>
                        {audioURL && (
                          <p style={{textAlign: 'center'}}>
                            <a onClick={(e) => {
                              e.preventDefault()
                              setAudioName()
                              setBaseAudioUrl()
                            }}>{language.btnDelete}</a>
                          </p>
                        )}
                      </Card>
                    </label> */}
              </Col>
              <Col span={12}>
                <Card style={{ height: 140 }}>
                  <p style={{ textAlign: "center" }}>
                    {startRec ? (
                      <a onClick={startRecord}>
                        <PauseCircleFilled
                          style={{ fontSize: 24, color: "#BD271E" }}
                        />
                        <span
                          style={{
                            fontSize: 24,
                            color: "#BD271E",
                            marginLeft: 10,
                          }}
                        >
                          {language.btnStop}
                        </span>
                      </a>
                    ) : (
                      <a onClick={startRecord}>
                        <PlayCircleFilled
                          style={{ fontSize: 24, color: "#BD271E" }}
                        />
                        <span
                          style={{
                            fontSize: 24,
                            color: "#BD271E",
                            marginLeft: 10,
                          }}
                        >
                          {language.btnRecord}
                        </span>
                      </a>
                    )}
                    <br />
                    {msgRec}
                  </p>
                </Card>
              </Col>
            </Row>
            {baseAudioUrl && (
              <Row style={{ marginTop: 10 }}>
                <Col span={24}>
                  <Audio
                    audioName={audioName}
                    playerID={"player"}
                    audioUrl={baseAudioUrl}
                    audioDuration={10}
                  />
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>{transcript}</Col>
            </Row>
            {transcript && (
              <>
                <Row style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <Checkbox
                      value={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                      }}
                    >
                      {language.haveBugs}
                    </Checkbox>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <Input.TextArea
                      disabled={!checked}
                      rows={10}
                      value={transcriptEdit}
                      onChange={(e) => {
                        setTranscriptEdit(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <Button
                      loading={loading}
                      type="primary"
                      onClick={() => {
                        createBug(transcriptEdit, transcript, base64data, user);
                      }}
                    >
                      {language.btnSave}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Card>

          <Table
            loading={loading}
            bordered
            columns={columns}
            dataSource={bugs}
            pagination={{
              defaultPageSize: 20,
            }}
          />
        </Layout>
        <Modal
          visible={isUpdateModal}
          title={language.correctedTranscript}
          onCancel={() => setIsUpdateModal(false)}
          destroyOnClose={true}
          width={570}
          centered
          footer={null}
        >
          <BugEditForm
            bug={bug}
            onCancel={() => setIsUpdateModal(false)}
            onCallback={() => {
              setIsUpdateModal(false);
            }}
          />
        </Modal>
      </Layout>
    </>
  );
};

export default Users;
