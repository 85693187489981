import React, { memo } from "react";
import { renderToString } from "react-dom/server";

import _isEqual from "lodash/isEqual";
import { convertToTranscriptSegments } from "utils/editor";
import loading from "../../assets/loading.gif";
import { IS_NTTDATA } from "utils/constants";

const WordNode = ({
  index,
  transcripts,
  words,
  sentenceOffset,
  highlight,
  last,
  isSpeaking,
  speaker,
  divId,
}) => {
  const transcriptSegments = convertToTranscriptSegments(
    transcripts,
    words,
    sentenceOffset,
    highlight
  );

  const __html =
    IS_NTTDATA
      ? renderToString(
          [...transcriptSegments, null].map((t, index) => {
            if (t) {
              let clName = t.type;

              const onlySpaces = t.transcript.trim() === "";

              return (
                <span
                  key={`wrapper-${sentenceOffset}-${index}`}
                  id={`word-${sentenceOffset}-${index}`}
                >
                  <span
                    className={clName}
                    key={`inside-${sentenceOffset}-${index}`}
                    id={`inside-${sentenceOffset}-${index}`}
                  >
                    {onlySpaces
                      ? "\u00A0".repeat(t.transcript.length)
                      : t.transcript}
                  </span>
                </span>
              );
            } else {
              if (last && isSpeaking[speaker] && (isSpeaking[speaker].is_speaking ?? isSpeaking[speaker].isSpeaking)) {
                return (
                  <span>
                    <img
                      src={loading}
                      alt="loading"
                      style={{ marginLeft: "0.5rem", height: "10px" }}
                    />
                  </span>
                );
              } else {
                return null;
              }
            }
          })
        )
      : renderToString(
          transcriptSegments.map((t, index) => {
            let clName = t.type;

            const onlySpaces = t.transcript.trim() === "";

            return (
              <span
                key={`wrapper-${sentenceOffset}-${index}`}
                id={`word-${sentenceOffset}-${index}`}
              >
                <span
                  key={`space-${sentenceOffset}-${index}`}
                  id={`space-${sentenceOffset}-${index}`}
                >
                  {index !== 0 && " "}
                </span>
                <span
                  className={clName}
                  key={`inside-${sentenceOffset}-${index}`}
                  id={`inside-${sentenceOffset}-${index}`}
                >
                  {onlySpaces
                    ? "\u00A0".repeat(t.transcript.length)
                    : t.transcript}
                </span>
              </span>
            );
          })
        );

  return (
    <div
      id={divId}
      className="transcriptEditor-editor"
      style={{ backgroundColor: index % 2 === 0 ? "#f8f8f8" : "#fff" }}
      dangerouslySetInnerHTML={{ __html }}
    />
  );
};

function checkHighlightNTTData(prevProps, nextProps) {
  return (
    (prevProps.sentenceOffset === prevProps.highlight[0] &&
      nextProps.sentenceOffset !== nextProps.highlight[0]) ||
    (prevProps.sentenceOffset !== prevProps.highlight[0] &&
      nextProps.sentenceOffset === nextProps.highlight[0]) ||
    (nextProps.sentenceOffset === nextProps.highlight[0] &&
      !_isEqual(prevProps.highlight, nextProps.highlight))
  );
}

function checkHighlightSaaS(prevProps, nextProps) {
  const isPrevHighlighted =
    prevProps.sentenceOffset <= prevProps.highlight[0] &&
    prevProps.highlight[0] <=
      prevProps.sentenceOffset + prevProps.words.length - 1;
  const isNextHighlighted =
    nextProps.sentenceOffset <= nextProps.highlight[0] &&
    nextProps.highlight[0] <=
      nextProps.sentenceOffset + nextProps.words.length - 1;
  return (
    (isPrevHighlighted && !isNextHighlighted) ||
    (!isPrevHighlighted && isNextHighlighted) ||
    (isNextHighlighted && !_isEqual(prevProps.highlight, nextProps.highlight))
  );
}

export default memo(WordNode, (prevProps, nextProps) => {
  const checkHighlight =
    IS_NTTDATA
      ? checkHighlightNTTData
      : checkHighlightSaaS;
  if (checkHighlight(prevProps, nextProps)) {
    return false;
  }
  if (!_isEqual(prevProps.speaker, nextProps.speaker)) {
    return false;
  }
  if (!_isEqual(prevProps.last, nextProps.last)) {
    return false;
  }
  if (!_isEqual(prevProps.isSpeaking, nextProps.isSpeaking)) {
    return false;
  }
  if (!_isEqual(prevProps.transcripts, nextProps.transcripts)) {
    return false;
  }
  if (!_isEqual(prevProps.words, nextProps.words)) {
    return false;
  }
  if (!_isEqual(prevProps.indexParagraph, nextProps.indexParagraph)) {
    return false;
  }
  return true;
});
