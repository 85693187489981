/* eslint-disable react-hooks/rules-of-hooks */

import React, { useRef, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
    SaveOutlined,
    DownloadOutlined,
    RetweetOutlined,
    FilterOutlined,
} from "@ant-design/icons";
import {
    Button,
    Row,
    Col,
    Typography,
    Divider,
    Input,
    Popover,
    Slider,
    message,
    Dropdown,
    Menu,
    Skeleton,
    Space,
} from "antd";
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    StepForwardOutlined,
    PauseOutlined,
    PlayCircleOutlined,
    WifiOutlined,
    CheckOutlined,
} from "@ant-design/icons";
import TranscriptEditor from "components/TranscriptEditor";
import {
    exportToJsonFile,
    formatTime,
    convertToDatetime,
    exportToTextFile,
    filterSentences,
} from "utils/utils";
import "./styles.less";
import { db } from "utils/firebase";
import useAuth from "hooks/useAuth";
import { cleanSentenceWords, replaceSentencesWithDict } from "utils/editor";
import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";
import {
    IS_FUNIX,
    IS_NTTDATA,
    IS_NTTDATA_ON_PREMISE,
    IS_SAFARI,
    MAX_SPEAKER_SHOW,
} from "utils/constants";
import { storage } from "utils/firebase";
import { Wave } from "@foobar404/wave";
import moment from "moment";
import { Tasks } from "apis/ai";
import SafariModal from "components/SafariModal";
import { editSessionTranscripts, getSession, getSessionEditedTranscripts, getSessionTranscripts, updateSession } from "apis/meeting";
import { io } from "socket.io-client";
import { dictList } from "apis/dict";
import { getFileDownloadURL } from "apis/file";
import _ from "lodash";

const { Text } = Typography;
const { TextArea } = Input;

const { REACT_APP_TENANT_ID } = process.env;

// Check url return true if video, false if audio
const checkUrl = (url) => {
    // match regex for video from any url
    const regex = /(.*?).(mp4|webm|ogg|ogv|avi|wmv|mov|flv|3gp|mkv|mpeg|mpg)/g;
    return regex.test(url);
};

const stateDefault = {
    playing: false,
    volume: 0.8,
    muted: false,
    played: 0,
    duration: 0,
    playedString: "",
    durationString: "",
    loaded: 0,
    playbackRate: 1.0,
};

let start_timestamp;

const MeetingDetailOnPremise = () => {
    //ref
    const vidRef = useRef();
    const { user, loading } = useAuth();

    // Wave Visualizer
    let canvasElm = useRef();

    // state
    const [mediaUrl, setMediaUrl] = useState("");
    const [sentences, setSentences] = useState([]);
    const [speaking, setSpeaking] = useState({});
    const [safariModal, setSafariModal] = useState(false);
    const [replacedDict, setReplacedDict] = useState(false);
    const [videoState, setVideoState] = useState(stateDefault);
    const [fileName, setFileName] = useState(null);
    const [loadingTranscription, setLoadingTranscription] = useState(true);
    const [loadingRevert, setLoadingRevert] = useState(false);
    const [filterAll, setFilterAll] = useState(true);
    const { t, i18n } = useTranslation();
    const lang = getLanguages(t);
    const [saveLoading, setSaveLoading] = useState(false);
    const [notes, setNotes] = useState("");

    const id = useRef();
    const latestSentencesRef = useRef(sentences);
    latestSentencesRef.current = sentences;

    //Hook
    const { meetingId: videoId, mode } = useParams();

    //Socket
    const transcriptSocket = useRef(null);

    const handlePlayPause = () => {
        if (vidRef.current && (vidRef.current?.paused || vidRef.current?.ended)) {
            vidRef.current.play();
        } else {
            vidRef.current.pause();
        }
    };

    const handleSeekChange = (value) => {
        const timeInSeconds = value / 10000000;
        const time = formatTime(timeInSeconds);
        if (vidRef.current) vidRef.current.pause();
        setVideoState((e) => ({
            ...e,
            played: timeInSeconds,
            playedString: `${time.hours !== "00" ? `${time.hours}:` : ""}${time.minutes
                }:${time.seconds}`,
        }));
    };

    const handleSeekMouseUp = (value) => {
        if (vidRef.current) vidRef.current.play();
        vidRef.current.currentTime = value / 10000000;
    };

    const updateVolume = (value) => {
        setVideoState((e) => ({
            ...e,
            volume: value / 10,
        }));
        if (vidRef.current?.muted) {
            vidRef.current.muted = false;
        }
        vidRef.current.volume = value / 10;
    };

    const updatePlaybackRate = () => {
        const playbackRate = videoState.playbackRate;
        if (playbackRate === 3) {
            setVideoState((e) => ({
                ...e,
                playbackRate: 1,
            }));
            vidRef.current.playbackRate = 1;
        } else {
            setVideoState((e) => ({
                ...e,
                playbackRate: playbackRate + 1,
            }));
            vidRef.current.playbackRate = playbackRate + 1;
        }
    };

    const changeTime = useCallback(
        (value) => {
            if (mode !== "edit") return;
            const time = formatTime(value);
            setVideoState((e) => ({
                ...e,
                played: value,
                playedString: `${time.hours !== "00" ? `${time.hours}:` : ""}${time.minutes
                    }:${time.seconds}`,
            }));
            vidRef.current.currentTime = value;
            if (vidRef.current.paused) {
                vidRef.current.play();
            }
        },
        [mode]
    );

    const handleExportClick = async (key) => {
        let date;
        if (IS_NTTDATA_ON_PREMISE) {
            try {
                date = moment((await getSession(videoId)).data?.session?.createdAt).format("ll");
            }
            catch {
                date = moment().format("ll");
            }
        } else {
            date = moment(
                await (
                    await db
                        .collection(IS_NTTDATA ? "meetings" : "files")
                        .doc(videoId)
                        .get()
                )
                    .data()
                    .createdAt?.toDate()
            ).format("ll");
        }
        switch (key) {
            case 0:
                exportToTextFile(
                    sentences,
                    `${date}_${IS_NTTDATA
                        ? videoId.includes("_")
                            ? videoId.split("_").slice(0, -1).join("_")
                            : videoId
                        : fileName.split(".")[0]
                    }`,
                    true,
                    {
                        started: lang.transcriptionStarted,
                        stopped: lang.transcriptionStopped,
                        close: lang.meetingClose,
                    },
                    i18n.language
                );
                break;
            case 1:
                exportToTextFile(
                    sentences,
                    `${date}_${IS_NTTDATA
                        ? videoId.includes("_")
                            ? videoId.split("_").slice(0, -1).join("_")
                            : videoId
                        : fileName.split(".")[0]
                    }`,
                    false,
                    {
                        started: lang.transcriptionStarted,
                        stopped: lang.transcriptionStopped,
                        close: lang.meetingClose,
                    }
                );
                break;
            case 2:
                exportToJsonFile(
                    { sentences },
                    videoId.includes("_")
                        ? videoId.split("_").slice(0, -1).join("_")
                        : videoId
                );
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (!canvasElm.current) return;
        if (!vidRef) return;

        const temp = new Wave(vidRef.current, canvasElm.current);

        temp.addAnimation(
            new temp.animations.Lines({
                fillColor: "#4a90e2",
                lineColor: "#4a90e2",
                center: true,
                mirroredY: true,
            })
        );
    }, [vidRef]);

    if (IS_NTTDATA) {
        useEffect(() => {
            // If the user is at the bottom of the transcript, scroll to the bottom
            // when new sentences are added

            const oldScrollHeight =
                document.getElementsByClassName("edit-leftcol")[0].scrollHeight;

            return () => {
                const ele = document.getElementsByClassName("edit-leftcol")[0];
                const { scrollTop, scrollHeight, clientHeight } = ele;
                // console.log(scrollTop + clientHeight, scrollHeight, oldScrollHeight);
                if (Math.abs(scrollTop + clientHeight - oldScrollHeight) <= 10) {
                    // console.log("scroll to bottom");
                    if (ele)
                        ele.scrollTo({
                            top: scrollHeight,
                            behavior: "auto",
                        });
                }
            };
        }, [sentences, loadingTranscription, speaking]);

        useEffect(() => {
            if (mode === "edit") return;
            //Listen sentences update and speakers
            if (!transcriptSocket.current) {
                transcriptSocket.current = io(process.env.REACT_APP_API + "/transcript", {
                    transports: ["websocket"],
                    query: {
                        sessionId: videoId
                    }
                });
                transcriptSocket.current
                    .on("connect", () => {
                        console.log("transcript socket connected")
                    }).on("disconnect", (e) => {
                        console.log(e, "transcript socket disconnected")
                    })
                    .on("speakers", speakers => {
                        const speakingOBj = {};
                        speakers.forEach((speaker) => {
                            speakingOBj[speaker.id] = speaker;
                        });
                        setSpeaking(speakingOBj);
                    })
                    .on("speaker", speaker => {
                        setSpeaking(speakingOBj => {
                            const newSpeakingOBj = Object.assign({}, speakingOBj);
                            newSpeakingOBj[speaker.id] = speaker;
                            return newSpeakingOBj
                        });
                    })
                    .on("transcripts", async (transcripts) => {
                        transcripts = transcripts.map((transcript) => {
                            return {
                                ...transcript,
                                checked: !!latestSentencesRef.current.find(
                                    (e) => e.id === transcript.id
                                )?.checked,
                                words: !_.isEmpty(transcript.words) ? transcript.words : [
                                    {
                                        start: transcript.start,
                                        end: transcript.end,
                                        word: transcript.transcript,
                                        conf: 1,
                                    },
                                ],
                            };
                        });
                        setSentences(filterSentences(transcripts))
                    })
                    .on("transcript", async (transcript) => {
                        transcript = {
                            ...transcript,
                            checked: !!latestSentencesRef.current.find(
                                (e) => e.id === transcript.id
                            )?.checked,
                            words: !_.isEmpty(transcript.words) ? transcript.words : [
                                {
                                    start: transcript.start,
                                    end: transcript.end,
                                    word: transcript.transcript,
                                    conf: 1,
                                },
                            ],
                        }
                        setSentences(transcripts => filterSentences(_.sortBy(_.unionBy([transcript], transcripts, "id"), ["timestamp", "timecheck"])))
                    })
                return () => {
                    // eslint-disable-next-line no-unused-expressions
                    transcriptSocket.current?.close();
                    transcriptSocket.current = null;
                }
            }
        }, [videoId, mode]);

        useEffect(() => {
            if (!user) return;

            (async () => {
                const session = (await getSession(videoId)).data.session;
                if (!session) {
                    return;
                }
                const audioLength = session.length;

                if (audioLength) {
                    getFileDownloadURL(`${videoId}/full.wav`).then(({ data: { url } }) => {
                        setMediaUrl(url);
                        const time = formatTime(audioLength);
                        setVideoState((e) => ({
                            ...e,
                            duration: audioLength,
                            durationString: `${time.hours !== "00" ? `${time.hours}:` : ""
                                }${time.minutes}:${time.seconds}`,
                        }));
                    })
                } else {
                    if (mode === "edit") setSentences(null);
                    return;
                }

                start_timestamp = session.startTimestamp
                setNotes(session.notes);

                const editedTranscripts = (await getSessionEditedTranscripts(videoId)).data.transcripts;
                if (editedTranscripts && editedTranscripts.length !== 0) {
                    const data = editedTranscripts.map((transcript) => {
                        if (
                            !transcript.status &&
                            (!transcript.words ||
                                !Array.isArray(transcript.words) ||
                                transcript.words.length === 0)
                        ) {
                            transcript.words = [];
                            transcript.words.push({
                                start: transcript.start,
                                end: transcript.end,
                                word: transcript.transcript,
                                conf: 1,
                            });
                        }
                        return transcript;
                    })

                    const audioTranscripts = start_timestamp
                        ? data.map((e) => {
                            const start = e.timestamp - start_timestamp;
                            return {
                                ...e,
                                words:
                                    e.words?.map((word) => {
                                        return {
                                            ...word,
                                            start: word.start - e.start + start,
                                            end: word.end - e.start + start || 0,
                                        };
                                    }) || [],
                                start: start,
                                end: start + e.duration || 0,
                            };
                        })
                        : data;

                    setSentences(filterSentences(audioTranscripts));
                } else {
                    const transcripts = (await getSessionTranscripts(videoId)).data.transcripts;
                    if (transcripts.length === 0) {
                        message.error("No transcript found");
                        setSentences(null);
                        return;
                    }

                    const data = transcripts.map((transcript) => {
                        if (
                            !transcript.status &&
                            (!transcript.words ||
                                !Array.isArray(transcript.words) ||
                                transcript.words.length === 0)
                        ) {
                            transcript.words = [];
                            transcript.words.push({
                                start: transcript.start,
                                end: transcript.end,
                                word: transcript.transcript,
                                conf: 1,
                            });
                        }
                        return transcript;
                    })

                    const audioTranscripts = start_timestamp
                        ? data.map((e) => {
                            const start = e.timestamp - start_timestamp;
                            return {
                                ...e,
                                words:
                                    e.words?.map((word) => {
                                        return {
                                            ...word,
                                            start: word.start - e.start + start,
                                            end: word.end - e.start + start || 0,
                                        };
                                    }) || [],
                                start: start,
                                end: start + e.duration || 0,
                            };
                        })
                        : data;

                    setSentences(filterSentences(audioTranscripts));
                    return;
                }

            })();

            localStorage.removeItem(`nami-transcript-${videoId}`);
        }, [videoId, user, mode]);

        useEffect(() => {
            if (
                loadingTranscription &&
                user &&
                (!sentences || sentences.length > 0)
            ) {
                if (!replacedDict && sentences && sentences.length > 0) {
                    if (IS_NTTDATA_ON_PREMISE) {
                        dictList().then(({ data: { dicts } }) => {
                            setReplacedDict(true);
                            setSentences((e) => replaceSentencesWithDict(e, dicts));
                            setLoadingTranscription(false);
                            message.success(lang.replacedDict);
                        })
                    } else {
                        let query = db.collection("dicts");

                        if (IS_FUNIX)
                            query = query.where("tenantId", "==", REACT_APP_TENANT_ID);
                        else query = query.where("userId", "==", user?.uid);

                        query.get().then((querySnapshot) => {
                            const dicts = querySnapshot.docs.map((doc) => ({
                                ...doc.data(),
                            }));
                            setReplacedDict(true);
                            setSentences((e) => replaceSentencesWithDict(e, dicts));
                            setLoadingTranscription(false);
                            message.success(lang.replacedDict);
                        });
                    }
                } else {
                    setLoadingTranscription(false);
                }
            }
        }, [
            sentences,
            replacedDict,
            loadingTranscription,
            user,
            lang.replacedDict,
        ]);
    } else {
        useEffect(() => {
            if (user && sentences.length > 0) {
                if (!replacedDict) {
                    setReplacedDict(true);
                    setLoadingTranscription(true);
                    let query = db.collection("dicts");

                    if (IS_FUNIX)
                        query = query.where("tenantId", "==", REACT_APP_TENANT_ID);
                    else query = query.where("userId", "==", user?.uid);

                    query
                        .orderBy("sortBy", "asc")
                        .get()
                        .then(async (data) => {
                            let dicts = await Promise.all(
                                data.docs.map((doc) => {
                                    let item = doc.data();
                                    return {
                                        dictId: doc.id,
                                        ...item,
                                    };
                                })
                            );

                            setSentences((s) => replaceSentencesWithDict(s, dicts));
                            setLoadingTranscription(false);
                        })
                        .catch(() => {
                            setLoadingTranscription(false);
                        });
                }
                return;
            }
            localStorage.removeItem(`nami-transcript-${videoId}`);
        }, [videoId, user, replacedDict, sentences]);

        useEffect(() => {
            if (user && videoId) {
                db.collection("files")
                    .doc(videoId)
                    .get()
                    .then((doc) => {
                        if (doc.empty) {
                            console.log("No matching documents.");
                            return;
                        }

                        const data = doc.data();
                        setFileName(data.name);
                        setLoadingTranscription(true);
                        setMediaUrl(data.url);
                        setNotes(data.notes || "");
                        const time = formatTime(data.length);
                        setVideoState((e) => ({
                            ...e,
                            duration: data.length,
                            durationString: `${time.hours !== "00" ? `${time.hours}:` : ""}${time.minutes
                                }:${time.seconds}`,
                        }));
                        id.current = doc.id;

                        db.collection("files")
                            .doc(doc.id)
                            .collection("sentencesEdited")
                            .orderBy("start", "asc")
                            .get()
                            .then((sentencesEdited) => {
                                if (sentencesEdited.empty) {
                                    db.collection("files")
                                        .doc(doc.id)
                                        .collection("sentences")
                                        .orderBy("start", "asc")
                                        .get()
                                        .then((sentences) => {
                                            if (sentences.empty) {
                                                Tasks.getResult(data.taskId).then(
                                                    (transcriptionData) => {
                                                        const transcriptionSentences =
                                                            transcriptionData.sentences;
                                                        setSentences(transcriptionSentences);
                                                    }
                                                );
                                            } else {
                                                const transcriptionSentences = sentences.docs.map(
                                                    (doc) => doc.data()
                                                );
                                                setSentences(transcriptionSentences);
                                            }
                                        });
                                } else {
                                    setSentences(sentencesEdited.docs.map((doc) => doc.data()));
                                }
                            });
                    });
            }
        }, [videoId, user]);
    }

    const saveTranscriptionHandle = IS_NTTDATA
        ? useCallback(async () => {
            if (
                user &&
                Array.isArray(latestSentencesRef.current) &&
                latestSentencesRef.current.length
            ) {
                setSaveLoading(true);
                if (IS_NTTDATA_ON_PREMISE) {
                    //Update notes
                    notes && await updateSession(videoId, { notes });
                    //Update transcripts
                    const sentences = latestSentencesRef.current;
                    await editSessionTranscripts(videoId, sentences);

                    setSaveLoading(false);

                    message.success(lang.saveSuccess);
                } else {
                    const curMeetingDoc = db.collection("meetings").doc(videoId);

                    curMeetingDoc.set({ lastEdited: new Date() }, { merge: true });

                    curMeetingDoc.update({
                        notes: notes,
                    });

                    curMeetingDoc
                        .collection("sentencesEdited")
                        .get()
                        .then(async (querySnapshot) => {
                            if (querySnapshot.empty) {
                                await Promise.all(
                                    cleanSentenceWords(latestSentencesRef.current).map((sentence) => {
                                        return curMeetingDoc
                                            .collection("sentencesEdited")
                                            .add(sentence);
                                    })
                                );
                            } else {
                                const data = querySnapshot.docs.map((doc) => ({
                                    ...doc.data(),
                                    id: doc.id,
                                }));
                                // Delete all
                                await Promise.all(
                                    data.map((sentence) => {
                                        return curMeetingDoc
                                            .collection("sentencesEdited")
                                            .doc(sentence.id)
                                            .delete();
                                    })
                                );
                                // Add all
                                await Promise.all(
                                    cleanSentenceWords(latestSentencesRef.current).map(({ id, ...sentence }) => {
                                        return curMeetingDoc
                                            .collection("sentencesEdited")
                                            .add(sentence);
                                    })
                                );
                            }

                            setSaveLoading(false);

                            message.success(lang.saveSuccess);
                        })
                        .catch((e) => {
                            message.error(lang.saveFailed);
                            setSaveLoading(false);
                        });
                }
            }
        }, [videoId, user, lang.saveSuccess, lang.saveFailed, notes])
        : useCallback(async () => {
            db.collection("files").doc(id.current).update({
                notes: notes,
            });

            if (
                Array.isArray(latestSentencesRef.current) &&
                latestSentencesRef.current.length
            ) {
                // Delete all
                await db
                    .collection("files")
                    .doc(id.current)
                    .collection("sentencesEdited")
                    .get()
                    .then(async (querySnapshot) => {
                        setSaveLoading(true);
                        await Promise.all(
                            querySnapshot.docs.map((doc) => {
                                return doc.ref.delete();
                            })
                        );

                        // Add all
                        Promise.all(
                            cleanSentenceWords(latestSentencesRef.current).map((sentence) => {
                                return db
                                    .collection("files")
                                    .doc(id.current)
                                    .collection("sentencesEdited")
                                    .add(sentence);
                            })
                        )
                            .then(() => {
                                db.collection("files").doc(id.current).update({
                                    lastEdit: new Date(),
                                });
                                setSaveLoading(false);

                                message.success(lang.saveSuccess);
                            })
                            .catch((e) => {
                                message.error(lang.saveFailed);
                                setSaveLoading(false);
                                return;
                            });
                    })
                    .catch((e) => {
                        message.error(lang.saveFailed);
                        setSaveLoading(false);
                        return;
                    });
            }
        }, [notes, lang.saveFailed, lang.saveSuccess]);

    const revertTranscriptionHandle = IS_NTTDATA
        ? useCallback(() => {
            if (user) {
                setLoadingRevert(true);
                db.collection("meetings")
                    .doc(videoId)
                    .collection("sentences")
                    .orderBy("timestamp", "asc")
                    .get()
                    .then((snapshot) => {
                        const data = snapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                            checked: false,
                        }));

                        const audioTranscripts = start_timestamp
                            ? data.map((e) => {
                                const start = e.timestamp - start_timestamp;
                                return {
                                    ...e,
                                    words:
                                        e.words?.map((word) => {
                                            return {
                                                ...word,
                                                start: word.start - e.start + start,
                                                end: word.end - e.start + start || 0,
                                            };
                                        }) || [],
                                    start: start,
                                    end: start + e.duration || 0,
                                };
                            })
                            : data;

                        setSentences(filterSentences(audioTranscripts));
                        setLoadingRevert(false);
                    });
            }
        }, [videoId, user])
        : useCallback(() => {
            if (user) {
                setLoadingRevert(true);
                db.collection("files")
                    .doc(videoId)
                    .collection("sentences")
                    .orderBy("start", "asc")
                    .get()
                    .then((snapshot) => {
                        const data = snapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));

                        setSentences(filterSentences(data));
                        setLoadingRevert(false);
                    });
            }
        }, [videoId, user]);

    useEffect(() => {
        if (mode !== "edit") return;
        // --------------------VIDEO---------------------------------
        const videoCurrent = vidRef.current;
        if (!mediaUrl) {
            return;
        }

        if (videoCurrent.readyState === 0) {
            videoCurrent.load();
        }

        const handleTimeUpdate = () => {
            if (!videoCurrent?.seeking) {
                const time = formatTime(Math.floor(videoCurrent?.currentTime));
                setVideoState((e) => ({
                    ...e,
                    played: videoCurrent?.currentTime,
                    playedString: `${time.hours !== "00" ? `${time.hours}:` : ""}${time.minutes
                        }:${time.seconds}`,
                }));
            }
        };
        // handle video playing time
        if (videoCurrent)
            videoCurrent.addEventListener("timeupdate", handleTimeUpdate);
        return () => {
            videoCurrent.removeEventListener("timeupdate", handleTimeUpdate);
        };
    }, [mediaUrl, mode]);

    const jumpContent = (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                const data = new FormData(e.target);
                const dataObj = Object.fromEntries(data.entries());
                const { hours, minutes, seconds } = dataObj;
                const timeInSeconds = +hours * 3600 + +minutes * 60 + +seconds;
                changeTime(timeInSeconds);
            }}
        >
            <div className="jump-content-container">
                <div className="jump-content-input-container">
                    <p>{lang.hour}</p>
                    <input name="hours" type="number" min="0" />
                </div>
                <div className="jump-content-input-container">
                    <p>{lang.minute}</p>
                    <input name="minutes" type="number" min="0" max="59" />
                </div>
                <div className="jump-content-input-container">
                    <p>{lang.second}</p>
                    <input name="seconds" type="number" min="0" max="59" />
                </div>
                <button className="ant-btn-primary" type="submit">
                    <CheckOutlined />
                    <p>{lang.apply}</p>
                </button>
            </div>
        </form>
    );

    const volumeContent = (
        <Slider
            min={0}
            max={10}
            onChange={updateVolume}
            value={videoState.volume * 10}
        />
    );
    const filterMenu = (
        <Menu selectable selectedKeys={`download-item-${Number(!filterAll)}`}>
            {[lang.showAll, lang.showOnlySelected].map((item, index) => (
                <Menu.Item
                    key={`download-item-${index}`}
                    onClick={() => setFilterAll(!Boolean(index))}
                >
                    {item}
                </Menu.Item>
            ))}
        </Menu>
    );
    const exportMenu = (
        <Menu>
            {(IS_NTTDATA
                ? [lang.exportAll, lang.exportSelected]
                : [lang.exportAll]
            ).map((item, index) => (
                <Menu.Item
                    key={`download-item-${index}`}
                    onClick={() => handleExportClick(index)}
                >
                    {item}
                </Menu.Item>
            ))}
        </Menu>
    );

    const someOneIsSpeaking = Object.values(speaking).some((e) => e.is_speaking ?? e.isSpeaking);
    const fileLabel = !IS_NTTDATA
        ? fileName
        : videoId.includes("_")
            ? videoId.split("_").slice(0, -1).join("_")
            : videoId;
    const isVideo = checkUrl(mediaUrl);

    return (
        <div className="edit">
            <Skeleton active loading={loadingTranscription && loading}>
                <Row className="edit-header" justify="space-between" align="middle">
                    <div className="edit-header-title">
                        <span>
                            {IS_NTTDATA && lang.meeting + " " + lang.ID + ": "}{" "}
                            <b>{fileLabel}</b>
                        </span>
                        {IS_SAFARI && (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span className="warning">Warning for Safari users!</span>
                                    <Button
                                        type="link"
                                        className="edit-header-warning"
                                        style={{
                                            display: "inline-block",
                                        }}
                                        onClick={() => setSafariModal(true)}
                                    >
                                        See instruction
                                    </Button>
                                </div>
                                <SafariModal
                                    show={safariModal}
                                    onClose={() => setSafariModal(false)}
                                />
                            </>
                        )}
                    </div>
                    <Col style={{ padding: "0 2rem" }}>
                        <Space>
                            {IS_NTTDATA && (
                                <Dropdown overlay={filterMenu}>
                                    <Button
                                        size="large"
                                        htmlType="submit"
                                        type="link"
                                        icon={<FilterOutlined />}
                                    >
                                        {lang.filter}
                                    </Button>
                                </Dropdown>
                            )}
                            {mode === "edit" && !IS_NTTDATA && (
                                <Button
                                    size="large"
                                    htmlType="submit"
                                    type="link"
                                    icon={<RetweetOutlined />}
                                    onClick={revertTranscriptionHandle}
                                    loading={loadingRevert}
                                >
                                    {lang.revert}
                                </Button>
                            )}
                            <Dropdown overlay={exportMenu}>
                                <Button
                                    size="large"
                                    htmlType="submit"
                                    type="link"
                                    icon={<DownloadOutlined />}
                                >
                                    {lang.export}
                                </Button>
                            </Dropdown>
                            {mode === "edit" && (
                                <Button
                                    size="large"
                                    htmlType="submit"
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    onClick={saveTranscriptionHandle}
                                    loading={saveLoading}
                                >
                                    {lang.btnSave}
                                </Button>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Skeleton>
            <Divider style={{ margin: 0 }} />
            {/*-------------------------------------------------- Video Player-------------------------------------------------- */}
            <Row className="edit-row">
                {mode === "edit" && (
                    <Col span={7} className="edit-rightcol">
                        <div className="edit-video">
                            {isVideo ? (
                                <video
                                    ref={vidRef}
                                    src={mediaUrl}
                                    className="edit-video-player"
                                    preload="metadata"
                                    onClick={handlePlayPause}
                                    onPause={() =>
                                        setVideoState((e) => ({ ...e, playing: false }))
                                    }
                                    onPlay={() => setVideoState((e) => ({ ...e, playing: true }))}
                                    crossOrigin="anonymous"
                                ></video>
                            ) : (
                                <>
                                    <audio
                                        ref={vidRef}
                                        className="edit-video-player"
                                        src={mediaUrl}
                                        preload="metadata"
                                        onPause={() =>
                                            setVideoState((e) => ({ ...e, playing: false }))
                                        }
                                        onPlay={() =>
                                            setVideoState((e) => ({ ...e, playing: true }))
                                        }
                                        crossOrigin="anonymous"
                                    ></audio>
                                    <canvas onClick={handlePlayPause} ref={canvasElm}></canvas>
                                </>
                            )}
                        </div>
                        <div className="edit-video-progress">
                            <Slider
                                min={0}
                                max={videoState.duration * 10000000}
                                tooltipVisible={false}
                                value={videoState.played * 10000000}
                                onChange={handleSeekChange}
                                onAfterChange={handleSeekMouseUp}
                                className="edit-video-progress-slider"
                            />
                            <div className="time">
                                <Text type="secondary">
                                    <time
                                        id="time-elapsed"
                                        dateTime={convertToDatetime(videoState.playedString)}
                                    >
                                        {videoState.playedString
                                            ? videoState.playedString
                                            : "00:00"}
                                    </time>
                                    <span> / </span>
                                    <time
                                        id="duration"
                                        dateTime={convertToDatetime(videoState.durationString)}
                                    >
                                        {videoState.durationString
                                            ? videoState.durationString
                                            : "00:00"}
                                    </time>
                                </Text>
                            </div>
                        </div>

                        <div className="edit-video-btn">
                            <div className="edit-video-btn-item" onClick={handlePlayPause}>
                                <span className="edit-video-btn-item-icon">
                                    {videoState.playing ? (
                                        <PauseOutlined />
                                    ) : (
                                        <PlayCircleOutlined />
                                    )}
                                </span>
                                <span className="edit-video-btn-item-text">
                                    {videoState.playing ? lang.pause : lang.play}
                                </span>
                            </div>

                            <Popover content={jumpContent} title={lang.jumpToTimestamp}>
                                <div className="edit-video-btn-item">
                                    <span className="edit-video-btn-item-icon">
                                        <StepForwardOutlined />
                                    </span>
                                    <span className="edit-video-btn-item-text">
                                        {lang.jumpTo}
                                    </span>
                                </div>
                            </Popover>

                            <div className="edit-video-btn-item" onClick={updatePlaybackRate}>
                                <span className="edit-video-btn-item-icon">
                                    <Text className="edit-video-btn-item-icon-speed anticon">
                                        {videoState.playbackRate}x
                                    </Text>
                                </span>
                                <span className="edit-video-btn-item-text">{lang.speed}</span>
                            </div>

                            <Popover content={volumeContent} title={lang.setVolume}>
                                <div className="edit-video-btn-item">
                                    <span className="edit-video-btn-item-icon">
                                        <WifiOutlined rotate={90} />
                                    </span>
                                    <span className="edit-video-btn-item-text">
                                        {lang.volume}
                                    </span>
                                </div>
                            </Popover>
                        </div>

                        {/* ----------------------Text Area-------------------------------------- */}
                        <div className="edit-video-note" direction="vertical">
                            <label htmlFor="notes-area">
                                <Text style={{ textTransform: "uppercase" }}>{lang.notes}</Text>
                            </label>
                            <TextArea
                                id="notes-area"
                                className="edit-video-note-textarea"
                                placeholder={lang.writeNotesHere}
                                value={notes}
                                onChange={(e) => {
                                    setNotes(e.target.value);
                                }}
                            />
                        </div>
                    </Col>
                )}
                <Col
                    span={17}
                    style={
                        mode === "edit"
                            ? {}
                            : {
                                width: "100%",
                                maxWidth: "unset",
                                flex: "unset",
                                position: "relative",
                            }
                    }
                    className="edit-leftcol"
                >
                    <Skeleton loading={loadingTranscription} paragraph={{ rows: 15 }}>
                        {sentences ? (
                            <TranscriptEditor
                                mode={mode}
                                filterAll={filterAll}
                                changeTime={changeTime}
                                sentences={sentences.filter(x => {
                                    //nếu speaker đó bị lỗi thì không show sentence của speaker đó từ thời điểm error_timestamp
                                    //console.log('speaking[x.speaker]: ', speaking[x.speaker])
                                    if ((speaking[x.speaker]?.is_error || speaking[x.speaker]?.isError) === true && x.timestamp > (speaking[x.speaker].error_timestamp ?? speaking[x.speaker]?.errorTimestamp)) {
                                        return false
                                    } else {
                                        return true
                                    }
                                })}
                                setSentences={setSentences}
                                vidRef={vidRef}
                                videoId={videoId}
                                dicts={user?.profile.dicts}
                                isSpeaking={speaking}
                            />
                        ) : (
                            <div>Not found</div>
                        )}
                    </Skeleton>
                    {someOneIsSpeaking && (
                        <div className="speaking-status">
                            <svg
                                className="speaking-status-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M9,14a4,4,0,1,0-4-4A4,4,0,0,0,9,14ZM9,8a2,2,0,1,1-2,2A2,2,0,0,1,9,8ZM6,15h6a4,4,0,0,1,4,4v2a1,1,0,0,1-2,0V19a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2v2a1,1,0,0,1-2,0V19A4,4,0,0,1,6,15Zm11.462-5A5.977,5.977,0,0,1,15.7,14.253a1,1,0,0,1-1.414-1.414,4.015,4.015,0,0,0,0-5.678A1,1,0,1,1,15.7,5.747,5.977,5.977,0,0,1,17.462,10Zm-.181,7.7a1,1,0,0,1,.024-1.414,8.667,8.667,0,0,0,0-12.562A1,1,0,0,1,18.7,2.281a10.667,10.667,0,0,1,0,15.438,1,1,0,0,1-1.414-.024Z"
                                    fill="currentColor"
                                />
                            </svg>
                            {Object.entries(speaking)
                                .map(([key, value], index) => {
                                    const rv =
                                        index < MAX_SPEAKER_SHOW
                                            ? (value.is_error ?? value.isError)
                                                ? `${key} (${value.error_description ?? value.errorDescription})`
                                                : key
                                            : index === MAX_SPEAKER_SHOW
                                                ? `and ${Object.keys(speaking).length - index} more...`
                                                : null;
                                    return value.is_speaking || value.isSpeaking ? rv : null;
                                })
                                .filter((item) => item !== null)
                                .join(", ")}
                            <div className="wave-container">
                                <div className="line line1"></div>
                                <div className="line line2"></div>
                                <div className="line line3"></div>
                                <div className="line line4"></div>
                                <div className="line line5"></div>
                            </div>
                        </div>
                    )}
                    <div className="navigate-container">
                        <Button
                            type="primary"
                            onClick={() => {
                                const ele = document.getElementsByClassName("edit-leftcol")[0];
                                ele.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            <ArrowUpOutlined />
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                const ele = document.getElementsByClassName("edit-leftcol")[0];
                                ele.scrollTo({
                                    top: ele.scrollHeight,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            <ArrowDownOutlined />
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default MeetingDetailOnPremise;
