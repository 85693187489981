import React, { useState, useRef } from "react";

import { Layout, Card, Row, Col, Button } from "antd";

import "./styles.less";

import { PlayCircleFilled, PauseCircleFilled } from "@ant-design/icons";

import useRecorder from "./useRecorder";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import UserTranscriptsLayout from "../../../components/web/Layout/UserTranscripts";
import _ from "lodash";

const socketViUrl = process.env.REACT_APP_LIVE_API_VI_URL;
const socketStreamingASRUrl = "wss://jp-asr-api.namisense.ai/stream_asr_vn";

const StreamingTranscript = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [fullContextMessageHistory, setFullContextMessageHistory] = useState(
    []
  );
  const [streamingASRMessageHistory, setStreamingASRMessageHistory] = useState(
    []
  );

  const fullContextSocket = useRef(null);
  const streamingASRSocket = useRef(null);

  let [, startFullContextRecording, stopFullContextRecording] =
    useRecorder(fullContextSocket);
  let [, startStreamingASRRecording, stopStreamingASRRecording, audioURL] =
    useRecorder(streamingASRSocket);

  const [startRec, setStartRec] = useState(false);

  const [fullContextConnectState, setFullContextConnectState] =
    useState("Connecting");
  const [streamingASRConnectState, setStreamingASRConnectState] =
    useState("Connecting");

  const startRecord = () => {
    if (startRec) {
      if (fullContextSocket.current) {
        fullContextSocket.current.close();
        fullContextSocket.current = null;
      }
      if (streamingASRSocket.current) {
        streamingASRSocket.current.close();
        streamingASRSocket.current = null;
      }
      setStartRec(false);
      stopFullContextRecording();
      stopStreamingASRRecording();
    } else {
      // Full context socket
      fullContextSocket.current = new WebSocket(socketViUrl);

      fullContextSocket.current.addEventListener("open", () => {
        setFullContextConnectState("Connected");
      });

      fullContextSocket.current.addEventListener("close", () => {
        setFullContextConnectState("Closed");
      });

      fullContextSocket.current.addEventListener("message", (event) => {
        setFullContextMessageHistory((prev) =>
          prev.concat(JSON.parse(event.data))
        );
      });

      // Streaming ASR socket
      streamingASRSocket.current = new WebSocket(socketStreamingASRUrl);

      streamingASRSocket.current.addEventListener("open", () => {
        setStreamingASRConnectState("Connected");
      });

      streamingASRSocket.current.addEventListener("close", () => {
        setStreamingASRConnectState("Closed");
      });

      streamingASRSocket.current.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        console.log(data);
        setStreamingASRMessageHistory((oldMessages) =>
          !data.end_sentence && !data.transcript
            ? oldMessages
            : oldMessages.length === 0 || _.last(oldMessages).end_sentence
            ? data.transcript
              ? oldMessages.concat(data)
              : oldMessages
            : oldMessages.map((sentence, i) =>
                i === oldMessages.length - 1 && data.transcript
                  ? data
                  : { ...sentence, end_sentence: data.end_sentence }
              )
        );
      });

      setFullContextMessageHistory([]);
      setStreamingASRMessageHistory([]);

      setStartRec(true);
      startFullContextRecording();
      startStreamingASRRecording();
    }
  };

  return (
    <>
      <UserTranscriptsLayout />
      <Layout className="jobs-page">
        <Layout style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Card style={{ marginBottom: 18 }} bordered={false}>
            <Card style={{ marginTop: "20px" }}>
              <p style={{ textAlign: "center" }}>
                {startRec ? (
                  <a onClick={startRecord}>
                    <PauseCircleFilled
                      style={{ fontSize: 24, color: "#BD271E" }}
                    />
                    <span
                      style={{ fontSize: 24, color: "#BD271E", marginLeft: 10 }}
                    >
                      {lang.btnStop}
                    </span>
                  </a>
                ) : (
                  <a onClick={startRecord}>
                    <PlayCircleFilled
                      style={{ fontSize: 24, color: "#BD271E" }}
                    />
                    <span
                      style={{ fontSize: 24, color: "#BD271E", marginLeft: 10 }}
                    >
                      {lang.btnStart}
                    </span>
                  </a>
                )}
                <br />
              </p>
            </Card>
            {audioURL && (
              <Button
                onClick={() => {
                  const a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  a.href = audioURL;
                  a.download = "audio_data.webm";
                  a.click();
                }}
                type="primary"
                style={{ marginTop: "20px" }}
              >
                Download audio file
              </Button>
            )}
          </Card>
          <Row>
            <Col span={12}>
              <Card style={{ height: "100%" }}>
                <p>Full context transcript</p>
                <p>
                  {lang.connectionStatus}:{" "}
                  {fullContextConnectState === "Connected" ? (
                    <span style={{ fontWeight: "bold", color: "green" }}>
                      {lang.connected}
                    </span>
                  ) : (
                    <span style={{ fontWeight: "bold", color: "red" }}>
                      {lang.closed}
                    </span>
                  )}
                </p>
                <Card>
                  {fullContextMessageHistory.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </Card>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ height: "100%" }}>
                <p>Streaming ASR transcript</p>
                <p>
                  {lang.connectionStatus}:{" "}
                  {streamingASRConnectState === "Connected" ? (
                    <span style={{ fontWeight: "bold", color: "green" }}>
                      {lang.connected}
                    </span>
                  ) : (
                    <span style={{ fontWeight: "bold", color: "red" }}>
                      {lang.closed}
                    </span>
                  )}
                </p>
                <Card>
                  {streamingASRMessageHistory.map((item, index) => {
                    return <p key={index}>{item.transcript}</p>;
                  })}
                </Card>
              </Card>
            </Col>
          </Row>
        </Layout>
      </Layout>
    </>
  );
};

export default StreamingTranscript;
