import { useEffect, useState } from "react";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.startRecording();
    } else {
      recorder.stopRecording(() => {
        let blob = recorder.getBlob();
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          console.log(base64data);
        };
        setAudioURL(URL.createObjectURL(blob));
      });
    }

    // // Obtain the audio when ready.
    // const handleData = (e) => {
    //   setAudioURL(URL.createObjectURL(e.data));
    // };

    // recorder.addEventListener("dataavailable", handleData);
    // return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  return [audioURL, isRecording, startRecording, stopRecording];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new RecordRTC(stream, {
    mimeType: "audio/webm",
    sampleRate: 16000,
    numberOfAudioChannels: 1,
  });
}
export default useRecorder;
