import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Affix, Tabs } from "antd";

import "./styles.less";
import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

const { TabPane } = Tabs;

const EmployerLayout = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const navigate = useNavigate();
  const [key, setKey] = useState(1);

  useEffect(() => {
    if (window.location.pathname === "/user/streaming") setKey("streaming");
    else setKey("record");
  }, [window.location.pathname]);

  return (
    <div className="employer-layout">
      <Layout>
        <Tabs
          activeKey={key}
          type="card"
          size="medium"
          onChange={(e) => {
            if (e === "streaming") navigate(`/user/streaming`);
            else navigate(`/user/record`);
          }}
          tabBarStyle={{ margin: "25px 24px 0px 24px" }}
        >
          <TabPane
            tab="Full Context"
            onClick={() => navigate(`/user/record`)}
            key="record"
          ></TabPane>
          <TabPane
            tab={"Streaming"}
            onClick={() => navigate(`/user/streaming`)}
            key="streaming"
          ></TabPane>
        </Tabs>
        <Outlet />
      </Layout>
    </div>
  );
};

export default EmployerLayout;
