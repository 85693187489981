import React, { useState, useEffect, useRef } from "react";

import { Card, Table, Layout, Button, Col, Input, Space, Modal, DatePicker, InputNumber, Tag } from "antd";
import { EyeOutlined, SyncOutlined, PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import { useNavigate } from "react-router";

import "./styles.less";

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import moment from 'moment-timezone';
import { db, storage } from 'utils/firebase';
import { firestore } from 'firebase';

import useAuth from "hooks/useAuth";
import { IS_FUNIX } from "utils/constants";

import lodash from "lodash";
import usLocale from 'antd/lib/date-picker/locale/en_US';

import _extends from "@babel/runtime/helpers/extends";
import CalendarLocale from "rc-picker/es/locale/vi_VN";
import TimePickerLocale from 'antd/es/time-picker/locale/vi_VN';

import { MeiliSearch } from 'meilisearch';

const { RangePicker } = DatePicker;

const { REACT_APP_MEILI_HOST, REACT_APP_MEILI_API_KEY } = process.env;

const searchClient = IS_FUNIX ? new MeiliSearch({
  host: REACT_APP_MEILI_HOST,
  apiKey: REACT_APP_MEILI_API_KEY,
}) : null;

const FollowUps = () => {
  const { t, i18n } = useTranslation();
  const lang = getLanguages(t);

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [followUpNumber, setFollowUpNumber] = useState("");
  const [results, setResults] = useState([]);
  const [showMoreMetadata, setShowMoreMetadata] = useState([]);

  const [expandedKey, setExpandedKey] = useState([]);
  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedKey([...expandedKey, record.number]);
    } else {
      setExpandedKey(expandedKey.filter((key) => key !== record.number));
    }
  };

  const locale = {
    lang: _extends({
      placeholder: 'Chọn thời điểm',
      rangePlaceholder: ['Từ ngày', 'Đến ngày']
    }, CalendarLocale),
    timePickerLocale: _extends({}, TimePickerLocale)
  };

  useEffect(() => {
    setLoading(true);
    // Get distinct fromNumber
    const fromNumbers = new Set([...files.map(file => file?.from_number)]);

    // Get distinct toNumber
    const toNumbers = new Set([...files.map(file => file?.to_number)]);

    // Combine fromNumbers and toNumbers
    const numbers = [...new Set([...fromNumbers, ...toNumbers])];

    // Filter specific number
    const filterList = ['84886310808', '84866445005', '84896163234'];
    const filteredNumbers = numbers.filter(number => number && number.length > 4 && !filterList.includes(number));
    
    // Count number of follow up
    let results = filteredNumbers.map(number => {
      const followUps = files.filter(file => file.answer_duration != 0 && (file?.from_number === number || file?.to_number === number));
      
      return {
        number,
        followUpNumber: followUps.length,
      }
    });

    if (phoneNumber) {
      results = results.filter(result => result.number == phoneNumber);
    }

    if (followUpNumber) {
      results = results.filter(result => result.followUpNumber >= followUpNumber);
    }

    setResults(results);
    setLoading(false);
  }, [files]);


  const onSearch = async () => {
    setExpandedKey([]);
    setLoading(true)

    const results = await searchClient.index('transcripts').search('', {
      limit: 999999,
      filter: `timestamp_fm >= ${startDate.startOf('day').unix()} AND timestamp_fm <= ${endDate.endOf('day').unix()}`,
      sort: ['timestamp_fm:asc'],
    });

    const data = results.hits;

    setFiles(data);
  }

  const columns = [
    // Print results
    {
      title: lang.phone,
      dataIndex: 'number',
      key: 'number',
      width: 200,
      render: (text, record) => {
        return (
          <div>
            <div>{text}</div>
          </div>
        )
      }
    },
    {
      title: lang.followUp,
      dataIndex: 'followUpNumber',
      key: 'followUpNumber',
      width: 200,
      sorter: (a, b) => a.followUpNumber - b.followUpNumber,
      defaultSortOrder: 'descend',
      render: (text, record) => {
        return (
          <div>
            <div>{text}</div>
          </div>
        )
      }
    },
  ];

  const columnsMember = [
    {
      title: 'Metadata',
      width: '10%',
      render: (text, record, index) => {
        if (record) {
          return (<div>
            {record && <div>
              <div><b>answer_duration_fm:</b> {record.answer_duration_fm}</div>
              <div><b>from_method:</b> {record.from_method}</div>
              <div><b>from_name:</b> {record.from_name}</div>
              <div><b>from_number:</b> {record.from_number}</div>
              <div><b>to_number:</b> {record.to_number}</div>
            </div>}
            {showMoreMetadata.includes(record.call_id)
              ? <div>
                  <div><b>answer_duration:</b> {record.answer_duration}</div>
                  <div><b>call_id:</b> {record.call_id}</div>
                  <div><b>duration:</b> {record.duration}</div>
                  <div><b>to_alias:</b> {record.to_alias}</div>
                  <Button
                    style={{marginLeft: -15}}
                    type="link"
                    onClick={() => setShowMoreMetadata(showMoreMetadata.filter(item => item !== record.call_id))}
                  >
                    <MinusCircleOutlined /> {lang.showLess}
                  </Button>
                </div>
              : <Button
                style={{marginLeft: -15}}
                type="link"
                onClick={() => setShowMoreMetadata([...showMoreMetadata, record.call_id])}
              >
                <PlusCircleOutlined /> {lang.showMore}
              </Button>
            }
          </div>)
        }
        else return null;
      }
    },
    {
      title: lang.length,
      align: 'center',
      dataIndex: '',
      width: '3%',
      sorter: {
        compare: (a, b) => {
          return a.answer_duration_fm?.localeCompare(b.answer_duration_fm)
        },
      },
      render: (text, record, index) => {
        return (
          <div>{record.answer_duration_fm}</div>
        );
      }
    },
    {
      title: lang.callDate,
      width: '4%',
      sorter: {
        compare: (a, b) => {
          return a?.timestamp_fm - b?.timestamp_fm
        },
      },
      render: (text, record, index) => {
        return (<div>{moment.unix(record.timestamp_fm).format("DD-MM-YYYY HH:mm:ss")}</div>)
      }
    },
    {
      title: lang.status,
      align: 'center',
      width: '3%',
      sorter: {
        compare: (a, b) => {
          return (a.status || "").localeCompare(b.status || "")
        },
      },
      render: (record) => {
        switch (record.status) {
          case 'TRANSCRIBING':
            return (
              <Tag icon={<SyncOutlined spin />} color='#108ee9'>
                {lang.transcribing}
              </Tag>
            )
          case 'TRANSCRIBED':
            return (
              <a target={'_blank'} href={`/user/transcripts/edit/${record.firebaseDocId}`}>
                <Tag color='#87d068'>{lang.ready}</Tag>
              </a>
            )
          case 'ERROR':
            return (
              <Tag color='#ff0000'>{lang.noSpeechData}</Tag>
            )
          case 'UPLOADED':
            return (
              <Tag color='#ff9300'>{lang.uploaded}</Tag>
            )
          default:
            return <Tag color='grey'>{lang.noAudioFound}</Tag>;
        }
      },
    },
    {
      title: '',
      align: 'center',
      width: '2%',
      render: (record) => {
        return (
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            {(record.status === 'TRANSCRIBED' && record.length !== '00:00:00') && <a target={'_blank'} href={`/user/transcripts/edit/${record.firebaseDocId}`}><Button 
            type="link" 
            icon={<EyeOutlined />}
            /></a>}
          </div>
        );
      }
    }
  ];

  return (
    <Layout className="jobs-page">
      <Layout
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          marginTop: 20,
          marginBottom: 24,
        }}
      >
        <Card style={{ marginBottom: 18 }} bordered={false}>
          <Col className="gutter-row">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3>{lang.followUp}</h3>
            </div>
              <RangePicker
                style={{ marginRight: 10, marginBottom: 18, width: 410 }}
                onChange={(date, dateString) => {
                  if (date) {
                    setStartDate(date[0]);
                    setEndDate(date[1]);
                  }
                  else {
                    setStartDate(null);
                    setEndDate(null);
                  }
                }}
                value={[startDate, endDate]}
                format={"DD-MM-YYYY"}
                ranges={{
                  [lang.today]: [moment(), moment()],
                  [lang.yesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  [lang.thisWeek]: [moment().startOf('week'), moment().endOf('week')],
                  [lang.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
                  [lang.thisQuarter]: [moment().startOf('quarter'), moment().endOf('quarter')],
                  [lang.thisYear]: [moment().startOf('year'), moment().endOf('year')],
                }}
                locale={i18n.language === 'en' ? usLocale : locale}
              />

              <Input
                style={{ width: 200, marginBottom: 18, marginRight: 10, height: 32 }}
                placeholder={lang.phone}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                allowClear={true}
              />

              <InputNumber
                style={{ width: 200, marginBottom: 18, marginRight: 10, height: 32 }}
                placeholder={lang.followUpNumber}
                min={0}
                onChange={(value) => {
                  setFollowUpNumber(value);
                }}
              />

              <Button
                type="primary"
                style={{ width: 100 }}
                onClick={() => {onSearch()}}
                loading={loading}
              >
                {lang.search}
              </Button>
          </Col>
        </Card>
        <Card>
          <p><b>{results.length}</b> {lang.searchResults}</p>
          <Table
            loading={loading}
            bordered
            columns={columns}
            rowKey={(record) => record.number}
            dataSource={results}
            expandable={{
              expandedRowRender: (record) => (
                <Table
                  loading={loading}
                  bordered
                  columns={columnsMember}
                  dataSource={files.filter((file) => file.answer_duration != 0 && (file?.from_number == record.number || file?.to_number == record.number))}
                />
              ),
              onExpand: onExpand,
              expandedRowKeys: [...expandedKey]
            }}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
            }}
          />
        </Card>
      </Layout>
    </Layout>
  );
};

export default FollowUps;
