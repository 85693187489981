import { Button, Modal } from "antd";
import React from "react";

const SafariModal = ({ show, onClose }) => {
  return (
    <Modal
      visible={show}
      onCancel={onClose}
      title="Warning for Safari users!"
      footer={
        <Button type="primary" onClick={onClose}>
          Ok
        </Button>
      }
    >
      <div className="safari-modal">
        <p>
          To play the media you must set the{" "}
          <b>Safari &gt; Settings &gt; Settings for ... &gt; Auto-play</b> to{" "}
          <b>Allow All Auto-play</b> and reload the page.
        </p>
        <p>
          <b>Step 1:</b>
        </p>
        <img src="/step-1.png" alt="step-1" />
        <p>
          <b>Step 2:</b>
        </p>
        <img src="/step-2.png" alt="step-2" />
        <p>We are very sorry for this inconvenience.</p>
      </div>
    </Modal>
  );
};

export default SafariModal;