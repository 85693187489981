import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Checkbox, message } from "antd";

import { auth } from "utils/firebase";

import "./styles.less";
import Axios from "axios";

const { REACT_APP_TENANT_ID } = process.env;

const UserCreateForm = ({ onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  return (
    <Form
      form={form}
      layout="vertical"
      name="userUpdateForm"
      onFinish={async (values) => {
        setLoading(true);

        const apiUrl = process.env.REACT_APP_API + "/admin/users";
        try {
          const { data: response } = await Axios.post(
            apiUrl,
            {
              name: values?.name,
              email: values?.email,
              password: values?.password,
              phone: values?.phone || "",
              address: values?.address || "",
              isActive: values?.isActive,
              tenantId: REACT_APP_TENANT_ID,
            },
            {
              headers: {
                Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
              },
            }
          );
          message.success(response.message ?? "Success");
          setLoading(false);
          onCancel();
        } catch (err) {
          message.error(err?.response?.data?.message ?? "Server Error");
          setLoading(false);
        }
      }}
      initialValues={{}}
    >
      <div className="user-form">
        <Row gutter={24} type="flex">
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col span={12}>
            <Form.Item name="phone" label={"Phone"}>
              <Input placeholder={"0987675234"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="address" label={"Address"}>
              <Input
                placeholder={
                  "CC Hoàng Sa, Đường D1, Phường An Thuận Động, Q7, HCM"
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item name="isActive" label="Active" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: "right" }}>
          <Col className="gutter-row" span={24} style={{ textAlign: "right" }}>
            <Button type="clear" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={loading} type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default UserCreateForm;
