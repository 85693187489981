import React, {useState, useSelector} from 'react';
import { Form, Input, Col, Row, Button, Checkbox, Select } from 'antd';
import {db} from "utils/firebase";
import { lang } from 'moment-timezone';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

const BugEditForm = ({ bug, onCancel, onCallback }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  
  return (
    <Form
        form={form}
        layout="vertical"
        onFinish={values => {
          setLoading(true);
          db.doc(`/bugs/${bug?.id}`)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              return;
            }
            
            db.doc(`/bugs/${bug?.id}`)
            .update({
              transcriptEdit: values?.transcriptEdit
            })
            .then(doc => {
              setLoading(false)
              onCallback();
            })
            .catch(err => {
              setLoading(false)
              console.log(err);
            });
          })
          .catch((err) => {
            setLoading(false)
            console.error(err);
          });
        }}
        initialValues={bug}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <audio id="audio_player" src={bug?.base64data} controls="controls" autobuffer="autobuffer" />
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="transcript" 
              label={lang.transcript} 
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="transcriptEdit" 
              label={lang.correctedTranscript}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  {lang.btnCancel}
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                  {lang.btnSave}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
  );
};

export default BugEditForm;