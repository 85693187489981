import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, message, Menu, Dropdown } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import './styles.less';
import bgImg from 'assets/background.svg';
import { auth } from 'utils/firebase';

import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { localeAtom } from 'recoils/atoms';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import logo from 'assets/Color2.png';
import nttLogo from 'assets/nttdata_logo.svg';
import funixLogo from 'assets/funix-logo.png';
import iconFlagEn from 'assets/icons/united-states-flag.png';
import iconFlagVi from 'assets/icons/vietnam-flag.png';
import iconFlagJa from 'assets/icons/japan-flag.png'
import { IS_FUNIX, IS_NTTDATA, IS_NTTDATA_ON_PREMISE } from 'utils/constants';
import { resetPassword } from 'apis/auth';

const { REACT_APP_DEFAULT_LANG } = process.env;

const UserResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [locale, setLocale] = useRecoilState(localeAtom);

  const [loading, setLoading] = useState(false);
  const { token } = useParams()

  const onFinish = (values) => {
    setLoading(true);
    if (IS_NTTDATA_ON_PREMISE) {
      resetPassword(token, values).then(() => {
        message.success(lang.changePasswordSuccessContent);
        setLoading(false);
      }).catch(() => {
        message.error("Invalid token");
        setLoading(false);
      })
    }
  };

  useEffect(() => {
    if (locale === 'null') {
      setLocale(REACT_APP_DEFAULT_LANG)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (locale) => {
    setLocale(locale)
  };

  const languages = (
    <Menu>
      {IS_NTTDATA &&
        <Menu.Item onClick={() => changeLanguage('ja')}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <img src={iconFlagJa} width={24} alt="Japanese Language" />
            </div>
            <div style={{ marginLeft: 8, marginTop: 2 }}>日本語</div>
          </div>
        </Menu.Item>
      }
      <Menu.Item onClick={() => changeLanguage('en')}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img src={iconFlagEn} width={24} alt="English Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>English</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => changeLanguage('vi')}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img src={iconFlagVi} width={24} alt="Vietnam Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>Tiếng Việt</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const languageSelected = (locale) => {
    switch (locale) {
      case 'vi':
        return <img src={iconFlagVi} width={30} alt="Vietnamese Language" />;
      case 'en':
        return <img src={iconFlagEn} width={30} alt="English Language" />;
      case 'ja':
        return <img src={iconFlagJa} width={30} alt="Japanese Language" />;
      default:
        return IS_NTTDATA
          ? <img src={iconFlagJa} width={30} alt="Japanese Language" />
          : <img src={iconFlagVi} width={30} alt="Vietnamese Language" />;
    }
  };

  return (
    <div className="login-page">
      <Row justify="space-between" style={{ height: window.innerHeight }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}
          style={{ alignSelf: 'center' }} className="paddingCol">
          <div className="login-logo">
            {IS_NTTDATA
              ? <img src={nttLogo} alt="logo" style={{ height: 50, marginTop: "50px" }} />
              : IS_FUNIX
                ? <img src={funixLogo} alt="logo" style={{ height: 80, marginTop: "50px" }} />
                : <img src={logo} alt="logo" style={{ height: 80 }} />}
            <h3 className="login-title" style={{ marginTop: 40, marginBottom: 40 }}>
              <strong>{lang.user}</strong> {lang.changePassword}
            </h3>
          </div>
          <div>
            <Form
              name="loginForm"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                label={lang.newPassword}
                name="password"
                rules={[
                  { required: true, message: lang.requiredPassword },
                ]}
              >
                <Input
                  type='password'
                  size={'large'}
                  placeholder={lang.newPassword}
                />
              </Form.Item>
              <Form.Item>
                {loading === false ? (
                  <Button size={'large'} type="primary" htmlType="submit" block className="login-form-button">
                    {lang.btnSubmit}
                  </Button>
                ) : (
                  <Button size={'large'} type="primary" loading={loading} block className="login-form-button">
                    {lang.loading}
                  </Button>
                )}
              </Form.Item>
              <Form.Item>
                <p style={{ textAlign: 'right' }}>
                  <a href='#' onClick={(e) => {
                    e.preventDefault();
                    navigate('/');
                  }}>{lang.backToLogin}</a>
                </p>
              </Form.Item>
            </Form>
          </div>
          <div style={{ position: 'relative' }}>
            <Dropdown overlay={languages} placement="bottom">
              <div
                style={{
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 16,
                  width: 100
                }}
              >
                {languageSelected(locale)}
                <DownOutlined style={{ fontSize: 10, marginLeft: 8 }} />
              </div>
            </Dropdown>
          </div>
          <div style={{ paddingTop: '25vh' }}>{lang.slogan}</div>
          <div style={{ paddingTop: '3vh', fontWeight: 'bold' }}>{lang.companyRights}</div>
        </Col>
        <Col
          xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}
          style={{
            backgroundImage: `url(${bgImg})`,
            width: '100%',
            backgroundSize: 'cover',
            overflow: 'hidden',
          }}
        >
        </Col>
      </Row>
    </div>
  );
};

export default UserResetPassword;
