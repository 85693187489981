import React, { memo } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popover, Typography, Checkbox } from "antd";
import {
  formatDateTime,
  formatTimeToString,
  isEqualAllProps,
  speakerToString,
} from "utils/utils";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";
import { IS_NTTDATA } from "utils/constants";

const isEmpty = (value) => {
  return value === undefined || value === null;
};

const WordWrap = ({
  speaker,
  checked,
  setChecked,
  start,
  timestamp,
  status,
  onTimeClick,
  handleChangeSpeaker,
  index,
}) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const onTranscription = status === "on_transcription";
  const offTranscription = status === "off_transcription";
  const speakerString = !isEmpty(speaker)
    ? speakerToString(speaker)
    : onTranscription
    ? lang.transcriptionStarted
    : offTranscription
    ? lang.transcriptionStopped
    : lang.meetingClose;

  const { mode } = useParams();

  const content = (
    <Form
      layout="inline"
      name="basic"
      onFinish={(value) =>
        handleChangeSpeaker({ all: true, ...value }, speaker, index)
      }
    >
      <Form.Item
        name="speaker"
        rules={[
          {
            whitespace: true,
            required: true,
            message: "Please input speaker",
          },
        ]}
        label={`Change all ${speakerString} to:`}
      >
        <Input value={speakerString} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Change
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div
      className="transcriptEditor-title"
      style={{ backgroundColor: index % 2 === 0 ? "#f8f8f8" : "#ffffff" }}
      key={`paragraph${speakerString}-${index}`}
      contentEditable={false}
    >
      <div className="transcriptEditor-title-header">
        {!isEmpty(speaker) && !isEmpty(checked) && (
          <Checkbox
            style={{ marginRight: "10px" }}
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          />
        )}
        {!isEmpty(speaker) ? (
          <Popover content={content} trigger="click">
            <span className="transcriptEditor-title-speaker">
              {speakerString}
            </span>
          </Popover>
        ) : (
          <span
            className="transcriptEditor-title-speaker"
            style={{
              color: onTranscription
                ? "green"
                : offTranscription
                ? "red"
                : "gray",
            }}
          >
            {speakerString}
          </span>
        )}
        <Typography.Text
          className="transcriptEditor-title-time"
          type="secondary"
          data-start={!isEmpty(speaker) ? start : undefined}
          onClick={onTimeClick}
        >
          {mode === "edit" && !isEmpty(speaker) && <CaretRightOutlined />}
          {IS_NTTDATA
            ? formatDateTime(timestamp)
            : formatTimeToString(start)}
        </Typography.Text>
      </div>
    </div>
  );
};

export default memo(WordWrap, (prevProps, nextProps) => {
  return isEqualAllProps(prevProps, nextProps, {
    onTimeClick: () => false,
    handleChangeSpeaker: () => false,
    setChecked: () => false,
  });
});
