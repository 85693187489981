import React, { useState, useEffect } from 'react';

import { Layout, Table, Row, Col, Card } from 'antd';

import {db} from "utils/firebase";

import './styles.less';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { IS_NTTDATA_ON_PREMISE } from 'utils/constants';
import { userList } from 'apis/user';
import { dictListAdmin } from 'apis/dict';

const { REACT_APP_TENANT_ID } = process.env;

const Dicts = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [dicts, setDicts] = useState([]);

  useEffect(() => {
    setLoading(true)
    if(IS_NTTDATA_ON_PREMISE){
      const fetchData = async () =>{
        //Get User List
        const users = (await userList()).data?.users;
        setUsers(users);
        //Get Dict List
        const dicts = (await dictListAdmin()).data?.dicts;
        setDicts(dicts);
        setLoading(false);
      }
      fetchData()
    }else{
    db.collection('users')
      .where('tenantId', '==', REACT_APP_TENANT_ID)
      .orderBy('createdAt', 'desc')
      .onSnapshot(snapshot => {
        setUsers(snapshot.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data()?.createdAt.toDate(),
          }
        }))
      })

    db.collection('dicts')
      .where('tenantId', '==', REACT_APP_TENANT_ID)
      .onSnapshot(snapshot => {
        setDicts(snapshot.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        }))
      })
      setLoading(false);
    }
  }, []);

  const columns = [
    {
      title: lang.name,
      dataIndex: 'name',
      width: '20%',
      filterMultiple: true,
      filters: users?.map(item => {
        return {
          text: item.name,
          value: item?.id,
        }
      }),
      onFilter: (value, record) => record.id === value,
    },
    {
      title: lang.dictionary,
      dataIndex: 'dicts',
      render: (text, record, index) => {
        return dicts.filter(item => item.userId === record.id).map(item => (
          <div>
            <div>{item?.word1}, {item?.word3}, {item?.word5}, {item?.sortBy}</div>
          </div>
        ));
      }
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h2>{lang.dictionary}</h2>
              </div>
            </Col>
          </Row>
        </Card>
        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={users}
          rowKey={row=>row.id}
          pagination={{
            defaultPageSize: 20
          }}
        />
      </Layout>
    </Layout>

  )
};

export default Dicts;