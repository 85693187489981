import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Skeleton } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Text from "antd/lib/typography/Text";
import TranscriptEditorV2 from "components/TranscriptEditorV2";
import useAuth from "hooks/useAuth";
import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { IS_FUNIX } from "utils/constants";
import { db } from "utils/firebase";
import { getLanguages } from "utils/lang";
import KeywordsPanel from "./KeywordsPanel";
import MediaPanel from "./MediaPanel";
import "./styles.less";
import MeetingDetailHeader from "./MeetingDetailHeader";
import useDataToolRole from "hooks/useDataToolRole";
import { getTranscriptEditedDocId } from "utils/editor";

const { REACT_APP_TENANT_ID } = process.env;

const MeetingDetailV2 = ({ showKeywordPanel }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { user } = useAuth();
  const { fileId } = useParams();

  const mediaRef = useRef(null);

  const localSentences = useRef(null);
  const [sentences, setSentences] = useState(null);
  const [sentencesForKeywords, setSentencesForKeywords] = useState([]);
  const dicts = useRef([]);
  const [keywordHighlights, setKeywordHighlights] = useState({});
  const [jump, setJump] = useState(null);
  const [comparingSentences, setComparingSentences] = useState(null);
  const [comparingSentences2, setComparingSentences2] = useState(null);
  const [comparingSentences3, setComparingSentences3] = useState(null);
  const [showCompare, setShowCompare] = useState(false);

  const initEditor = useRef(false);
  const handleSave = useRef();

  const [fileMetadata, setFileMetadata] = useState(null);
  const [versionHistory, setVersionHistory] = useState([]);
  const [notes, setNotes] = useState("");
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const dataToolRole = useDataToolRole();
  const fetched = useRef(false);

  const normalizeKeywordHighlights = useMemo(() => {
    return _.flatten(_.values(keywordHighlights).map(_.values));
  }, [keywordHighlights]);

  useEffect(() => {
    setSentencesForKeywords(sentences);
  }, [sentences]);

  // Fetch sentences and dicts
  useEffect(() => {
    if (!user || fetched.current) {
      return;
    }
    fetched.current = true;
    (async () => {
      try {
        // Get file metadata
        const fileRef = db.collection("files").doc(fileId);
        const fileMetadata = (await fileRef.get()).data();

        if (!fileMetadata) {
          setError("not_found");
          return;
        }

        if (dataToolRole === "annotator" && fileMetadata?.annotatorSubmitted) {
          setSubmitted(true);
        } else if (
          dataToolRole === "reviewer" &&
          fileMetadata?.reviewerSubmitted
        ) {
          setSubmitted(true);
        } else if (
          dataToolRole === "approver" &&
          fileMetadata?.approverSubmitted
        ) {
          setSubmitted(true);
        }

        setFileMetadata(fileMetadata);
        setNotes(fileMetadata.notes ?? "");

        // Get dict
        let query = db.collection("dicts");

        if (IS_FUNIX)
          query = query.where("tenantId", "==", REACT_APP_TENANT_ID);
        else query = query.where("userId", "==", user?.uid);

        const dictDocs = await query.orderBy("sortBy", "asc").get();
        dicts.current = dictDocs.docs.map((doc) => ({
          ...doc.data(),
          dictId: doc.id,
        }));

        // Get sentences
        if (dataToolRole === "admin") {
          const sentenceDoc = await fileRef.collection("sentences").get();
          let annotatorEditedDoc = await fileRef
            .collection("edited")
            .doc("annotator")
            .get();
          let reviewerEditedDoc = await fileRef
            .collection("edited")
            .doc("reviewer")
            .get();
          let approverEditedDoc = await fileRef
            .collection("edited")
            .doc("user")
            .get();
          localSentences.current = sentenceDoc.docs.map((doc) => doc.data());
          if (annotatorEditedDoc.exists) {
            setComparingSentences(annotatorEditedDoc.data().sentences);
          }
          if (reviewerEditedDoc.exists) {
            setComparingSentences2(reviewerEditedDoc.data().sentences);
          }
          if (approverEditedDoc.exists) {
            setComparingSentences3(approverEditedDoc.data().sentences);
          }
        } else {
          let sentenceDoc = await fileRef
            .collection("edited")
            .doc(getTranscriptEditedDocId(dataToolRole))
            .get();
          if (sentenceDoc.data()?.versionHistory) {
            setVersionHistory(sentenceDoc.data().versionHistory);
          }
          if (dataToolRole === "reviewer") {
            const annotatorEditedDoc = await fileRef
              .collection("edited")
              .doc("annotator")
              .get();
            if (!sentenceDoc.exists) {
              sentenceDoc = annotatorEditedDoc;
            }
            if (sentenceDoc.exists) {
              localSentences.current = sentenceDoc.data().sentences;
            } else {
              localSentences.current = [];
            }
            setComparingSentences(annotatorEditedDoc.data().sentences);
          } else if (dataToolRole === "approver") {
            const reviewerEditedDoc = await fileRef
              .collection("edited")
              .doc("reviewer")
              .get();
            if (!sentenceDoc.exists) {
              sentenceDoc = reviewerEditedDoc;
            }
            if (sentenceDoc.exists) {
              localSentences.current = sentenceDoc.data().sentences;
            } else {
              localSentences.current = [];
            }
            setComparingSentences(reviewerEditedDoc.data().sentences);
          } else {
            if (sentenceDoc.exists) {
              localSentences.current = sentenceDoc.data().sentences;
            } else {
              sentenceDoc = await fileRef.collection("sentences").get();
              localSentences.current = sentenceDoc.docs.map((doc) =>
                doc.data()
              );
            }
          }
        }
        setSentences(_.cloneDeep(localSentences.current));
      } catch (e) {
        setError(e.message);
      }
    })();
  }, [user]);

  const debouncedSave = useCallback(
    _.debounce(() => handleSave.current(), 1000),
    []
  );

  if (error) {
    return (
      <div
        style={{
          textAlign: "center",
          padding: 60,
          fontSize: 40,
          fontWeight: "bold",
        }}
        className="edit"
      >
        {error === "not_found" ? "Transcript not found!" : error}
      </div>
    );
  }

  const exportSentences =
    dataToolRole !== "admin"
      ? [{ sentences: localSentences.current }]
      : [
          { sentences: localSentences.current },
          comparingSentences
            ? { label: "annotator", sentences: comparingSentences }
            : null,
          comparingSentences2
            ? { label: "reviewer", sentences: comparingSentences2 }
            : null,
          comparingSentences3
            ? { label: "approver", sentences: comparingSentences3 }
            : null,
        ].filter((data) => data !== null);

  return (
    <div className="edit">
      <MeetingDetailHeader
        exportSentences={exportSentences}
        handleSave={handleSave}
        submitted={submitted}
        initEditor={initEditor}
        setSubmitted={setSubmitted}
        fileMetadata={fileMetadata}
        localSentences={localSentences}
        showCompare={showCompare}
        setShowCompare={setShowCompare}
        versionHistory={versionHistory}
        setVersionHistory={setVersionHistory}
        notes={notes}
        comparingSentences={comparingSentences}
        sentences={sentences}
        setSentences={setSentences}
        setComparingSentences={setComparingSentences}
      />
      <Divider style={{ margin: 0 }} />
      <Row className="edit-row">
        <Col span={7} className="edit-rightcol">
          <Skeleton loading={!fileMetadata}>
            {fileMetadata && (
              <>
                <MediaPanel ref={mediaRef} url={fileMetadata?.url} />
                {showKeywordPanel && (
                  <div>
                    <KeywordsPanel
                      mediaRef={mediaRef}
                      sentences={sentencesForKeywords}
                      setJump={setJump}
                      keywordHighlights={keywordHighlights}
                      setKeywordHighlights={setKeywordHighlights}
                    />
                  </div>
                )}
                <div className="edit-video-note" direction="vertical">
                  <label htmlFor="notes-area">
                    <Text style={{ textTransform: "uppercase" }}>
                      {lang.notes}
                    </Text>
                  </label>
                  <TextArea
                    id="notes-area"
                    className="edit-video-note-textarea"
                    placeholder={lang.writeNotesHere}
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          </Skeleton>
        </Col>
        <Col span={17} className="edit-leftcol">
          <Skeleton loading={!sentences} paragraph={{ rows: 15 }}>
            <div style={{ display: "flex" }}>
              {sentences && sentences.length > 0 ? (
                <div
                  style={
                    (comparingSentences ||
                      comparingSentences2 ||
                      comparingSentences3) &&
                    (showCompare || dataToolRole === "admin")
                      ? {
                          maxHeight: "calc(100vh - 125px)",
                          overflowY: "scroll",
                          flex: 1,
                        }
                      : {
                          flex: 1,
                        }
                  }
                >
                  <TranscriptEditorV2
                    readOnly={dataToolRole === "admin" || submitted}
                    jump={jump}
                    customHighlights={normalizeKeywordHighlights}
                    sentences={sentences}
                    dicts={dicts.current}
                    onChange={(value) => {
                      if (!_.isEqual(localSentences.current, value)) {
                        if (initEditor.current) debouncedSave();
                        initEditor.current = true;
                      }
                      localSentences.current = _.cloneDeep(value);
                    }}
                    videoRef={mediaRef}
                  />
                </div>
              ) : (
                <div>Not found</div>
              )}
              {comparingSentences &&
                (showCompare || dataToolRole === "admin") && (
                  <div
                    style={{
                      flex: 1,
                      maxHeight: "calc(100vh - 125px)",
                      overflowY: "scroll",
                    }}
                  >
                    <TranscriptEditorV2
                      readOnly
                      sentences={comparingSentences}
                      dicts={dicts.current}
                      onChange={() => {}}
                      videoRef={mediaRef}
                    />
                  </div>
                )}
              {comparingSentences2 &&
                (showCompare || dataToolRole === "admin") && (
                  <div
                    style={{
                      flex: 1,
                      maxHeight: "calc(100vh - 125px)",
                      overflowY: "scroll",
                    }}
                  >
                    <TranscriptEditorV2
                      readOnly
                      sentences={comparingSentences2}
                      dicts={dicts.current}
                      onChange={() => {}}
                      videoRef={mediaRef}
                    />
                  </div>
                )}
              {comparingSentences3 &&
                (showCompare || dataToolRole === "admin") && (
                  <div
                    style={{
                      flex: 1,
                      maxHeight: "calc(100vh - 125px)",
                      overflowY: "scroll",
                    }}
                  >
                    <TranscriptEditorV2
                      readOnly
                      sentences={comparingSentences3}
                      dicts={dicts.current}
                      onChange={() => {}}
                      videoRef={mediaRef}
                    />
                  </div>
                )}
            </div>
          </Skeleton>
          <div className="navigate-container">
            <Button
              type="primary"
              onClick={() => {
                const ele = document.getElementsByClassName("edit-leftcol")[0];
                ele.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <ArrowUpOutlined />
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const ele = document.getElementsByClassName("edit-leftcol")[0];
                ele.scrollTo({
                  top: ele.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              <ArrowDownOutlined />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default React.memo(MeetingDetailV2);
