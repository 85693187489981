import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Collapse, Divider, Row } from 'antd';
import { db } from 'utils/firebase';
import useAuth from 'hooks/useAuth';
import _ from 'lodash';
import { IS_SAFARI, VNMESE_CHAR } from 'utils/constants';
import XRegExp from 'xregexp';

const LoadKeyword = ({ id, loadAllKeywordCallbacks }) => {
    const { user } = useAuth();

    const [loading, setLoading] = useState(false);
    const [keywords, setKeywords] = useState(null);
    const loaded = useRef(false);

    const handleLoad = async () => {
        if (loaded.current) {
            return;
        }
        setLoading(true);
        try {
            const sentencesDoc = await db.collection("files").doc(id).collection("sentences").get();
            const sentences = sentencesDoc.docs.map(doc => doc.data());

            const keywordsDoc = await db.collection("keywords").where("userId", "==", user?.uid).get();
            const keywordCategories = keywordsDoc.docs.map(doc => doc.data());

            const matches = [];
            keywordCategories.forEach(keywordCategory => {
                let categoryMatches = [];
                (keywordCategory?.words ?? []).forEach((keyword) => {
                    let { text } = keyword;
                    text = text.trim().normalize("NFC");
                    if (text.trim().length === 0) {
                        return;
                    }
                    let keywordMatches = [];
                    sentences.forEach((sentence) => {
                        let sentenceMatches;
                        if (keyword.isRegex) {
                            const wordRegex = new RegExp(text, "igm");
                            sentenceMatches = sentence.transcript.match(wordRegex);
                        } else {
                            if (IS_SAFARI) {
                                const regex = new RegExp(`${text}(?!${VNMESE_CHAR}+)`);
                                sentenceMatches = XRegExp.matchAllLb(sentence.transcript, `(?<!${VNMESE_CHAR}+)`, regex);
                            } else {
                                const regex = new RegExp(
                                    `(?<!${VNMESE_CHAR}+)${text}(?!${VNMESE_CHAR}+)`,
                                    "igm"
                                );
                                sentenceMatches = sentence.transcript.match(regex);
                            }
                        }
                        if (sentenceMatches) {
                            keywordMatches.push(...sentenceMatches);
                        }
                    })
                    if (!_.isEmpty(keywordMatches)) {
                        categoryMatches.push({
                            keyword: text,
                            isRegex: keyword.isRegex,
                            matches: keywordMatches
                        })
                    }
                })
                if (!_.isEmpty(categoryMatches))
                    matches.push({
                        label: keywordCategory.label,
                        matches: categoryMatches
                    });
            })
            setKeywords(matches);
            loaded.current = true;
        } catch {
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadAllKeywordCallbacks.current[id] = handleLoad;
        return () => {
            loadAllKeywordCallbacks.current = _.omit(loadAllKeywordCallbacks.current, id);
        }
    }, [])

    return keywords ?
        <div>
            {
                _.isEmpty(keywords)
                    ? <div>No keyword matched</div>
                    :
                    <Collapse>
                        {
                            keywords.map((category, i) => {
                                return (
                                    <Collapse.Panel key={i} header={category.label + ` (${category.matches.length})`}>
                                        {category.matches.map((match, i) =>
                                            <>
                                                {(!match.isRegex || true) &&
                                                    <>
                                                        {i !== 0 && <Divider style={{ margin: "4px 0" }} />}
                                                        <Row justify="space-between">
                                                            <Col>{match.keyword}</Col>
                                                            <Col>{match.matches.length}</Col>
                                                        </Row>
                                                    </>
                                                }
                                                {/* {match.isRegex &&
                                                    <>
                                                        <Collapse header={"asda"}>
                                                            <CollapsePanel>
                                                                {match.matches.map(match => <div>{match}</div>)}
                                                            </CollapsePanel>
                                                        </Collapse>
                                                    </>
                                                } */}
                                            </>
                                        )}
                                    </Collapse.Panel>
                                )
                            })
                        }
                    </Collapse>
            }
        </div>
        : <Button loading={loading} onClick={handleLoad}>Load keyword</Button>
}

export default LoadKeyword;