module.exports = [
  {
    key: '1',
    name: 'Search',
    tags: ['CTRL', 'SHIFT', 'F'],
  },
  {
    key: '2',
    name: 'Bold',
    tags: ['CTRL', 'B'],
  },
  {
    key: '3',
    name: 'Highlight',
    tags: ['CTRL', 'J'],
  },
  {
    key: '4',
    name: 'Strikethrough',
    tags: ['CTRL', 'H'],
  },
  {
    key: '5',
    name: 'Undo',
    tags: ['CTRL', 'Z'],
  },
  {
    key: '6',
    name: 'Redo',
    tags: ['CTRL', 'Y'],
  },
  {
    key: '7',
    name: 'Cut',
    tags: ['CTRL', 'X'],
  },
  {
    key: '8',
    name: 'Copy',
    tags: ['CTRL', 'C'],
  }, {
    key: '9',
    name: 'Paste',
    tags: ['CTRL', 'V'],
  },
  {
    key: '10',
    name: 'Skip to',
    tags: ['F8'],
  }
];