import request from "utils/request";

const { REACT_APP_API, REACT_APP_FUNIX_API } = process.env;

export const transcriptGet = async (payload) => {
    return request(`${REACT_APP_API}/transcript/${payload}`, {}, { method: "GET" })
};
  
export const transcriptDelete = async (payload) => {
    return request(`${REACT_APP_FUNIX_API}/file`, payload, { method: "POST" })
}

export const monitorGet = async (payload) => {
    return request(`${REACT_APP_API}/monitor`, payload, { method: "GET" })
};