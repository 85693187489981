/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import { Layout, Table, Row, Col, Card, Upload, Input, Button, Form, Tag, Modal, Dropdown, Menu, Space, message, Select, DatePicker, Checkbox, InputNumber } from 'antd';
import { SyncOutlined, PlusCircleOutlined, ExclamationCircleOutlined, DeleteOutlined, MinusCircleOutlined, EyeOutlined } from '@ant-design/icons';

import moment from 'moment';
import {db, storage} from "utils/firebase";
import useAuth from 'hooks/useAuth';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './styles.less';

import { MeiliSearch } from 'meilisearch';

import { transcriptDelete } from "apis/transcript";

import _extends from "@babel/runtime/helpers/extends";
import CalendarLocale from "rc-picker/es/locale/vi_VN";
import TimePickerLocale from 'antd/es/time-picker/locale/vi_VN';

import usLocale from 'antd/lib/date-picker/locale/en_US';
import { IS_FUNIX } from 'utils/constants';

const { RangePicker } = DatePicker;
const { Option } = Select;

const { confirm } = Modal;


const { REACT_APP_TENANT_ID, REACT_APP_MEILI_HOST, REACT_APP_MEILI_API_KEY } = process.env;

const searchClient = IS_FUNIX ? new MeiliSearch({
  host: REACT_APP_MEILI_HOST,
  apiKey: REACT_APP_MEILI_API_KEY,
}) : null;

const Users = () => {
  const { t, i18n } = useTranslation();
  const language = getLanguages(t);

  const {user, token} = useAuth();

  const [files, setFiles] = useState([]);
  const [filterFiles, setFilterFiles] = useState([]);
  const [facetFiles, setFacetFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [fromMethod, setFromMethod] = useState('');
  const [fromName, setFromName] = useState('');
  const [toName, setToName] = useState('');
  const [fromNumber, setFromNumber] = useState('');
  const [toNumber, setToNumber] = useState('');
  const [toAlias, setToAlias] = useState('');
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('TRANSCRIBED');
  const [minDuration, setMinDuration] = useState('');
  const [pickUp, setPickUp] = useState('true');

  const [fromNameList, setFromNameList] = useState([]);
  const [toNameList, setToNameList] = useState([]);
  const [timestampList, setTimestampList] = useState([]);

  const [fromNameFacetList, setFromNameFacetList] = useState([]);
  const [toNameFacetList, setToNameFacetList] = useState([]);
  const [timestampFacetList, setTimestampFacetList] = useState([]);

  const [fromNameListChecked, setFromNameListChecked] = useState([]);
  const [toNameListChecked, setToNameListChecked] = useState([]);
  const [timestampChecked, setTimestampChecked] = useState([]);
  const [durationChecked, setDurationChecked] = useState([]);
  const [fromMethodChecked, setFromMethodChecked] = useState([]);
  const [statusListChecked, setStatusListChecked] = useState([]);
  const [fromNumberChecked, setFromNumberChecked] = useState([]);
  const [toNumberChecked, setToNumberChecked] = useState([]);

  const [moreFromName, setMoreFromName] = useState(false);
  const [moreToName, setMoreToName] = useState(false);
  const [moreTimestamp, setMoreTimestamp] = useState(false);

  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [showMoreMetadata, setShowMoreMetadata] = useState([]);

  const statusList = [
    ['UPLOADED', language.uploaded],
    ['TRANSCRIBING', language.transcribing],
    ['TRANSCRIBED', language.transcribed],
    ['ERROR', language.noSpeechData],
    [undefined, language.noAudioFound]
  ]

  const locale = {
    lang: _extends({
      placeholder: 'Chọn thời điểm',
      rangePlaceholder: ['Từ ngày', 'Đến ngày']
    }, CalendarLocale),
    timePickerLocale: _extends({}, TimePickerLocale)
  };

  useEffect(() => {
    const func = async () => {
      if (user) {
        let startDate = moment();
        let endDate = moment();

        setLoading(true)
  
        const latestData = await searchClient.index('transcripts').search('', {
          limit: 1,
          sort: ['timestamp_fm:desc'],
        });

        if (latestData.hits.length > 0) {
          startDate = moment.unix(latestData.hits[0].timestamp_fm);
          endDate = moment.unix(latestData.hits[0].timestamp_fm);
          setStartDate(startDate);
          setEndDate(endDate);
        }

        const results = await searchClient.index('transcripts').search('', {
          limit: 999999,
          filter: `timestamp_fm >= ${startDate.startOf('day').unix()} AND timestamp_fm <= ${endDate.endOf('day').unix()} AND answer_duration >= 1 AND status = TRANSCRIBED`,
        });

        let files = results.hits;

        if (startDate && endDate) {
          const start = moment(startDate).startOf('day').unix();
          const end = moment(endDate).endOf('day').unix();
          files = files.filter(file => file.timestamp_fm >= start && file.timestamp_fm <= end);
        }
  
        const fromNameList = [...new Set(files.map(file => file.from_name))].filter(item => item).sort((a, b) => a.localeCompare(b));
        const toNameList = [...new Set(files.map(file => file.to_name))].filter(item => item).sort((a, b) => a.localeCompare(b));
        const timestampList = [...new Set(files.map(file => file.timestamp_fm && moment.unix(file.timestamp_fm).format('DD-MM-YYYY')))].filter(item => item).sort((a, b) => a.localeCompare(b));

        setFromNameList(fromNameList);
        setToNameList(toNameList);
        setTimestampList(timestampList);

        setFromNameFacetList(fromNameList);
        setToNameFacetList(toNameList);
        setTimestampFacetList(timestampList);

        setFiles(files);
        setFilterFiles(files);

        setLoading(false);
  
        db.doc(`users/${user.uid}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return;
          }
        })
        .catch((err) => {
        });
      }
    }

    func();
  }, [user]);

  useEffect(() => {
    setShowMoreMetadata([]);

    if (fromNameListChecked.length !== 0 || toNameListChecked.length !== 0 || timestampChecked.length !== 0 || durationChecked.length !== 0 || fromMethodChecked.length !== 0 || statusListChecked.length !== 0 || fromNumberChecked.length !== 0 || toNumberChecked.length !== 0) {
      setFacetFiles(filterFiles.filter(file => {
        if (fromNameListChecked.length > 0 && !fromNameListChecked.includes(file.from_name)) return false;
        if (toNameListChecked.length > 0 && !toNameListChecked.includes(file.to_name)) return false;
        if (timestampChecked.length > 0 && !timestampChecked.includes(moment.unix(file.timestamp_fm).format('DD-MM-YYYY'))) return false;
        if (durationChecked.length === 1 && file?.answer_duration !== undefined) {
          if (durationChecked[0] === '0' && file?.answer_duration > 0) return false;
          if (durationChecked[0] === '> 0' && file?.answer_duration == 0) return false;
        }
        if (fromMethodChecked.length > 0 && !fromMethodChecked.includes(file.from_method)) return false;
        if (statusListChecked.length > 0 && !statusListChecked.includes(file.status)) return false;
        if (fromNumberChecked.length > 0 && !fromNumberChecked.includes(file.from_number)) return false;
        if (toNumberChecked.length > 0 && !toNumberChecked.includes(file?.to_number)) return false;
        
        return true;
      }));
    }
    else setFacetFiles(filterFiles);
  }, [fromNameListChecked, toNameListChecked, timestampChecked, durationChecked, fromMethodChecked, statusListChecked, fromNumberChecked, toNumberChecked, filterFiles]);

  const onSearch = async () => {
    // Empty checked list
    setFromNameListChecked([]);
    setToNameListChecked([]);
    setTimestampChecked([]);
    setDurationChecked([]);
    setFromMethodChecked([]);
    setStatusListChecked([]);
    setFromNumberChecked([]);
    setToNumberChecked([]);

    setShowMoreMetadata([]);

    setLoading(true);

    let filterQuery = [];
    // Filter Query for Meilisearch
    if (fromMethod) filterQuery.push(`from_method = "${fromMethod}"`);
    if (fromName) filterQuery.push(`from_name = "${fromName}"`);
    if (toName) filterQuery.push(`to_name = "${toName}"`);
    if (fromNumber) filterQuery.push(`from_number = "${fromNumber}"`);
    if (toNumber) filterQuery.push(`to_number = "${toNumber}"`);
    if (toAlias) filterQuery.push(`to_alias = "${toAlias}"`);
    if (startDate && endDate) filterQuery.push(`timestamp_fm >= ${moment(startDate).startOf('day').unix()} AND timestamp_fm <= ${moment(endDate).endOf('day').unix()}`);
    if (pickUp) {
      if (pickUp == 'true') filterQuery.push(`answer_duration >= 1`);
      else if (pickUp == 'false') filterQuery.push(`answer_duration = 0`);
    }
    if (status) filterQuery.push(`status = "${status}"`);
    if (status == undefined) filterQuery.push(`status NOT EXISTS`);
    if (minDuration) filterQuery.push(`answer_duration >= ${minDuration}`);

    const result = await searchClient.index('transcripts').search('', {
      q: keyword || '',
      filter: filterQuery.join(' AND '),
      limit: 999999
    });

    setFiles(result.hits);
    let filterFiles = result.hits;

    if (startDate && endDate) {
      const start = moment(startDate).startOf('day').unix();
      const end = moment(endDate).endOf('day').unix();
      filterFiles = filterFiles.filter(file => file.timestamp_fm >= start && file.timestamp_fm <= end);
    }

    setFilterFiles(filterFiles);
    setLoading(false);

    const fromNameList = [...new Set(filterFiles.map(file => file?.from_name))].filter(item => item).sort((a, b) => a.localeCompare(b));
    const toNameList = [...new Set(filterFiles.map(file => file?.to_name))].filter(item => item).sort((a, b) => a.localeCompare(b));
    const timestampList = [...new Set(filterFiles.map(file => file.timestamp_fm && moment.unix(file.timestamp_fm).format('DD-MM-YYYY')))].filter(item => item).sort((a, b) => a.localeCompare(b));

    setFromNameFacetList(fromNameList);
    setToNameFacetList(toNameList);
    setTimestampFacetList(timestampList);
  }

  const onDelete = async (record) => {
    setLoading(true);
    transcriptDelete({
      fileName: record.name,
    })
    .then(res => {
      if (res.message == "File deleted successfully") {
        message.success(language.deleteSuccess);
        setLoading(false);
      }
      else {
        message.error(language.deleteFail);
        setLoading(false);
      }
    })
    .catch(err => {
      message.error(language.deleteFail);
      setLoading(false);
    });
  }

  const columns = [
    {
      title: 'Metadata',
      width: '10%',
      render: (text, record, index) => {
        if (record) {
          return (<div>
            {record && <div>
              <div><b>answer_duration_fm:</b> {record.answer_duration_fm}</div>
              <div><b>from_method:</b> {record.from_method}</div>
              <div><b>from_name:</b> <a onClick={(e) => {
                e.preventDefault();
                setToNameListChecked([]);
                setTimestampChecked([]);
                setDurationChecked([]);
                setFromMethodChecked([]);
                setStatusListChecked([]);
                setFromNumberChecked([]);
                setToNumberChecked([]);
                if (fromNameListChecked.includes(record.from_name) && fromNameListChecked.length === 1) setFromNameListChecked([]);
                else setFromNameListChecked([record.from_name]);
                setMoreFromName(true);
                if (fromNumberChecked.length > 0) {
                  setFromNumber("");
                }
                if (toNumberChecked.length > 0) {
                  setToNumber("");
                }
                setAdvancedSearch(true);
              }}>{record.from_name}</a></div>
              <div><b>from_number:</b> <a onClick={(e) => {
                e.preventDefault();
                setFromNameListChecked([]);
                setToNameListChecked([]);
                setTimestampChecked([]);
                setDurationChecked([]);
                setFromMethodChecked([]);
                setStatusListChecked([]);
                setToNumberChecked([]);
                if (fromNumberChecked.includes(record.from_number)) {
                  setFromNumberChecked([]);
                  setFromNumber("");
                }
                else {
                  setFromNumberChecked([record.from_number]);
                  setFromNumber(record.from_number);
                }
                if (toNumberChecked.length > 0) {
                  setToNumber("");
                }
                setAdvancedSearch(true);
              }}>{record.from_number}</a></div>
              <div><b>to_number:</b> <a onClick={(e) => {
                e.preventDefault();
                setToNumber(record.to_number);
                setFromNameListChecked([]);
                setToNameListChecked([]);
                setTimestampChecked([]);
                setDurationChecked([]);
                setFromMethodChecked([]);
                setStatusListChecked([]);
                setFromNumberChecked([]);
                if (toNumberChecked.includes(record.to_number)) {
                  setToNumberChecked([]);
                  setToNumber("");
                }
                else {
                  setToNumberChecked([record.to_number]);
                  setToNumber(record.to_number);
                }
                if (fromNumberChecked.length > 0) {
                  setFromNumber("");
                }
                setAdvancedSearch(true);
              }}>{record.to_number}</a></div>
            </div>}
            {showMoreMetadata.includes(index)
              ? <div>
                  <div><b>answer_duration:</b> {record.answer_duration}</div>
                  <div><b>call_id:</b> {record.call_id}</div>
                  <div><b>duration:</b> {record.duration}</div>
                  <div><b>to_alias:</b> {record.to_alias}</div>
                  <Button
                    style={{marginLeft: -15}}
                    type="link"
                    onClick={() => setShowMoreMetadata(showMoreMetadata.filter(item => item !== index))}
                  >
                    <MinusCircleOutlined /> {language.showLess}
                  </Button>
                </div>
              : <Button
                style={{marginLeft: -15}}
                type="link"
                onClick={() => setShowMoreMetadata([...showMoreMetadata, index])}
              >
                <PlusCircleOutlined /> {language.showMore}
              </Button>
            }
          </div>)
        }
        else return null;
      }
    },
    {
      title: language.length,
      align: 'center',
      dataIndex: '',
      width: '3%',
      sorter: {
        compare: (a, b) => {
          setShowMoreMetadata([]);
          return a.answer_duration_fm.localeCompare(b.answer_duration_fm)
        },
      },
      render: (text, record, index) => {
        return (
          <div>{record.answer_duration_fm}</div>
        );
      }
    },
    {
      title: language.callDate,
      width: '4%',
      sorter: {
        compare: (a, b) => {
          setShowMoreMetadata([]);
          return a?.timestamp_fm - b?.timestamp_fm
        },
      },
      render: (text, record, index) => {
        return (<div>{moment.unix(record.timestamp_fm).format("DD-MM-YYYY HH:mm:ss")}</div>)
      }
    },
    {
      title: language.status,
      align: 'center',
      width: '3%',
      sorter: {
        compare: (a, b) => {
          setShowMoreMetadata([]);
          return (a.status || "").localeCompare(b.status || "")
        },
      },
      render: (record) => {
        switch (record.status) {
          case 'TRANSCRIBING':
            return (
              <Tag icon={<SyncOutlined spin />} color='#108ee9'>
                {language.transcribing}
              </Tag>
            )
          case 'TRANSCRIBED':
            return (
              <a target={'_blank'} href={`/user/transcripts/edit/${record.firebaseDocId}`}>
                <Tag color='#87d068'>{language.ready}</Tag>
              </a>
            )
          case 'ERROR':
            return (
              <Tag color='#ff0000'>{language.noSpeechData}</Tag>
            )
          case 'UPLOADED':
            return (
              <Tag color='#ff9300'>{language.uploaded}</Tag>
            )
          default:
            return <Tag color='grey'>{language.noAudioFound}</Tag>;
        }
      },
    },
    {
      title: '',
      align: 'center',
      width: '2%',
      render: (record) => {
        console.log('record: ', record)
        return (
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            {(record.status === 'TRANSCRIBED' && record.length !== '00:00:00') && <a target={'_blank'} href={`/user/transcripts/edit/${record.firebaseDocId}`}><Button 
            type="link" 
            icon={<EyeOutlined />}
            /></a>}
            
            <Button 
              icon={<DeleteOutlined />}
              type="link" 
              danger
              onClickCapture={() => {
                confirm({
                  title: language.doYouWantToDeleteTheseItems,
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    onDelete(record);
                  },
                  onCancel() {
                    console.log('Cancel');
                  },
                });
            }} />
          </div>
        );
      }
    }
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 20 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h3>{language.calls}</h3>
            </Col>
          </Row>
            {/* <div style={{ width: 200, marginLeft: 0, position: 'absolute' }}>
              <Upload
                accept=".xlsx,.xls"
                style={{ marginLeft: 100 }}
                showUploadList={false}
                beforeUpload={(file) => {
                  setLoadingUpload(true);
                  const reader = new FileReader();
                  reader.onload = (evt) => {
                    const bstr = evt.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
    
                    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                    
                    const header = data[0];
                    const body = data.slice(1);
                    
                    const index = body.map(item => {
                      let metadata = {};
                      for (let i = 1; i < header.length; i++) {
                        metadata[header[i]] = item[i] || "";
                      }

                      db.collection('files').where('name', '==', item[0]).get().then(snapshot => {
                        if (snapshot.empty) {
                          db.collection('files').add({
                            name: item[0],
                            metadata,
                            createdAt: new Date(),
                          })
                        }
                        else {
                          snapshot.forEach(doc => {
                            db.collection('files').doc(doc.id).update({
                              metadata,
                            })
                          })
                        }
                      })

                      return {
                        id: item[0],
                        ...metadata,
                        timestamp_fm: moment(metadata.timestamp_fm).unix()
                      }
                    })

                    searchClient.index('transcripts').updateDocuments(index).then(() => {
                      message.success(`${index.length} ${language.uploadSuccess}`);
                    }).catch(err => {
                      message.error(language.uploadFail);
                    });

                    setLoadingUpload(false);
                  };
                  reader.readAsBinaryString(file);
                  return false;
                }}
                onRemove={(file) => {
                  //setDicts(dicts);
                }}
                maxCount={1}
              >
                <Button loading={loadingUpload} type={'primary'} icon={<UploadOutlined />}>{`${language.btnUpload} Metadata`}</Button>
              </Upload>
            </div> */}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <RangePicker
                    style={{ marginRight: 10, marginBottom: 18, width: 410 }}
                    onChange={(date, dateString) => {
                      if (date) {
                        setStartDate(date[0]);
                        setEndDate(date[1]);
                      }
                      else {
                        setStartDate(null);
                        setEndDate(null);
                      }
                    }}
                    value={[startDate, endDate]}
                    format={"DD-MM-YYYY"}
                    ranges={{
                      [language.today]: [moment(), moment()],
                      [language.yesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                      [language.thisWeek]: [moment().startOf('week'), moment().endOf('week')],
                      [language.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
                      [language.thisQuarter]: [moment().startOf('quarter'), moment().endOf('quarter')],
                      [language.thisYear]: [moment().startOf('year'), moment().endOf('year')],
                    }}
                    locale={i18n.language === 'en' ? usLocale : locale}
                    allowClear={false}
                  />

                  <Input
                    style={{ width: 200, marginBottom: 18, marginRight: 10, height: 32 }}
                    placeholder={language.keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                  />

                  <InputNumber
                    style={{ width: 200, marginBottom: 18, marginRight: 10, height: 32 }}
                    placeholder={`${language.minDuration} (${language.seconds})`}
                    min={0}
                    onChange={(value) => {
                      setMinDuration(value);
                    }}
                  />

                  <Button
                    type="primary"
                    style={{ width: 125, marginBottom: 18 }}
                    onClick={() => onSearch()}
                    loading={loading}
                  >
                    {language.search}
                  </Button>

                  <Button
                    type="link"
                    style={{ marginLeft: 10, marginBottom: 18 }}
                    onClick={() => setAdvancedSearch(prev => !prev)}
                  >
                    {advancedSearch
                    ? language.hideAdvancedSearch
                    : language.advancedSearch}
                  </Button>

                  <Button
                    type="link"
                    style={{ marginLeft: 10, marginBottom: 18 }}
                  >
                    <a href="/user/transcripts/followup" target={"_blank"}>
                      {language.followUp}
                    </a>
                  </Button>
                </div>
                {advancedSearch && (
                  <div>
                    <div>
                    <Select
                      allowClear={false}
                      showSearch
                      style={{ width: 200, marginBottom: 18, marginRight: 10 }}
                      placeholder={`${language.internal} / ${language.external}`}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setFromMethod(value);
                      }}
                      defaultValue={""}
                    >
                      <Option value="">{language.all}</Option>
                      <Option value="internal">{language.internal}</Option>
                      <Option value="external">{language.external}</Option>
                    </Select>
                    <Select
                      allowClear={false}
                      showSearch
                      style={{ width: 200, marginBottom: 18, marginRight: 10 }}
                      placeholder={language.caller}
                      filterOption={(input, option) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                      value={fromName}
                      onChange={(value) => {
                        setFromName(value);
                      }}
                    >
                      <Option value="">{language.all}</Option>
                      {fromNameList.map(item => (
                        <Option value={item}>{item}</Option>
                      ))}
                    </Select>

                    <Select
                      allowClear={false}
                      showSearch
                      style={{ width: 200, marginBottom: 18, marginRight: 10 }}
                      placeholder={language.status}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setStatus(value);
                      }}
                      value={status}
                    >
                      <Option value="">{language.all}</Option>
                      {statusList.map(item => {
                        return (
                          <Option value={item[0]}>{item[1]}</Option>
                        )
                      })}
                    </Select>

                    <Select
                      allowClear={false}
                      showSearch
                      style={{ width: 200, marginBottom: 18, marginRight: 10 }}
                      placeholder={`${language.pickUp} / ${language.dontPickUp}`}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setPickUp(value);
                      }}
                      value={pickUp}
                    >
                      <Option value="">{language.all}</Option>
                      <Option value="true">{language.pickUp}</Option>
                      <Option value="false">{language.dontPickUp}</Option>
                    </Select>
                  </div>

                  <div>
                    <Input
                      style={{ width: 200, marginBottom: 18, marginRight: 10, height: 32 }}
                      placeholder={language.fromNumber}
                      value={fromNumber}
                      onChange={(e) => {
                        setFromNumber(e.target.value);
                      }}
                      allowClear
                    />

                    <Input
                      style={{ width: 200, marginBottom: 18, marginRight: 10, height: 32 }}
                      placeholder={language.toNumber}
                      value={toNumber}
                      onChange={(e) => {
                        setToNumber(e.target.value);
                      }}
                      allowClear
                    />
                    <Input
                      style={{ width: 200, marginBottom: 18, marginRight: 10, height: 32 }}
                      placeholder={language.agentNumber}
                      onChange={(e) => {
                        setToAlias(e.target.value);
                      }}
                      allowClear
                    />
                  </div>
                </div>
                )}
            </div>
          </Card>

          <Row gutter={16}>
            <Col span={5}>
              <Card style={{ height: '100%' }} loading={loading}>
                <div style={{color:'#4a90e2'}}>
                  <span style={{fontWeight: 'bold'}}>{filterFiles.length}</span> {language.searchResults}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span style={{ fontWeight: '600', marginTop: 20 }}>{language.callDate}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                    {(moreTimestamp
                    ? timestampFacetList
                    : timestampFacetList.slice(0, 5)).map(item => (
                      <Checkbox
                        style={{ marginLeft: 0}}
                        checked={timestampChecked.includes(item)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setTimestampChecked(prev => [...prev, item]);
                          } else {
                            setTimestampChecked(prev => prev.filter(i => i !== item));
                          }
                        }}
                      >
                        {item} <b>{`(${filterFiles.filter(file => moment.unix(file?.timestamp_fm).format('DD-MM-YYYY') === item).length})`}</b>
                      </Checkbox>
                    ))}
                    {timestampFacetList.length > 5 && <a
                      style={{marginLeft: 25, marginTop: 5}}
                      onClick={() => {
                        setMoreTimestamp(prev => !prev);
                      }}
                    >
                      {moreTimestamp ? 
                      <>
                      <MinusCircleOutlined /> {language.showLess}
                      </>
                      :
                      <>
                      <PlusCircleOutlined /> {language.showMore}
                      </>
                      }
                    </a>}
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span style={{ fontWeight: '600', marginTop: 20 }}>{language.status}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                      {statusList.map(item => {
                        const itemLength = filterFiles.filter(file => file?.status === item[0]).length;
                        if (itemLength > 0) {
                          return (
                            <Checkbox
                              style={{ marginLeft: 0}}
                              checked={statusListChecked.includes(item[0])}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setStatusListChecked(prev => [...prev, item[0]]);
                                } else {
                                  setStatusListChecked(prev => prev.filter(i => i !== item[0]));
                                }
                              }}
                            >
                              {item[1]} <b>{`(${itemLength})`}</b>
                            </Checkbox>
                          )
                        }
                      })}
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span style={{ fontWeight: '600', marginTop: 20 }}>{language.pickUp} / {language.dontPickUp}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                    <Checkbox
                      style={{ marginLeft: 0}}
                      checked={durationChecked.includes('0')}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDurationChecked(prev => [...prev, '0']);
                        } else {
                          setDurationChecked(prev => prev.filter(i => i !== '0'));
                        }
                      }}
                    >
                      {language.dontPickUp} <b>{`(${filterFiles.filter(file => file?.answer_duration == 0).length})`}</b>
                    </Checkbox>
                    <Checkbox
                      style={{ marginLeft: 0}}
                      checked={durationChecked.includes('> 0')}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDurationChecked(prev => [...prev, '> 0']);
                        } else {
                          setDurationChecked(prev => prev.filter(i => i !== '> 0'));
                        }
                      }}
                    >
                      {language.pickUp} <b>{`(${filterFiles.filter(file => file?.answer_duration > 0).length})`}</b>
                    </Checkbox>
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span style={{ fontWeight: '600', marginTop: 20 }}>{language.internal} / {language.external}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                    <Checkbox
                      style={{ marginLeft: 0}}
                      checked={fromMethodChecked.includes('internal')}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFromMethodChecked(prev => [...prev, 'internal']);
                        } else {
                          setFromMethodChecked(prev => prev.filter(i => i !== 'internal'));
                        }
                      }}
                    >
                      {language.internal} <b>{`(${filterFiles.filter(file => file?.from_method === 'internal').length})`}</b>
                    </Checkbox>
                    <Checkbox
                      style={{ marginLeft: 0}}
                      checked={fromMethodChecked.includes('external')}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFromMethodChecked(prev => [...prev, 'external']);
                        } else {
                          setFromMethodChecked(prev => prev.filter(i => i !== 'external'));
                        }
                      }}
                    >
                      {language.external} <b>{`(${filterFiles.filter(file => file?.from_method === 'external').length})`}</b>
                    </Checkbox>
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span style={{ fontWeight: '600', marginTop: 20 }}>{language.caller}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                    {(moreFromName 
                    ? fromNameFacetList
                    : fromNameFacetList.slice(0, 5)).map(item => (
                      <Checkbox
                        style={{ marginLeft: 0}}
                        checked={fromNameListChecked.includes(item)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFromNameListChecked(prev => [...prev, item]);
                          } else {
                            setFromNameListChecked(prev => prev.filter(i => i !== item));
                          }
                        }}
                      >
                        {item} <b>{`(${filterFiles.filter(file => file?.from_name === item).length})`}</b>
                      </Checkbox>
                    ))}
                  </div>
                  {fromNameFacetList.length > 5 && <a
                      style={{marginLeft: 25, marginTop: 5}}
                      onClick={() => {
                        setMoreFromName(prev => !prev);
                      }}
                    >
                      {moreFromName ? 
                      <>
                      <MinusCircleOutlined /> {language.showLess}
                      </>
                      :
                      <>
                      <PlusCircleOutlined /> {language.showMore}
                      </>
                      }
                    </a>}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span style={{ fontWeight: '600', marginTop: 20 }}>{language.callee}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                    {(moreToName
                      ? toNameFacetList
                      : toNameFacetList.slice(0, 5)).map(item => (
                      <Checkbox
                        style={{ marginLeft: 0}}
                        checked={toNameListChecked.includes(item)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setToNameListChecked(prev => [...prev, item]);
                          } else {
                            setToNameListChecked(prev => prev.filter(i => i !== item));
                          }
                        }}
                      >
                        {item} <b>{`(${filterFiles.filter(file => file?.to_name === item).length})`}</b>
                      </Checkbox>
                    ))}
                  </div>
                  {toNameFacetList.length > 5 && <a
                      style={{marginLeft: 25, marginTop: 5}}
                      onClick={() => {
                        setMoreToName(prev => !prev);
                      }}
                    >
                      {moreToName ? 
                      <>
                      <MinusCircleOutlined /> {language.showLess}
                      </>
                      :
                      <>
                      <PlusCircleOutlined /> {language.showMore}
                      </>
                      }
                    </a>}
                </div>
                
              </Card>
            </Col>
            <Col span={19}>
              <Card>
                <p><b>{facetFiles.length}</b> {language.filterResults} / <span style={{ color: '#4a90e2' }}><b>{filterFiles.length}</b> {language.searchResults}</span></p>
                <Table
                  loading={loading}
                  bordered
                  columns={columns}
                  dataSource={facetFiles}
                  pagination={{
                    defaultPageSize: 20,
                    showSizeChanger: true,
                  }}
                />
              </Card>
            </Col>
          </Row>
      </Layout>
    </Layout>
  )
};

export default Users;