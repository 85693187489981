import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message, Menu, Dropdown } from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./styles.less";
import bgImg from "assets/background.svg";
import { auth, db } from "utils/firebase";

import { useNavigate } from "react-router-dom";

import { useRecoilState, useSetRecoilState } from "recoil";
import { adminAtom, localeAtom, userAtom } from "recoils/atoms";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import logo from "assets/Color2.png";
import nttLogo from "assets/nttdata_logo.svg";
import funixLogo from "assets/funix-logo.png";
import iconFlagEn from "assets/icons/united-states-flag.png";
import iconFlagVi from "assets/icons/vietnam-flag.png";
import iconFlagJa from "assets/icons/japan-flag.png";
import {
  ADMIN_TOKEN,
  IS_FUNIX,
  IS_NTTDATA,
  IS_NTTDATA_ON_PREMISE,
} from "utils/constants";
import { login } from "apis/auth";
import useAuth from "hooks/useAuth";

const { REACT_APP_TENANT_ID, REACT_APP_DEFAULT_LANG } = process.env;

const Login = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [locale, setLocale] = useRecoilState(localeAtom);
  const setUser = useSetRecoilState(adminAtom);
  //const {user} = useAuth();

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (user) {
  //      navigate('/admin/users');
  //   }
  // }, [user, navigate]);

  const onFinish = (values) => {
    setLoading(true);
    if (IS_NTTDATA_ON_PREMISE) {
      //login api
      let payload = {
        email: values.email,
        password: values.password,
      };

      login(payload)
        .then(({ data: { user, token } }) => {
          if (user.role !== "admin" || !user.isActive) {
            throw new Error();
          }
          //set token
          localStorage.setItem(ADMIN_TOKEN, token);
          //setUser (recoil)
          const { id, email, role, isActive, ...userData } = user;
          setUser({
            uid: id,
            email,
            role,
            isActive,
            profile: userData,
          });
          //redirect
          navigate(`/admin/users`);
        })
        .catch((e) => {
          console.log(e);
          message.error(lang.wrongEmailPassword);
          setLoading(false);
        });
    } else {
      auth
        .signInWithEmailAndPassword(values.email, values.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          await db
            .doc(`tenants/${user.uid}`)
            .get()
            .then((doc) => {
              if (!doc.exists || doc.data()?.tenantId !== REACT_APP_TENANT_ID) {
                message.error("Admin is not found");
                setLoading(false);
              } else {
                setLoading(false);
                navigate(`/admin/users`);
              }
            })
            .catch((err) => {
              message.error(err.code);
              setLoading(false);
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          console.log(error);

          if (
            errorCode === "auth/wrong-password" ||
            errorCode === "auth/user-not-found"
          ) {
            message.error(lang.wrongEmailPassword);
          } else if (errorCode === "auth/invalid-email") {
            message.error(lang.wrongEmailFormat);
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (locale === "null") {
      setLocale(REACT_APP_DEFAULT_LANG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (locale) => {
    setLocale(locale);
  };

  const languages = (
    <Menu>
      {IS_NTTDATA && (
        <Menu.Item onClick={() => changeLanguage("ja")}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <img src={iconFlagJa} width={24} alt="Japanese Language" />
            </div>
            <div style={{ marginLeft: 8, marginTop: 2 }}>日本語</div>
          </div>
        </Menu.Item>
      )}
      <Menu.Item onClick={() => changeLanguage("en")}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <img src={iconFlagEn} width={24} alt="English Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>English</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => changeLanguage("vi")}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <img src={iconFlagVi} width={24} alt="Vietnam Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>Tiếng Việt</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const languageSelected = (locale) => {
    switch (locale) {
      case "vi":
        return <img src={iconFlagVi} width={30} alt="Vietnamese Language" />;
      case "en":
        return <img src={iconFlagEn} width={30} alt="English Language" />;
      case "ja":
        return <img src={iconFlagJa} width={30} alt="Japanese Language" />;
      default:
        return IS_NTTDATA ? (
          <img src={iconFlagJa} width={30} alt="Japanese Language" />
        ) : (
          <img src={iconFlagVi} width={30} alt="Vietnamese Language" />
        );
    }
  };

  return (
    <div className="login-page">
      <Row justify="space-between" style={{ height: window.innerHeight }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          style={{ alignSelf: "center" }}
          className="paddingCol"
        >
          <div className="login-logo">
            {IS_NTTDATA ? (
              <img
                src={nttLogo}
                alt="logo"
                style={{ height: 50, marginTop: "50px" }}
              />
            ) : IS_FUNIX ? (
              <img
                src={funixLogo}
                alt="logo"
                style={{ height: 80, marginTop: "50px" }}
              />
            ) : (
              <img src={logo} alt="logo" style={{ height: 80 }} />
            )}
            <h3
              className="login-title"
              style={{ marginTop: 40, marginBottom: 40 }}
            >
              <strong>{lang.admin}</strong> {lang.login}
            </h3>
          </div>
          <div>
            <Form
              name="loginForm"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                label={lang.email}
                name="email"
                rules={[{ required: true, message: lang.requiredEmail }]}
              >
                <Input
                  size={"large"}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={lang.emailRequirePlaceholder}
                />
              </Form.Item>
              <Form.Item
                label={lang.password}
                name="password"
                rules={[{ required: true, message: lang.requiredPassword }]}
              >
                <Input.Password
                  size={"large"}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={lang.requiredPassword}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOutlined style={{ color: "black" }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: "black" }} />
                    )
                  }
                />
              </Form.Item>
              <Form.Item>
                {loading === false ? (
                  <Button
                    size={"large"}
                    type="primary"
                    danger
                    htmlType="submit"
                    block
                    className="login-form-button"
                  >
                    {lang.login}
                  </Button>
                ) : (
                  <Button
                    size={"large"}
                    type="primary"
                    loading={loading}
                    block
                    className="login-form-button"
                  >
                    {lang.loading}
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
          <div style={{ position: "relative" }}>
            <Dropdown overlay={languages} placement="bottom">
              <div
                style={{
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  marginRight: 16,
                  width: 100,
                }}
              >
                {languageSelected(locale)}
                <DownOutlined style={{ fontSize: 10, marginLeft: 8 }} />
              </div>
            </Dropdown>
          </div>
          <div style={{ paddingTop: "25vh" }}>{lang.slogan}</div>
          <div style={{ paddingTop: "3vh", fontWeight: "bold" }}>
            {lang.companyRights}
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          style={{
            backgroundImage: `url(${bgImg})`,
            width: "100%",
            backgroundSize: "cover",
            overflow: "hidden",
          }}
        ></Col>
      </Row>
    </div>
  );
};

export default Login;
