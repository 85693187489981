import React, { useMemo, useRef } from 'react'
import { useEffect, useState } from 'react';

import { PlusOutlined, MoreOutlined, SaveOutlined, MinusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Form, Input, Layout, Menu, Popover, Radio, Row, Space, Spin, Tooltip, Typography, message } from 'antd';

import useAuth from 'hooks/useAuth';
import { db } from "utils/firebase";

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import { IS_FUNIX } from 'utils/constants';
import './styles.less';
import { v4 } from 'uuid';
import { DEFAULT_KEYWORD_BACKGROUND, DEFAULT_KEYWORD_TEXT, getKeywordStyles } from 'components/TranscriptEditorV2/util';

const { REACT_APP_TENANT_ID } = process.env;

export const ColorPicker = ({ readOnly, title, value, onChange, ...props }) => {
  const id = useMemo(() => v4(), []);

  return (
    <Tooltip title={title}>
      <label {...props} style={{ backgroundColor: value }} htmlFor={id} className='color-picker-button'>
        {!readOnly &&
          <input
            value={value}
            onChange={e => onChange(e.target.value)}
            id={id}
            style={{ visibility: "hidden", height: 0 }} type="color"
          />
        }
      </label>
    </Tooltip>
  )
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

const KeywordTag = ({ value, id, keywordCategory, remove, index }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [edit, setEdit] = useState(false);
  const editRef = useRef(null);

  useOnClickOutside(editRef, () => {
    if (edit) {
      setEdit(false);
    }
  })

  return (
    <Popover
      visible={edit}
      placement="rightTop"
      overlayClassName='keyword-edit-overlay'
      content={
        <div className='keyword-edit-form' ref={editRef}>
          <Typography.Title style={{ marginBottom: 16, fontSize: 14 }}>Edit keyword</Typography.Title>
          <Form.Item initialValue={false} style={{ marginBottom: 8, fontSize: 12 }} label="Search type" name={[index, "isRegex"]}>
            <Radio.Group>
              <Radio value={false}>Words</Radio>
              <Radio value={true}>Regex</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={[index, "text"]}>
            <Input />
          </Form.Item>
          <Typography.Title style={{ marginBottom: 16, fontSize: 12 }}>Color type:</Typography.Title>
          <Form.Item initialValue={0} name={[index, "customColor"]}>
            <Radio.Group>
              <Space direction='vertical'>
                <Radio value={0}>Category color</Radio>
                <Radio value={1}>
                  Custom color
                  <Form.Item noStyle initialValue={DEFAULT_KEYWORD_TEXT} name={[index, "textColor"]}>
                    <ColorPicker title="Text color" />
                  </Form.Item>
                  <Form.Item noStyle initialValue={DEFAULT_KEYWORD_BACKGROUND} name={[index, "backgroundColor"]}>
                    <ColorPicker title="Background color" />
                  </Form.Item>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      }>
      <div
        style={getKeywordStyles(value, keywordCategory)}
        className='keyword-tag'
      >
        {value.text.trim() || <em>Empty</em>}
        <Dropdown trigger="click" placement="rightTop"
          overlay={<Menu>
            <Menu.Item key={`${id}-edit-menu`} onClick={() => { setEdit(true) }} icon={<EditOutlined />}>{lang.btnEdit}</Menu.Item>
            <Menu.Item key={`${id}-delete-menu`} onClick={remove} icon={<DeleteOutlined />}>{lang.btnDelete}</Menu.Item>
          </Menu>}>
          <MoreOutlined />
        </Dropdown>
      </div>
    </Popover>
  )
}

const KeywordCategoryLabel = ({ value, remove, onChange, index }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Typography.Text editable={{ onChange }}>
          {value}
        </Typography.Text>
        <Form.Item noStyle initialValue={DEFAULT_KEYWORD_TEXT} name={[index, "textColor"]}>
          <ColorPicker title="Text color" />
        </Form.Item>
        <Form.Item noStyle initialValue={DEFAULT_KEYWORD_BACKGROUND} name={[index, "backgroundColor"]}>
          <ColorPicker title="Background color" />
        </Form.Item>
        <Button style={{ padding: "0 8px", display: "inline-block" }} onClick={remove} type="link" icon={<MinusCircleOutlined />}>
          {lang.btnDelete}
        </Button>
      </Col>
    </Row>
  )
}

const KeywordCategory = ({ index, value, remove, field }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  return (
    <Card style={{ marginBottom: 18 }}>
      <Form.Item name={[index, 'label']}>
        <KeywordCategoryLabel index={index} remove={() => remove(index)} />
      </Form.Item>
      <Row style={{ background: "white" }} gutter={12}>
        <Col>
          <Form.List name={[field.name, "words"]}>
            {(words, { add, remove }) =>
              <>
                {words.map((_, wordIndex) => (
                  <Space key={wordIndex}>
                    <Form.Item
                      name={wordIndex}>
                      <KeywordTag keywordCategory={value} index={wordIndex} remove={() => remove(wordIndex)} />
                    </Form.Item>
                  </Space>
                ))}

                <Button
                  type='link'
                  onClick={() => add({
                    text: "",
                    textColor: DEFAULT_KEYWORD_TEXT,
                    backgroundColor: DEFAULT_KEYWORD_BACKGROUND,
                  })}
                  icon={<PlusOutlined />}
                >
                  {lang.btnAdd}
                </Button>
              </>}
          </Form.List>
        </Col>
      </Row>
    </Card>
  )
}

const KeywordSpot = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { user } = useAuth();
  const [form] = Form.useForm();
  const [keywords, setKeywords] = useState([])
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    if (user && loading) {
      setLoading(true)
      let query = db.collection('keywords')

      if (IS_FUNIX) query = query.where('tenantId', '==', REACT_APP_TENANT_ID);
      else query = query.where('userId', '==', user?.uid);
      query
        .get()
        .then(async (data) => {
          if (data.empty) {
            setLoading(false);
            return;
          }

          let keywords = await Promise.all(data.docs.map((doc) => {
            let item = doc.data();
            return {
              id: doc.id,
              ...item,
            }
          }))

          setKeywords(prev => [...prev, ...keywords]);
          setLoading(false);
        })
    }
  }, [user]);

  const onSave = async (keywords, successMessage) => {
    setLoadingSave(true);

    try {
      //get
      let query = db.collection('keywords')

      if (IS_FUNIX) query = query.where('tenantId', '==', REACT_APP_TENANT_ID);
      else query = query.where('userId', '==', user?.uid);

      let keywordsDoc = await query.get();

      await Promise.all(keywordsDoc.docs.map((doc) => {
        return db.collection('keywords')
          .doc(doc.id)
          .delete()
      }));
      await Promise.all(keywords.map((item, index) => {
        return db.collection('keywords')
          .add({
            userId: user?.uid,
            tenantId: REACT_APP_TENANT_ID,
            ...item
          })
      }))

      setKeywords(keywords);
      form.setFieldsValue({ keywords });
      message.success(successMessage || lang.dictionarySuccess);
    } catch (e) {
      message.error(e.message ?? lang.saveFailed);
    } finally {
      setLoadingSave(false);
    }
  }

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20, marginBottom: 24 }} >
        {loading ?
          <Spin />
          :
          <Form
            form={form}
            layout="vertical"
            name="info"
            onFinish={async (values) => {
              onSave(values?.keywords);
            }}
            initialValues={{
              keywords: keywords,
            }}
          >
            <Card style={{ marginBottom: 18 }}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                <Col className="gutter-row" span={12}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h3>Keywords</h3>
                  </div>
                </Col>
                <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
                  <Button size='large' loading={loadingSave} htmlType="submit" type="primary" icon={<SaveOutlined />}>{lang.btnSave}</Button>
                </Col>
              </Row>
            </Card>
            {loading ?
              <Spin />
              :
              <Form.List name="keywords">
                {(fields, { add, remove }) => (
                  <>
                    <Row style={{ marginBottom: 10 }}>
                      <Col span={24}>
                        <span style={{ fontWeight: 'bold' }}>{lang.totalCategories ?? "Total categories"}: {fields.length}</span>
                      </Col>
                    </Row>
                    {fields.map((field, index) => (
                      <Form.Item name={[index]}>
                        <KeywordCategory key={index} field={field} remove={remove} index={index} />
                      </Form.Item>
                    ))}
                    <Button style={{ marginLeft: -18 }} type='link' onClick={() => add({
                      label: "Category",
                      words: []
                    })} icon={<PlusOutlined />}>
                      {lang.btnAdd}
                    </Button>
                  </>
                )}
              </Form.List>
            }
          </Form>
        }
      </Layout>
    </Layout>
  )
};

export default KeywordSpot;