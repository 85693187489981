import { useEffect, useState } from "react";

const useRecorder = (socket) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [audioURL, setAudioURL] = useState("");

  async function requestRecorder(socket) {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const csAudioContext = new AudioContext({
      sampleRate: 16000,
    });

    const source = csAudioContext.createMediaStreamSource(stream);
    const dest = csAudioContext.createMediaStreamDestination();
    const dest2 = csAudioContext.createMediaStreamDestination();

    const processor = csAudioContext.createScriptProcessor(512, 1, 1);
    processor.onaudioprocess = (e) => {
      if (socket.current && socket.current.readyState === 1) {
        const channel = e.inputBuffer.getChannelData(0);
        const buffer = new ArrayBuffer(channel.length * 2);
        const view = new DataView(buffer);
        for (let i = 0; i < channel.length; i++) {
          const sample = Math.max(-1, Math.min(1, channel[i]));
          view.setInt16(
            i * 2,
            sample < 0 ? sample * 0x8000 : sample * 0x7fff,
            true
          );
        }
        socket.current.send(view.buffer);
      }

      return e;
    };

    dest.channelCount = 1;
    dest.channelCountMode = "explicit";
    dest.channelInterpretation = "speakers";

    dest2.channelCount = 1;
    dest2.channelCountMode = "explicit";
    dest2.channelInterpretation = "speakers";

    csAudioContext.onstatechange = () => {
      console.log("csAudioContext state: ", csAudioContext.state);
      if (csAudioContext.state !== "running") {
        csAudioContext.resume().then(function () {
          console.log("csAudioContext resume");
        });
      }
    };

    source.connect(processor);
    processor.connect(dest);
    source.connect(dest2);

    return new MediaRecorder(dest2.stream);
  }

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder(socket).then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    const handleSave = (e) => {
      if (e.data.size > 0) {
        setAudioURL(URL.createObjectURL(e.data));
      }
    };

    recorder.addEventListener("dataavailable", handleSave);

    return () => {
      recorder.removeEventListener("dataavailable", handleSave);
    };
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  return [isRecording, startRecording, stopRecording, audioURL];
};

export default useRecorder;
