/* eslint-disable */
import request from "utils/request";
import requestAdmin from "utils/requestAdmin";
import { isAdminRoute } from "utils/utils";

export const login = async (payload) => {
  return request(`auth/login`, payload, { method: "POST" })
};

export const getMe = async () => {
  return request(`auth/me`, {}, { method: "GET" })
};

export const getMeAdmin = async () => {
  return requestAdmin(`auth/me`, {}, { method: "GET" })
};

export const changePassword = async (payload) => {
  if (isAdminRoute()) {
    return requestAdmin(`auth/change-password`, payload, { method: "POST" });
  }
  return request(`auth/change-password`, payload, { method: "POST" })
};

export const forgotPassword = async (payload) => {
  return request(`auth/forgot-password`, payload, { method: "POST" })
};

export const resetPassword = async (token,payload) => {
  return request(`auth/reset/${token}`, payload, { method: "POST" })
};