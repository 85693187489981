import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
} from "@elastic/eui";
import log from "loglevel";
import React, { useEffect, useState } from "react";

export default function Audio({
  audioName,
  playerID,
  audioUrl,
  audioDuration,
}) {
  const [currentDuration, setCurrentDuration] = useState(0);
  const [duration, setDuration] = useState(0);
  const [play, setPlay] = useState(false);
  const [playIcon, setPlayIcon] = useState("playFilled");

  useEffect(() => {
    const audioTag = document.getElementById(playerID);
    audioTag.onloadeddata = () => {
      log.debug("[Audio] duration " + audioTag.duration);
      if (audioTag.duration === Infinity) {
        setDuration(audioDuration);
      }
    };
    audioTag.ontimeupdate = () => {
      if (audioTag.duration !== Infinity) {
        setDuration(audioTag.duration);
      }
      setCurrentDuration(audioTag.currentTime);
    };
    audioTag.onended = () => {
      setPlay(!play);
    };

    if (play) {
      setPlayIcon("pause");
      audioTag.play();
    } else {
      setPlayIcon("playFilled");
      audioTag.pause();
    }
  }, [play, audioUrl, playerID, audioDuration]);

  const playAudio = () => {
    const audioTag = document.getElementById(playerID);
    audioTag.play();
    setPlay(!play);
  };

  return (
    <>
      <EuiFlexGroup direction="column" responsive={false}>
        <EuiFlexItem
          style={{ maxHeight: "0", margin: "0", visibility: "hidden" }}
        >
          <audio src={audioUrl} controls id={playerID} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup alignItems="center" responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                display="base"
                iconType={playIcon}
                aria-label="Delete"
                color="primary"
                onClick={playAudio}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiProgress
                label={
                  audioName.length > 20
                    ? `${audioName.substring(0, 20)}...`
                    : audioName
                }
                value={currentDuration}
                max={duration}
                size="xs"
                color="primary"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}
