import React, { useState, useEffect } from "react";

import {
  Layout,
  Button,
  Table,
  Row,
  Col,
  Card,
  Modal,
  Tag,
  message,
} from "antd";
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import UserUpdateForm from "components/web/Admin/User/UserUpdateForm";
import UserCreateForm from "components/web/Admin/User/UserCreateForm";

import _ from "lodash";
import moment from "moment";

import { auth, db } from "utils/firebase";
import { deleteUser, userList } from "apis/user";

import "./styles.less";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";
import { IS_NTTDATA_ON_PREMISE } from "utils/constants";
import Axios from "axios";

const { confirm } = Modal;

const { REACT_APP_TENANT_ID } = process.env;

const Users = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [loading, setLoading] = useState(false);

  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState();

  useEffect(() => {
    setLoading(true);
    if (IS_NTTDATA_ON_PREMISE) {
      userList().then(({ data }) => {
        setUsers(data.users);
      });
    } else {
      db.collection("users")
        .where("tenantId", "==", REACT_APP_TENANT_ID)
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          setUsers(
            snapshot.docs.map((doc) => {
              return {
                id: doc.id,
                ...doc.data(),
                createdAt: doc.data()?.createdAt.toDate(),
              };
            })
          );
        });
    }
    setLoading(false);
  }, []);

  const columns = [
    {
      title: lang.createdAt,
      align: "center",
      dataIndex: "createdAt",
      width: "10%",
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format("YYYY-MM-DD HH:mm")}</div>
          </div>
        );
      },
    },
    {
      title: lang.name,
      dataIndex: "name",
      width: "20%",
    },
    {
      title: lang.email,
      dataIndex: "email",
      width: "20%",
    },
    {
      title: lang.phone,
      dataIndex: "phone",
      width: "10%",
    },
    {
      title: lang.active,
      dataIndex: "isActive",
      width: "10%",
      render: (record) => {
        if (record) {
          return <Tag>{lang.active}</Tag>;
        } else {
          return <Tag color={"red"}>{lang.inactive}</Tag>;
        }
      },
      filterMultiple: true,
      filters: [
        {
          text: lang.active,
          value: true,
        },
        {
          text: lang.inactive,
          value: false,
        },
      ],
      onFilter: (value, record) => record.isActive === value,
    },
    {
      title: "",
      align: "center",
      width: "5%",
      render: (record) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClickCapture={() => {
                setUser(record);
                setIsUpdateModal(true);
              }}
            >
              {lang.btnEdit}
            </Button>
            <Button
              icon={<DeleteOutlined />}
              type="link"
              danger
              onClick={() => {
                confirm({
                  title: lang.doYouWantToDeleteTheseItems,
                  icon: <ExclamationCircleOutlined />,
                  destroyOnClose: true,
                  onOk: async () => {
                    const apiUrl = process.env.REACT_APP_API + "/admin/users";
                    // "http://127.0.0.1:5001/namisense-prod/us-central1/api/admin/users";
                    await Axios.delete(apiUrl + "/" + record.id, {
                      headers: {
                        Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
                        tenantId: process.env.REACT_APP_TENANT_ID,
                      },
                    });
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              }}
            >
              {lang.btnDelete}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }}>
        <Card style={{ marginBottom: 18 }}>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            justify="space-between"
          >
            <Col className="gutter-row" span={12}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h2>{lang.user}</h2>
              </div>
            </Col>
            <Col
              className="gutter-row"
              span={12}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                onClick={() => setIsCreateModal(true)}
                icon={<PlusOutlined />}
              >
                {lang.btnAdd}
              </Button>
            </Col>
          </Row>
        </Card>
        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={users}
          pagination={{
            defaultPageSize: 20,
          }}
        />
        <Modal
          visible={isCreateModal}
          title="User create"
          onCancel={() => {
            Modal.confirm({
              title: lang.doYouWantToCancel,
              icon: null,
              onOk() {
                setIsCreateModal(false);
              },
              okText: "Yes",
              onCancel() {},
              cancelText: "No",
            });
          }}
          destroyOnClose={true}
          width={570}
          centered
          footer={null}
        >
          <UserCreateForm
            setUsers={setUsers}
            onCancel={() => setIsCreateModal(false)}
            onCallback={() => setIsCreateModal(false)}
          />
        </Modal>

        <Modal
          visible={isUpdateModal}
          title="User Update"
          onCancel={() => {
            setIsUpdateModal(false);
          }}
          destroyOnClose={true}
          width={570}
          centered
          footer={null}
        >
          <UserUpdateForm
            user={user}
            setUsers={setUsers}
            onCancel={() => setIsUpdateModal(false)}
            onCallback={() => setIsUpdateModal(false)}
          />
        </Modal>
      </Layout>
    </Layout>
  );
};

export default Users;
