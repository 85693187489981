import axios from 'axios'

const responseBody = (response) => response.data

const requests = {
  get: (url, config) => axios.get(url, config).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  del: (url) => axios.delete(url).then(responseBody),
}

export const Tasks = {
  get: (taskId) => requests.get(`${process.env.REACT_APP_AI_URL}/tasks/${taskId}`),
  create: (body) => requests.post(`${process.env.REACT_APP_AI_URL}/task`, body),
  update: (taskId, body) => requests.put(`${process.env.REACT_APP_AI_URL}/tasks/${taskId}`, body),
  getStatus: (taskId) => requests.get(`${process.env.REACT_APP_AI_URL}/task/status/${taskId}`),
  getResult: (taskId) => requests.get(`${process.env.REACT_APP_AI_URL}/task/result/${taskId}`),
}