import * as firebase from "firebase";

// let app, auth, db, storage;
// export default app;
// export { auth, db, storage };

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOAMIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID
};

// if (!IS_NTTDATA_ON_PREMISE) {
//     app = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
//     auth = firebase.auth();
//     db = firebase.firestore();
//     storage = firebase.storage();
// }

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();