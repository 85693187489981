import React, { useEffect, Fragment } from "react";
import { Modal, Table, Tag } from "antd";
import keyboardData from "config/keyboard";

const TrackKeyBoard = ({
  keyboardVisible,
  setKeyboardVisible,
  setFindReplaceVisible,
}) => {
  const handleKeyDown = (event) => {
    // ['CTRL', 'Z'] undo
    if (event.ctrlKey && event.keyCode === 90) {
      console.info("undo");
    }
    //['CTRL', 'Y'] redo
    if (event.ctrlKey && event.keyCode === 89) {
      console.info("redo");
    }
    //['CTRL', 'SHIFT', 'F'] SEARCH
    if (event.ctrlKey && event.shiftKey && event.keyCode === 70) {
      event.preventDefault();
      setFindReplaceVisible((e) => !e);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <Modal
      title="Keyboard Shortcuts"
      visible={keyboardVisible}
      onCancel={() => setKeyboardVisible(false)}
      footer={null}
    >
      <Table dataSource={keyboardData} pagination={false}>
        <Table.Column title="Name" dataIndex="name" key="name"></Table.Column>
        <Table.Column
          title="Tags"
          dataIndex="tags"
          key="tags"
          render={(text) =>
            text.map((e, i) => (
              <Fragment key={`tag-${i}`}>
                <Tag>{e}</Tag>
                {i < text.length - 1 && "+ "}
              </Fragment>
            ))
          }
        ></Table.Column>
      </Table>
    </Modal>
  );
};
export default TrackKeyBoard;
