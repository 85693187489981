import { Button, Form, InputNumber, Popover, Row, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Editor, Range } from "slate";
import { useFocused, useSlate } from "slate-react";
import { PlaySquareOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  changeTime,
  getWordAtOffset,
  getWordOnly,
  getWordsInRange,
} from "./util";
import _ from "lodash";

const Portal = ({ children }) => {
  return typeof document === "object"
    ? createPortal(children, document.body)
    : null;
};

const ListWords = React.memo(() => {
  const [listWords, setListWords] = useState(null);
  const editor = useSlate();

  useEffect(() => {
    setListWords(null);
  }, [editor.selection]);

  const getListWord = () => {
    const selection = editor.selection;
    if (selection) {
      const [start, end] = Range.edges(selection);
      if (start.path[0] === end.path[0]) {
        const sentence = editor.children[start.path[0]].sentence;
        const listWords = getWordsInRange(
          sentence.words,
          [start.offset, end.offset],
          { skipEnd: true }
        ).map((word) => _.merge({}, word, { sentenceIndex: start.path[0] }));
        setListWords(getWordOnly(listWords));
      } else {
        const listWords = [];
        for (let i = start.path[0]; i <= end.path[0]; i++) {
          const sentence = editor.children[i].sentence;
          if (i === start.path[0]) {
            listWords.push(
              ...getWordsInRange(sentence.words, [start.offset, -1]).map(
                (word) => _.merge({}, word, { sentenceIndex: i })
              )
            );
          } else if (i === end.path[0]) {
            listWords.push(
              ...getWordsInRange(sentence.words, [0, end.offset]).map((word) =>
                _.merge({}, word, { sentenceIndex: i })
              )
            );
          } else {
            listWords.push(
              ...sentence.words.map((word) =>
                _.merge({}, word, { sentenceIndex: i })
              )
            );
          }
        }
        setListWords(getWordOnly(listWords));
      }
    }
  };
  return (
    <Popover
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      visible={Boolean(listWords)}
      placement="rightTop"
      content={
        listWords ? (
          <div style={{ minWidth: 300 }} onClick={(e) => e.stopPropagation()}>
            <div>
              <Space
                direction="vertical"
                style={{ maxHeight: 300, width: "100%", overflowY: "auto" }}
              >
                <Row
                  style={{ fontWeight: "bold" }}
                  justify="space-between"
                  align="middle"
                >
                  <div style={{ width: "20%" }}>Word</div>
                  <div style={{ width: "38%" }}>Start</div>
                  <div style={{ width: "38%" }}>End</div>
                </Row>
                {listWords?.map((word, i) => (
                  <Form
                    initialValues={{
                      start: word.start,
                      end: word.end,
                    }}
                    onValuesChange={(values) => {
                      editor.apply({
                        type: "update_word_timestamp",
                        sentenceIndex: word.sentenceIndex,
                        wordIndex: word.wordIndex,
                        start: values.start,
                        end: values.end,
                      });
                    }}
                    key={i}
                  >
                    <Row justify="space-between" align="middle">
                      <div
                        style={{
                          width: "20%",
                          flexShrink: 0,
                          minWidth: 0,
                          textOverflow: "ellipsis",
                          overflowX: "hidden",
                        }}
                      >
                        {word.word || <em>Empty</em>}
                      </div>
                      <Form.Item
                        style={{ width: "38%", marginBottom: 0 }}
                        name="start"
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "38%", marginBottom: 0 }}
                        name="end"
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                    </Row>
                  </Form>
                ))}
              </Space>
            </div>
          </div>
        ) : null
      }
    >
      <Button
        id="popup-change-speaker"
        type="default"
        onClick={() => (listWords ? setListWords(null) : getListWord())}
        icon={<UnorderedListOutlined />}
      />
    </Popover>
  );
});

const TranscriptToolbar = ({ vidRef }) => {
  const ref = useRef();
  const editor = useSlate();
  const inFocus = useFocused();
  const [word, setWord] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      setShow(false);
      el.removeAttribute("style");
      return;
    }

    const start = Range.start(selection);
    const wordAtIndex = getWordAtOffset(
      editor.children[start.path[0]].sentence.words,
      start.offset,
      { wordOnly: true, skipEnd: true }
    )?.word;

    if (wordAtIndex) {
      setWord(wordAtIndex);
      // document.getElementById("popup-play--text").innerText = " " + wordAtIndex.start;
    }

    try {
      const domSelection = window.getSelection();
      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      el.style.opacity = "1";
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight - 8}px`;
      el.style.left = `${
        rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
      }px`;
    } catch {}
    setShow(true);
  }, [editor.selection, inFocus]);

  return (
    <Portal>
      <div ref={ref} className="transcriptEditor-toolbar">
        {show && (
          <>
            <Button
              id="popup-play"
              style={{ paddingLeft: 8, paddingRight: 8 }}
              type="default"
              onClick={() => {
                if (word) {
                  changeTime(vidRef.current, word.start - 0.5);
                }
              }}
              icon={<PlaySquareOutlined style={{ paddingRight: 8 }} />}
            >
              {word?.start}
            </Button>
            <ListWords />
          </>
        )}
      </div>
    </Portal>
  );
};

export default React.memo(TranscriptToolbar);
