import React, { useState, useEffect } from 'react';

import { Layout, Card, Row, Col, Button, Radio, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import useAuth from 'hooks/useAuth';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import moment from 'moment';

import {db} from "utils/firebase";

import { Column } from '@ant-design/charts';

import './styles.less';
import { IS_FUNIX } from 'utils/constants';

import _extends from "@babel/runtime/helpers/extends";
import CalendarLocale from "rc-picker/es/locale/vi_VN";
import TimePickerLocale from 'antd/es/time-picker/locale/vi_VN';

import usLocale from 'antd/lib/date-picker/locale/en_US';

const { REACT_APP_TENANT_ID } = process.env;

const { RangePicker } = DatePicker;

const Users = () => {
  const { t, i18n } = useTranslation();
  const language = getLanguages(t);

  const {user, token} = useAuth();

  const [files, setFiles] = useState([]);
  const [filesFilter, setFilesFilter] = useState([]);

  const [fromDate, setFromDate] = useState(IS_FUNIX ? moment().subtract(7, 'days') : moment().subtract(1, 'months'));
  const [toDate, setToDate] = useState(moment());

  const [totalMins, setTotalMins] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("Daily");

  const [loading, setLoading] = useState(false);

  const type = process.env.REACT_APP_UNIT;
  const price = process.env.REACT_APP_PRICE;
  const currency = process.env.REACT_APP_CURRENCY;

  const locale = {
    lang: _extends({
      placeholder: 'Chọn thời điểm',
      rangePlaceholder: ['Từ ngày', 'Đến ngày']
    }, CalendarLocale),
    timePickerLocale: _extends({}, TimePickerLocale)
  };

  function onChange(dates, dateStrings) {
    if (dates && dates[0]) {
      setFromDate(dates[0])
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    }
  }

  useEffect(() => {
    if (user) {
      setLoading(true);
      let totalMins = 0;

      let query = db.collection('files');

      if (IS_FUNIX) query = query.where('tenantId', '==', REACT_APP_TENANT_ID).where('timestamp_fm', '>=', fromDate.toDate())
      .where('timestamp_fm', '<=', toDate.toDate())
      else query = query.where('user', '==', user.uid).where('createdAt', '>=', fromDate.toDate()).where('createdAt', '<=', toDate.toDate())

      query
      .where('status', '==', 'TRANSCRIBED')
      .get()
      .then(snapshot => {
        const data = snapshot.docs.map(doc => {
          if(isFinite(doc.data().length)) totalMins += doc.data().length
          return {
            id: doc.id,
            ...doc.data(),
            createdAt: IS_FUNIX ? doc.data()?.timestamp_fm?.toDate() : doc.data()?.createdAt?.toDate(),
          }
        })
        setFiles(data);
        setFilesFilter(data);

        totalMins = type === 'HOUR' ? (totalMins / 3600).toFixed(0) : (totalMins / 60).toFixed(0)
        setTotalMins(totalMins);
        setTotalCost(price * totalMins);
        setLoading(false);
      })

      db.doc(`users/${user.uid}`)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
      })
    }
  }, [user]);

  useEffect(() => {
    if (filesFilter) {
      setLoading(true);
      let days = {};
      const type = chartType === "Monthly" ? 'months' : 'days';
      const dateFormat = type === 'months' ? "YYYY-MM" : "YYYY-MM-DD";

      let date = moment(toDate)
      while (moment(fromDate).isBefore(moment(date), type)) {
        days[date.format(dateFormat)] = 0;
        date = date.subtract(1, type)
      }

      days[date.format(dateFormat)] = 0;
  
      filesFilter.forEach(usage => {
        const date = moment(usage.createdAt).format(dateFormat)
        if (date in days && isFinite(usage.length)) {
          days[date] += (usage.length / 60);
        }
      })
  
      setChartData(Object.keys(days).reverse().map(item => {
        return {
          date: item,
          minutes: Math.round(days[item])
        }
      }))
      setLoading(false);
    }
  }, [filesFilter, chartType])

  const convertToDuration = (value) => {
    if (value) {
      if (type === 'HOUR') {
        return `${totalMins} ${language.hours}`;
      }
      else {
        return `${totalMins} ${language.minutes}`;
      }
    }
    else {
      return ``
    }
  };

  const config = {
    loading: loading,
    data: chartData,
    xField: 'date',
    yField: 'minutes',
    color: '#4A90E2',
    seriesField: 'category',
    appendPadding: 20,
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    label: {
      offsetY: 5,
      position: 'top',
      style: {
        fill: '#000000',
        opacity: 0.9,
      },
    },
  };

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
      <Card style={{marginBottom: 18}}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={12}>
                <h3>{language.statistics}</h3>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <RangePicker
                  ranges={{
                    [language.today]: [moment(), moment()],
                    [language.yesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    [language.thisWeek]: [moment().startOf('week'), moment().endOf('week')],
                    [language.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
                    [language.thisQuarter]: [moment().startOf('quarter'), moment().endOf('quarter')],
                    [language.thisYear]: [moment().startOf('year'), moment().endOf('year')],
                  }}
                  allowClear={false}
                  onChange={onChange}
                  defaultValue={[fromDate, toDate]}
                  locale={i18n.language === 'en' ? usLocale : locale}
                />
                <Button 
                  type="primary" 
                  style={{marginLeft: 24}} 
                  icon={<SearchOutlined />} 
                  disabled={!fromDate || !toDate}
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    let totalMins = 0;

                    let query = db.collection('files');

                    if (IS_FUNIX) query =  query.where('tenantId', '==', REACT_APP_TENANT_ID)
                                                .where('timestamp_fm', '>=', fromDate.startOf('day').toDate())
                                                .where('timestamp_fm', '<=', toDate.endOf('day').toDate())
                    else query = query.where('user', '==', user.uid)
                                      .where('createdAt', '>=', fromDate.startOf('day').toDate())
                                      .where('createdAt', '<=', toDate.endOf('day').toDate())

                    query
                    .where('status', '==', 'TRANSCRIBED')
                    .get()
                    .then(snapshot => {
                      console.log('snapshot.docs: ', snapshot.docs.length)
                      const data = snapshot.docs.map(doc => {
                        if(isFinite(doc.data().length)) totalMins += doc.data().length
                        return {
                          id: doc.id,
                          ...doc.data(),
                          createdAt: IS_FUNIX ? doc.data()?.timestamp_fm?.toDate() : doc.data()?.createdAt?.toDate(),
                        }
                      })
                      setFiles(data);
                      setFilesFilter(data);

                      totalMins = type === 'HOUR' ? (totalMins / 3600).toFixed(0) : (totalMins / 60).toFixed(0)
                      setTotalMins(totalMins);
                      setTotalCost(price * totalMins);
                      setLoading(false);
                    })
                    .catch(err => {
                      console.log(err)
                    })
                  }}
                >{language.search}</Button>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{marginTop: 24, marginBottom: 24}}>
              <Col span={24}>
                <Card style={{ display: 'block' }}>
                  <p>{`${language.total}: `}<strong>{`${convertToDuration(totalMins)}`}</strong></p>
                  {/* <p>{`${language.totalCost}: `}<strong>{`${Intl.NumberFormat('en-US').format(totalCost || 0)} ${currency}`}</strong></p> */}
                  <div style={{ marginBottom: '20px' }}>
                    <Radio.Group value={chartType} onChange={(e) => setChartType(e.target.value)}>
                      <Radio.Button style={{ marginRight: '8px' }} value="Daily">{language.daily}</Radio.Button>
                      <Radio.Button style={{ marginRight: '16px' }} value="Monthly">{language.monthly}</Radio.Button>
                    </Radio.Group>
                  </div>
                  <Column {...config} />
                </Card>
              </Col>
            </Row>
        </Card>
      </Layout>
    </Layout>
  )
};

export default Users;