import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'i18n/en';
import vi from 'i18n/vi';
import ja from 'i18n/ja';
import moment from 'moment-timezone';

const { REACT_APP_DEFAULT_LANG } = process.env;

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  vi: {
    translation: vi,
  },
  en: {
    translation: en,
  },
  ja: {
    translation: ja
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: REACT_APP_DEFAULT_LANG,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

moment.locale(i18n.language);

export default i18n;
