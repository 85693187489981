export const getLanguages = (t) => {
  return {
    login: t("login"),
    logout: t("logout"),
    password: t("password"),
    forgotPassword: t("forgotPassword"),
    changePassword: t("changePassword"),
    changePasswordSuccessTitle: t("changePasswordSuccessTitle"),
    changePasswordSuccessContent: t("changePasswordSuccessContent"),
    currentPassword: t("currentPassword"),
    pleaseInputCurrentPassword: t("pleaseInputCurrentPassword"),
    newPassword: t("newPassword"),
    pleaseInputNewPassword: t("pleaseInputNewPassword"),
    passwordErrorMinimum: t("passwordErrorMinimum"),
    passwordErrorUppercase: t("passwordErrorUppercase"),
    passwordErrorLowercase: t("passwordErrorLowercase"),
    passwordErrorNumber: t("passwordErrorNumber"),
    slogan: t("slogan"),
    companyRights: t("companyRights"),
    dictionary: t("dictionary"),
    dictionarySuccess: t("dictionarySuccess"),
    word1: t("word1"),
    word2: t("word2"),
    word3: t("word3"),
    word4: t("word4"),
    word5: t("word5"),
    transcripts: t("transcripts"),
    record: t("record"),
    transcript: t("transcript"),
    correctedTranscript: t("correctedTranscript"),
    createdAt: t("createdAt"),
    audio: t("audio"),
    secLimit: t("secLimit"),
    seconds: t("seconds"),
    predicting: t("predicting"),
    selectDragDropAudio: t("selectDragDropAudio"),
    selectDragDropVideo: t("selectDragDropVideo"),
    bugSuccess: t("bugSuccess"),
    selectLanguage: t("selectLanguage"),
    japanese: t("japanese"),
    vietnamese: t("vietnamese"),
    haveBugs: t("haveBugs"),
    noMessage: t("noMessage"),
    placeholderPassword: t("placeholderPassword"),
    loading: t("loading"),
    user: t("user"),
    admin: t("admin"),
    doYouWantToDeleteTheseItems: t("doYouWantToDeleteTheseItems"),
    doYouWantToCancel: t("doYouWantToCancel"),
    btnSubmit: t("btnSubmit"),
    btnStop: t("btnStop"),
    requiredPassword: t("requiredPassword"),
    btnCancel: t("btnCancel"),
    btnUpdate: t("btnUpdate"),
    btnAdd: t("btnAdd"),
    btnEdit: t("btnEdit"),
    btnDelete: t("btnDelete"),
    btnSave: t("btnSave"),
    btnRecord: t("btnRecord"),
    requiredEmail: t("requiredEmail"),
    email: t("email"),
    emailRequirePlaceholder: t("emailRequirePlaceholder"),
    recordAudio: t("recordAudio"),
    name: t("name"),
    phone: t("phone"),
    active: t("active"),
    inactive: t("inactive"),
    word1Required: t("word1Required"),
    word2Required: t("word2Required"),
    word3Required: t("word3Required"),
    word4Required: t("word4Required"),
    word5Required: t("word5Required"),
    connectionStatus: t('connectionStatus'),
    closed: t('closed'),
    connecting: t('connecting'),
    connected: t('connected'),
    live: t('live'),
    offline: t('offline'),
    btnStart: t('btnStart'),
    successSave: t('successSave'),
    btnDownload: t('btnDownload'),
    btnUpload: t('btnUpload'),
    line: t('line'),
    uploadFail: t('uploadFail'),
    uploadSuccess: t('uploadSuccess'),
    meetings: t('meetings'),
    btnView: t('btnView'),
    actions: t('actions'),
    meeting: t('meeting'),
    export: t('export'),
    exportAll: t('exportAll'),
    exportSelected: t('exportSelected'),
    revert: t('revert'),
    play: t('play'),
    pause: t('pause'),
    jumpTo: t('jumpTo'),
    jumpToTimestamp: t('jumpToTimestamp'),
    speed: t('speed'),
    volume: t('volume'),
    setVolume: t('setVolume'),
    notes: t('notes'),
    writeNotesHere: t('writeNotesHere'),
    participants: t('participants'),
    length: t('length'),
    date: t('date'),
    ID: t('ID'),
    lastEdit: t('lastEdit'),
    sortBy: t("sortBy"),
    sortByRequired: t("sortByRequired"),
    inProgress: t("inProgress"),
    filter: t('filter'),
    showAll: t('showAll'),
    showOnlySelected: t('showOnlySelected'),
    dev: t('dev'),
    transcriptionStarted: t('transcriptionStarted'),
    transcriptionStopped: t('transcriptionStopped'),
    replacedDict: t('replacedDict'),
    hour: t('hour'),
    minute: t('minute'),
    second: t('second'),
    apply: t('apply'),
    jumpToNewTimestampSuccess: t('jumpToNewTimestampSuccess'),
    jumpToEndSuccess: t('jumpToEndSuccess'),
    saveSuccess: t('saveSuccess'),
    saveFailed: t('saveFailed'),
    uploadDate: t("uploadDate"),
    status: t("status"),
    pasteVideo: t("pasteVideo"),
    downloading: t("downloading"),
    uploading: t("uploading"),
    transcribing: t("transcribing"),
    transcribed: t("transcribed"),
    ready: t("ready"),
    action: t("action"),
    success: t("success"),
    error: t("error"),
    fileTranscriptSettings: t("fileTranscriptSettings"),
    person: t("person"),
    people: t("people"),
    statistics: t("statistics"),
    daily: t("daily"),
    monthly: t("monthly"),
    search: t("search"),
    today: t("today"),
    yesterday: t("yesterday"),
    thisWeek: t("thisWeek"),
    thisMonth: t("thisMonth"),
    thisQuarter: t("thisQuarter"),
    thisYear: t("thisYear"),
    total: t("total"),
    minutes: t('minutes'),
    hours: t('hours'),
    totalCost: t('totalCost'),
    otherDict: t('otherDict'),
    organizationDict: t('organizationDict'),
    personDict: t('personDict'),
    locationDict: t('locationDict'),
    wrongEmailPassword: t('wrongEmailPassword'),
    wrongEmailFormat: t('wrongEmailFormat'),
    resetPasswordSuccess: t('resetPasswordSuccess'),
    userNotFound: t('userNotFound'),
    backToLogin: t('backToLogin'),
    meetingClose: t('meetingClose'),
    deleteSuccess: t('deleteSuccess'),
    deleteFail: t('deleteFail'),
    uploaded: t('uploaded'),
    callDate: t('callDate'),
    pickUp: t('pickUp'),
    dontPickUp: t('dontPickUp'),
    internal: t('internal'),
    external: t('external'),
    caller: t('caller'),
    callee: t('callee'),
    filterResults: t('filterResults'),
    searchResults: t('searchResults'),
    results: t('results'),
    keyword: t('keyword'),
    minDuration: t('minDuration'),
    advancedSearch: t('advancedSearch'),
    hideAdvancedSearch: t('hideAdvancedSearch'),
    fromNumber: t('fromNumber'),
    toNumber: t('toNumber'),
    agentNumber: t('agentNumber'),
    calls: t('calls'),
    showMore: t('showMore'),
    showLess: t('showLess'),
    all: t('all'),
    uploadDateFuture: t('uploadDateFuture'),
    followUp: t('followUp'),
    followUpNumber: t('followUpNumber'),
    totalWords: t('totalWords'),
    phoneNumber: t('phoneNumber'),
    noAudioFound: t('noAudioFound'),
    noMetadataFound: t('noMetadataFound'),
    noSpeechData: t('noSpeechData'),
    monitor: t('monitor'),
  };
};
