/* eslint-disable */
import requestAdmin from "utils/requestAdmin";

export const userList = async () => {
  return requestAdmin(`users`, {}, { method: "GET" })
};  

export const updateUser = async (id,payload) => {
  return requestAdmin(`users/${id}`, payload, { method: "PUT" })
};  

export const deleteUser = async (id) => {
  return requestAdmin(`users/${id}`, {}, { method: "DELETE" })
};  

export const createUser = async (payload) => {
  return requestAdmin(`users`, payload, { method: "POST" })
};  

