/* eslint-disable */
import axios from "axios";
import _ from "lodash";
import firebase from 'firebase';
import { ADMIN_TOKEN, IS_NTTDATA_ON_PREMISE } from "./constants";

const getToken = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(null);
    }, 10000);

    const unregister = firebase.auth().onAuthStateChanged(async (user) => {
      resolve(await user.getIdToken());
      unregister();
    })
  })
}

const getAxiosInstance = async (options = {}) => {
  let token;
  if (IS_NTTDATA_ON_PREMISE) {
    token = localStorage.getItem(ADMIN_TOKEN);
  } else {
    token = await getToken();
  }

  const headers = {
    ...(token ? { Authorization: "Bearer " + token } : {}),
  };

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API + "/api",
    headers,
    ...options,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if ([200, 201].includes(response.status)) {
        const result = response.data;
        if (_.isObject(result.isObject)) {
          result.statusCode = response.status;
        }
        return response.data;
      }
      return Promise.reject(response);
    },
    (error) => {
      let { code } = error.response.data || error.response.status;
      code = code || error.response.status;
      if (code) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.response.data);
    }
  );

  return axiosInstance;
};

const request = async (url, data = {}, options = {}) => {
  try {
    const { method = "POST", ...opts } = options;
    const API = await getAxiosInstance(opts);
    switch (method) {
      case "GET":
        return API.get(url, { params: data });
      case "PUT":
        return API.put(url, data);
      case "DELETE":
        return API.delete(url, data);
      default:
        return API.post(url, data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default request;