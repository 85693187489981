import React, { useEffect, memo, useState, useRef, Fragment } from "react";
import WordNode from "components/WordNode";
import WordWrap from "components/WordWrap";
import {
  changeTransToNewSpeaker,
  diffToSegments,
  getWordFromSelection,
} from "utils/old-editor";
import "./style.less";
import {
  filterSentences,
  isEqualAllProps,
  speakerToString,
  XRegExp,
} from "utils/utils";
import moment from "moment-timezone";
import loading from "assets/loading.gif";
import { db } from "utils/firebase";
import { Button, Dropdown, Menu } from "antd";
import { PlaySquareOutlined, UserSwitchOutlined } from "@ant-design/icons";
import {
  FALLBACK_TIME,
  HIGHLIGHT_WORD_INTERVAL,
  IS_FUNIX,
  IS_NTTDATA_ON_PREMISE,
  IS_SAFARI,
} from "utils/constants";
import { useCallback } from "react";
import { transcriptDelete } from "apis/transcript";
import { editSessionTranscript } from "apis/meeting";
import { isEmpty, isUndefined } from "lodash";

function getCaretCharacterOffsetWithin(element) {
  let caretOffset = 0;
  const sel = window.getSelection();
  if (sel.rangeCount > 0) {
    const range = window.getSelection().getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.startContainer, range.startOffset);
    caretOffset = preCaretRange.toString().length;
  }
  return caretOffset;
}

const TranscriptEditor = ({
  mode,
  filterAll,
  changeTime,
  sentences,
  setSentences,
  vidRef,
  videoId,
  isSpeaking,
  meeting,
}) => {
  const divRef = useRef(null);
  const popupRef = useRef(null);
  const hasChangedRef = useRef(false);
  const popupParamsRef = useRef({
    play: null,
    changeSpeaker: {
      sentenceOffset: null,
      changedValue: null,
    },
  });
  const [popupCurSpeaker, setPopupCurSpeaker] = useState(null);
  const [curWordPosition, setCurWordPosition] = useState([-1, -1]);
  const onTimeClick = useCallback(
    (event) => {
      if (divRef.current) divRef.current.blur();
      let element = event.nativeEvent.target;
      while (!element.hasAttribute("data-start") && element.parentElement) {
        element = element.parentElement;
      }

      if (element.hasAttribute("data-start")) {
        const time = parseFloat(element.getAttribute("data-start"));
        setCurWordPosition([-1, -1]);
        changeTime(time);
      }
    },
    [changeTime]
  );
  const handleChangeSpeaker = useCallback(
    (value, oldSpeaker, index) => {
      // console.log("value", value);
      if (value.all) {
        setSentences((e) => {
          const newItem = [...e];
          newItem.forEach((item) => {
            if (item.speaker === oldSpeaker) {
              item.speaker = value.speaker;
            }
          });
          return [...newItem];
        });
      } else {
        setSentences((e) => {
          const newItem = [...e];
          newItem[index].speaker = value.speaker;
          return [...newItem];
        });
      }
      localStorage.setItem(
        `nami-transcript-${videoId}`,
        JSON.stringify({ sentences })
      );
    },
    [sentences, setSentences, videoId]
  );

  const speakers = [...new Set(sentences.map((s) => s.speaker))];

  const textOverlapTitleRegex = new RegExp(
    `\\n(${speakers
      .map((s) => speakerToString(s))
      .join("|")})\\n(([0-9][0-9]:)?[0-9][0-9]:[0-9][0-9])\\n`,
    "m"
  );

  useEffect(() => {
    // const autoSaveInterval = setInterval(() => {
    //   localStorage.setItem(
    //     `nami-transcript-${videoId}`,
    //     JSON.stringify({ sentences })
    //   );
    // }, 60000);

    const highlightInterval = setInterval(() => {
      if (!vidRef?.current || vidRef?.current?.paused) return;

      const curWord = sentences[curWordPosition[0]]?.words[curWordPosition[1]];
      const nextWord = !curWord
        ? null
        : curWordPosition[1] + 1 >= sentences[curWordPosition[0]].length
          ? sentences[curWordPosition[0] + 1]?.words[curWordPosition[1]]
          : sentences[curWordPosition[0]].words[curWordPosition[1] + 1];
      const curTime = vidRef.current?.currentTime;
      if (curWord && curWord?.start <= curTime && curWord?.end >= curTime) {
        return;
      } else if (
        nextWord &&
        nextWord?.start <= curTime &&
        nextWord?.end >= curTime
      ) {
        setCurWordPosition([curWordPosition[0], curWordPosition[1] + 1]);
      } else {
        sentences.forEach((sentence, i) => {
          let isBreak = false;
          const words = sentence.words;
          if (!words) return;
          words.forEach((word, j) => {
            const curTime = vidRef.current?.currentTime;
            if (word?.start <= curTime && word?.end >= curTime) {
              if (curWordPosition[0] !== i || curWordPosition[1] !== j) {
                setCurWordPosition([i, j]);
                isBreak = true;
                return;
              }
            }
          });
          if (isBreak) return;
        });
      }
    }, HIGHLIGHT_WORD_INTERVAL);
    return () => {
      // clearInterval(autoSaveInterval);
      clearInterval(highlightInterval);
    };
  }, [curWordPosition, sentences, vidRef, videoId]);

  let paragraph = [];
  let oldItem = null;
  let paragraphOffsets = [];
  const filler = {
    speaker: -1,
  };
  let realIndex = -1;
  const contentEditable =
    mode === "edit" && window.location.pathname.split("/")[1] === "user";
  return (
    <div className="oldTranscriptEditor">
      <div
        className="transcriptEditor"
        style={
          !contentEditable
            ? { WebkitUserModify: "read-only", maxWidth: "none" }
            : {}
        }
        ref={divRef}
        contentEditable={contentEditable}
        suppressContentEditableWarning
        // Prevent enter key from creating a new line
        onKeyDown={(e) => {
          const sel = window.getSelection();
          const text = sel.toString();
          if (e.key === "Enter") {
            e.preventDefault();
          }
          if (e.key === "Backspace") {
            if (
              sel.anchorOffset === 0 ||
              text.startsWith("\n") ||
              textOverlapTitleRegex.test(text)
            ) {
              e.preventDefault();
            }
          }
          if (e.key === "Delete") {
            if (
              sel.anchorOffset === sel.anchorNode.length ||
              text.startsWith("\n")
            ) {
              e.preventDefault();
            }
          }
        }}
        onMouseDown={(e) => {
          if (
            !popupRef.current ||
            popupRef.current.contains(e.target) ||
            e.target?.id?.startsWith("change-speaker")
          )
            return;
          popupRef.current.classList.remove("popup-show");
        }}
        onMouseUp={(e) => {
          const sel = window.getSelection();
          const text = sel.toString();
          if (text && text.trim() && !text.startsWith("\n")) {
            if (
              !popupRef.current ||
              popupRef.current.classList.contains("popup-show")
            )
              return;
            let wordNodeElement = sel.anchorNode.parentElement;
            let id = null;
            while (
              (id = wordNodeElement.getAttribute("id")) &&
              !id.startsWith("word-node")
            ) {
              wordNodeElement = wordNodeElement.parentElement;
            }
            if (!id) return;
            const offsetMetadata = id
              .split("-")
              .slice(2)
              .map((e) => +e);
            setPopupCurSpeaker(sentences[offsetMetadata[0]].speaker);
            const anchorOffset = getCaretCharacterOffsetWithin(wordNodeElement);

            popupRef.current.childNodes.forEach((child) => {
              if (child.getAttribute("id") === "popup-play") {
                const sentenceOffset = offsetMetadata[0];
                const word = getWordFromSelection(
                  sentences,
                  sentenceOffset,
                  anchorOffset
                );
                if (word) {
                  popupParamsRef.current.play = word.start - FALLBACK_TIME;
                }
              } else if (child.getAttribute("id") === "popup-change-speaker") {
                const wordNodeText = wordNodeElement.innerText;
                const isSelectionNotSplitWord =
                  (!wordNodeText[anchorOffset - 1] ||
                    wordNodeText[anchorOffset - 1] === " " ||
                    text[0] === " ") &&
                  (!wordNodeText[anchorOffset + text.length] ||
                    wordNodeText[anchorOffset + text.length] === " " ||
                    text[text.length - 1] === " ");
                if (
                  textOverlapTitleRegex.test(text) ||
                  !isSelectionNotSplitWord
                ) {
                  child.style.display = "none";
                  return;
                } else {
                  child.style.display = "inline-block";
                }
                const org = wordNodeElement.innerText;
                const modified =
                  org.slice(0, anchorOffset) +
                  org.slice(anchorOffset + text.length);
                popupParamsRef.current.changeSpeaker = {
                  sentenceOffset: offsetMetadata,
                  changedValue: modified,
                };
              }
            });
            popupRef.current.classList.add("popup-show");
            const rect = sel.getRangeAt(0).getBoundingClientRect();
            const { x, y } = e.currentTarget.getBoundingClientRect();
            popupRef.current.style.left =
              rect.x +
              rect.width / 2 -
              popupRef.current.clientWidth / 2 -
              x +
              "px";
            let top = rect.y - y - 50;
            if (top < 0) {
              top = rect.y + rect.height - y + 10;
            }
            popupRef.current.style.top = top + "px";
          }
        }}
        onInput={() => {
          hasChangedRef.current = true;
        }}
        onBlur={(e) => {
          if (
            !e.currentTarget.contains(e.relatedTarget) &&
            !e.relatedTarget?.id?.startsWith("change-speaker")
          ) {
            // eslint-disable-next-line no-unused-expressions
            popupRef.current?.classList?.remove("popup-show");
          }
          if (e.target !== e.currentTarget || !hasChangedRef.current) return;
          hasChangedRef.current = false;
          const speakerLines = [];
          e.currentTarget.childNodes.forEach((item) => {
            if (item.className === "transcriptEditor-title") {
              speakerLines.push(item.innerText);
            }
          });

          // When deleting one whole paragraph, there will be two empty lines,
          // replace them with one empty line
          let res = e.currentTarget.innerText
            .replaceAll("\u00A0", " ")
            .replace("\n\n", "\n");
          speakerLines.forEach((item) => {
            if (IS_SAFARI) {
              res = XRegExp.replaceLb(
                res,
                "(?<=(\\n)?)",
                new RegExp(`${item}(?=\\n)`, "igm"),
                ""
              );
              return;
            }
            const regex = new RegExp(`(?<=(\\n)?)${item}(?=\\n)`, "igm");
            res = res.replaceAll(regex, "");
          });
          const changedParagraphs = res
            .split("\n")
            .filter((item) => item.length > 0);
          // console.log(res);
          setSentences((e) =>
            filterSentences(
              diffToSegments(e, paragraphOffsets, changedParagraphs)
            )
          );
        }}
      >
        {meeting
          ? [...sentences, filler]?.map((items, index) => {
            if (items.transcript === "") {
              return null;
            } else {
              const localParagraph = paragraph;
              const paragraphItems = sentences.filter((_, i) =>
                localParagraph.includes(i)
              );
              const localTranscripts = paragraphItems.map((i) => i.transcript);
              const localWords = paragraphItems.map((i) => i.words);
              const localOldItem = oldItem;
              oldItem = items;
              paragraph = [index];
              if (
                localTranscripts.length === 0 ||
                (!filterAll && !localOldItem.checked && localOldItem.speaker)
              )
                return null;
              if (localOldItem.speaker) {
                paragraphOffsets.push([
                  localParagraph[0],
                  localParagraph[localParagraph.length - 1],
                ]);
              }
              realIndex++;
              return (
                <Fragment key={`word-fragment-${realIndex}`}>
                  <WordWrap
                    speaker={localOldItem.speaker}
                    checked={
                      isUndefined(localOldItem.checked)
                        ? localOldItem.transcript
                          ? false
                          : undefined
                        : localOldItem.checked
                    }
                    setChecked={(checked) => {
                      setSentences((e) => {
                        if (!meeting) return e;
                        const newItem = JSON.parse(JSON.stringify(e));
                        localParagraph.forEach((item) => {
                          newItem[item].checked = checked;

                          if (mode === "live") {
                            if (IS_NTTDATA_ON_PREMISE) {
                              editSessionTranscript(videoId, newItem[item].id, {
                                checked,
                              });
                            } else {
                              db.collection("meetings")
                                .doc(videoId)
                                .collection("sentencesLiveEdited")
                                .doc(newItem[item].id)
                                .set({
                                  checked,
                                });
                            }
                          }
                        });
                        return newItem;
                      });
                    }}
                    start={localOldItem.start}
                    timestamp={localOldItem.timestamp}
                    status={localOldItem.status}
                    onTimeClick={onTimeClick}
                    handleChangeSpeaker={handleChangeSpeaker}
                    index={realIndex}
                    key={`word-wrap-${realIndex}`}
                  ></WordWrap>
                  <WordNode
                    transcripts={localTranscripts}
                    words={localWords}
                    sentenceOffset={localParagraph[0]}
                    highlight={curWordPosition}
                    setSentences={setSentences}
                    index={realIndex}
                    key={`word-Node-${realIndex}`}
                    last={realIndex === sentences.length - 1}
                    isSpeaking={isSpeaking}
                    speaker={localOldItem.speaker}
                  />
                </Fragment>
              );
            }
          })
          : [...sentences, filler]?.map((items, index) => {
            if (items.speaker === sentences[index - 1]?.speaker) {
              paragraph.push(index);
              return null;
            } else {
              const localParagraph = paragraph;
              const paragraphItems = sentences.filter((_, i) =>
                localParagraph.includes(i)
              );
              const localTranscripts = paragraphItems.map((i) => i.transcript);
              const localWords = paragraphItems.map((i) => i.words);
              const localOldItem = oldItem;
              oldItem = items;
              paragraph = [index];
              if (localTranscripts.length === 0) return null;
              paragraphOffsets.push([
                localParagraph[0],
                localParagraph[localParagraph.length - 1],
              ]);
              realIndex++;
              return (
                <Fragment key={`word-fragment-${realIndex}`}>
                  <WordWrap
                    speaker={localOldItem?.speaker}
                    start={localOldItem?.start}
                    onTimeClick={onTimeClick}
                    handleChangeSpeaker={handleChangeSpeaker}
                    index={realIndex}
                    key={`word-wrap-${realIndex}`}
                  />
                  <WordNode
                    transcripts={localTranscripts}
                    words={localWords}
                    sentenceOffset={localParagraph[0]}
                    highlight={curWordPosition}
                    setSentences={setSentences}
                    index={realIndex}
                    divId={`word-node-${localParagraph[0]}-${localParagraph[localParagraph.length - 1]
                      }`}
                  />
                </Fragment>
              );
            }
          })}
        {Object.entries(isSpeaking).map(([key, value], index) => {
          if ((value.is_speaking ?? value.isSpeaking) && key !== sentences[sentences.length - 1].speaker) {
            return (
              <Fragment key={`speaking-fragment-${key}`}>
                <WordWrap
                  speaker={key}
                  checked={false}
                  setChecked={() => { }}
                  start={0}
                  timestamp={moment().unix()}
                  status={0}
                  onTimeClick={() => { }}
                  handleChangeSpeaker={() => { }}
                  index={sentences.length + index}
                  key={`word-wrap-${sentences.length + index}`}
                ></WordWrap>
                <div
                  className="transcriptEditor-editor"
                  style={{
                    backgroundColor:
                      (sentences.length + index) % 2 === 0 ? "#f8f8f8" : "#fff",
                  }}
                >
                  <img src={loading} alt="speaking" style={{ height: "10px" }} />
                </div>
              </Fragment>
            );
          }
          return null;
        })}
        {!meeting && (
          <div
            ref={popupRef}
            className="transcriptEditor-popup"
            contentEditable={false}
          >
            <Button
              id="popup-play"
              type="default"
              icon={<PlaySquareOutlined />}
              onClick={() => {
                const { play } = popupParamsRef.current;
                if (play) changeTime(play);
                window.getSelection().removeAllRanges();
                popupRef.current.classList.remove("popup-show");
              }}
            />

            {!IS_FUNIX && <Dropdown
              trigger={["click"]}
              overlay={
                <Menu id="change-speaker-menu">
                  {speakers.map(
                    (s, index) =>
                      s !== popupCurSpeaker && (
                        <Menu.Item
                          id={`change-speaker-${index}`}
                          key={index}
                          onClick={() => {
                            // console.log("change speaker");
                            const { sentenceOffset, changedValue } =
                              popupParamsRef.current.changeSpeaker;
                            if (
                              sentenceOffset &&
                              typeof changedValue === "string"
                            ) {
                              setSentences((e) =>
                                changeTransToNewSpeaker(
                                  e,
                                  sentenceOffset,
                                  changedValue,
                                  s
                                )
                              );
                            }
                            popupRef.current.classList.remove("popup-show");
                          }}
                        >
                          {speakerToString(s)}
                        </Menu.Item>
                      )
                  )}
                </Menu>
              }
            >
              <Button
                id="popup-change-speaker"
                type="default"
                icon={<UserSwitchOutlined />}
              />
            </Dropdown>}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TranscriptEditor);
