import {
  Card,
  Checkbox,
  Collapse,
  Divider,
  Skeleton,
  Space,
  Typography,
} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import {
  changeTime,
  execWord,
  getKeywordBackgroundColor,
  getKeywordTextColor,
  getWordAtOffset,
} from "components/TranscriptEditorV2/util";
import useAuth from "hooks/useAuth";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { IS_FUNIX } from "utils/constants";
import { db } from "utils/firebase";
import { ColorPicker } from "../KeywordSpot";

const { REACT_APP_TENANT_ID } = process.env;

const MatchTag = ({
  id,
  checked,
  match,
  setKeywordHighlights,
  setJump,
  mediaRef,
  keywordCategory,
}) => {
  return (
    <CheckableTag
      style={
        checked
          ? {
              color: match.textColor,
              background: match.backgroundColor,
            }
          : {}
      }
      className="keyword-panel-tag"
      checked={checked}
      onChange={(checked) => {
        if (checked) {
          setKeywordHighlights((highlights) => {
            if (!highlights[keywordCategory.id]) {
              highlights[keywordCategory.id] = {};
            }
            highlights[keywordCategory.id][id] = {
              id,
              from: [match.sentenceIndex, match.offset],
              to: [match.sentenceIndex, match.offset + match.text.length],
              properties: {
                label: keywordCategory.label,
                textColor: match.textColor,
                backgroundColor: match.backgroundColor,
              },
            };
            return _.cloneDeep(highlights);
          });
          setJump([match.sentenceIndex, match.offset]);
          changeTime(mediaRef.current, match.word.start);
        } else {
          setKeywordHighlights((highlights) =>
            _.omit(highlights, `${keywordCategory.id}.${id}`)
          );
        }
      }}
    >
      {match.text}
    </CheckableTag>
  );
};

const KeywordsPanel = ({
  mediaRef,
  sentences,
  keywordHighlights,
  setKeywordHighlights,
  setJump,
}) => {
  const [keywordCategories, setKeywordCategories] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    let query = db.collection("keywords");

    if (IS_FUNIX) query = query.where("tenantId", "==", REACT_APP_TENANT_ID);
    else query = query.where("userId", "==", user?.uid);

    query.get().then((doc) => {
      if (!doc.empty) {
        setKeywordCategories(
          doc.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      }
    });
  }, []);

  const matches = useMemo(() => {
    if (keywordCategories && sentences) {
      const matches = [];
      keywordCategories.forEach((keywordCategory) => {
        const categoryMatches = [];
        let index = 0;
        (keywordCategory?.words ?? []).forEach((keyword, keywordIndex) => {
          const { text } = keyword;
          if (text.trim().length === 0) {
            return;
          }
          const wordRegex = new RegExp(text.normalize("NFC"), "igm");
          sentences.forEach((sentence, sentenceIndex) => {
            const handleMatch = (match) => {
              const matchData = {
                keywordIndex,
                text: match[0],
                id: index++,
                offset: match.index,
                sentenceIndex,
                textColor: getKeywordTextColor(keyword, keywordCategory),
                backgroundColor: getKeywordBackgroundColor(
                  keyword,
                  keywordCategory
                ),
                word: getWordAtOffset(sentence.words, match.index, {
                  skipIf: "last",
                })?.word,
              };
              categoryMatches.push(matchData);
            };
            if (keyword.isRegex) {
              let match = null;
              while ((match = wordRegex.exec(sentence.transcript)) !== null) {
                handleMatch(match);
              }
            } else {
              execWord(
                keyword.text.trim().normalize("NFC"),
                sentence.transcript.trim().normalize("NFC"),
                handleMatch
              );
            }
          });
        });
        matches.push(_.isEmpty(categoryMatches) ? undefined : categoryMatches);
      });
      return matches;
    }

    return null;
  }, [sentences, keywordCategories]);

  if (!keywordCategories || !sentences) {
    if (!sentences) {
      return <Skeleton active loading />;
    }
    return null;
  }

  const availableMatches = _.compact(matches);

  if (availableMatches.length === 0) {
    return (
      <div className="edit-video-keyword keyword-panel">
        <label style={{ marginTop: 8, marginBottom: 12, display: "block" }}>
          <Typography.Text
            style={{ textTransform: "uppercase", marginBottom: 8 }}
          >
            No keyword matched
          </Typography.Text>
        </label>
        <Divider style={{ margin: 0 }} />
      </div>
    );
  }

  const groupedMatches = matches.map((match) => {
    if (match) {
      return _.groupBy(match, "keywordIndex");
    }
    return match;
  });

  return (
    <div className="edit-video-keyword keyword-panel">
      <label style={{ marginTop: 8, marginBottom: 12, display: "block" }}>
        <Typography.Text
          style={{ textTransform: "uppercase", marginBottom: 8 }}
        >
          Keywords match ({availableMatches.length})
        </Typography.Text>
      </label>
      <Collapse destroyInactivePanel={false} style={{ userSelect: "none" }}>
        {keywordCategories.map((keywordCategory, index) =>
          matches[index] ? (
            <Collapse.Panel
              className="keyword-panel-header"
              key={keywordCategory.id}
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {keywordCategory.label} ({_.size(groupedMatches[index])})
                    <ColorPicker
                      readOnly
                      title="Text color"
                      value={getKeywordTextColor(null, keywordCategory)}
                    />
                    <ColorPicker
                      readOnly
                      title="Background color"
                      value={getKeywordBackgroundColor(null, keywordCategory)}
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        const checked = e.target.checked;
                        if (checked) {
                          setKeywordHighlights((highlights) => {
                            highlights[keywordCategory.id] = _.keyBy(
                              matches[index].map((match, index) => ({
                                id: index,
                                from: [match.sentenceIndex, match.offset],
                                to: [
                                  match.sentenceIndex,
                                  match.offset + match.text.length,
                                ],
                                properties: {
                                  label: keywordCategory.label,
                                  textColor: match.textColor,
                                  backgroundColor: match.backgroundColor,
                                },
                              })),
                              "id"
                            );
                            return _.cloneDeep(highlights);
                          });
                        } else {
                          setKeywordHighlights((highlights) =>
                            _.omit(highlights, keywordCategory.id)
                          );
                        }
                      }}
                      indeterminate={
                        _.size(keywordHighlights[keywordCategory.id]) > 0 &&
                        _.size(keywordHighlights[keywordCategory.id]) <
                          matches[index].length
                      }
                      checked={
                        _.size(keywordHighlights[keywordCategory.id]) ===
                        matches[index].length
                      }
                    >
                      Highlight all
                    </Checkbox>
                  </div>
                </div>
              }
            >
              <Space
                style={{ width: "100%" }}
                direction="vertical"
                size="small"
              >
                {_.keys(groupedMatches[index] ?? {}).map((keywordIndex) => (
                  <Card
                    style={{ width: "100%" }}
                    bordered
                    size="small"
                    key={index}
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          {keywordCategory.words[keywordIndex].text} (
                          {groupedMatches[index][keywordIndex].length})
                          {keywordCategory.words[keywordIndex].customColor ? (
                            <>
                              <ColorPicker
                                readOnly
                                title="Text color"
                                value={getKeywordTextColor(
                                  keywordCategory.words[keywordIndex],
                                  keywordCategory
                                )}
                              />
                              <ColorPicker
                                readOnly
                                title="Background color"
                                value={getKeywordBackgroundColor(
                                  keywordCategory.words[keywordIndex],
                                  keywordCategory
                                )}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                    }
                  >
                    {groupedMatches[index][keywordIndex].map((match) => (
                      <MatchTag
                        checked={_.has(
                          keywordHighlights,
                          `${keywordCategory.id}.${match.id}`
                        )}
                        id={match.id}
                        keyword={keywordCategory.words[index]}
                        key={match.id}
                        keywordCategory={keywordCategory}
                        match={match}
                        mediaRef={mediaRef}
                        setKeywordHighlights={setKeywordHighlights}
                        setJump={setJump}
                      />
                    ))}
                  </Card>
                ))}
              </Space>
            </Collapse.Panel>
          ) : (
            <div key={index}></div>
          )
        )}
      </Collapse>
    </div>
  );
};

export default React.memo(KeywordsPanel);
