/* eslint-disable */
import request from "utils/request";
import requestAdmin from "utils/requestAdmin";

export const dictList = async (userId) => {
  return request(`dicts`, {userId}, { method: "GET" })
};

export const createDicts = async (payload) => {
  return request(`dicts`, payload, { method: "POST" })
};

export const dictListAdmin = async (userId) => {
  return requestAdmin(`dicts`, {userId}, { method: "GET" })
};

export const createDictsAdmin = async (payload) => {
  return requestAdmin(`dicts`, payload, { method: "POST" })
};
