import { isAdminRoute } from "utils/utils";
import request from "utils/request";
import requestAdmin from "utils/requestAdmin";

export const getFileDownloadURL = async (filePath) => {
    if (isAdminRoute()) {
        return requestAdmin(`files/${filePath}`, {}, { method: "GET" });
    }
    return request(`files/${filePath}`, {}, { method: "GET" });
};
