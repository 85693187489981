import request from "utils/request";
import requestAdmin from "utils/requestAdmin";
import { isAdminRoute } from "utils/utils";

export const getAll = async () => {
  if(isAdminRoute()){
    return requestAdmin(`transcript-online-all`, {}, { method: "GET" });
  }
  return request(`transcript-online-all`, {}, { method: "GET" });
};

export const getSession = async (id) => {
  if(isAdminRoute()){
    return requestAdmin(`sessions/${id}`, {}, { method: "GET" });
  }
  return request(`sessions/${id}`, {}, { method: "GET" });
};

export const updateSession = async (id, payload) => {
  if(isAdminRoute()){
    return requestAdmin(`sessions/${id}`, payload, { method: "PUT" });
  }
  return request(`sessions/${id}`, payload, { method: "PUT" });
};

export const deleteSession = async (id) => {
  if(isAdminRoute()){
    return requestAdmin(`sessions/${id}`, {}, { method: "DELETE" });
  }
  return request(`sessions/${id}`, {}, { method: "DELETE" });
};

export const editSessionTranscript = async (sessionId, id, payload) => {
  if(isAdminRoute()){
    return requestAdmin(`sessions/${sessionId}/transcripts/${id}`, payload, { method: "PUT" });
  }
  return request(`sessions/${sessionId}/transcripts/${id}`, payload, { method: "PUT" });
};


export const editSessionTranscripts = async (id, payload) => {
  if(isAdminRoute()){
    return requestAdmin(`sessions/${id}/transcripts`, payload, { method: "PUT" });
  }
  return request(`sessions/${id}/transcripts`, payload, { method: "PUT" });
};

export const getAllSessions = async () => {
  if(isAdminRoute()){
    return requestAdmin(`sessions`, {}, { method: "GET" });
  }
  return request(`sessions`, {}, { method: "GET" });
};

export const getSessionTranscripts = async (sessionId) => {
  if(isAdminRoute()){
    return requestAdmin(`sessions/${sessionId}/transcripts`, {}, { method: "GET" });
  }
  return request(`sessions/${sessionId}/transcripts`, {}, { method: "GET" });
};

export const getSessionEditedTranscripts = async (sessionId) => {
  if(isAdminRoute()){
    return requestAdmin(`sessions/${sessionId}/transcripts/edited`, {}, { method: "GET" });
  }
  return request(`sessions/${sessionId}/transcripts/edited`, {}, { method: "GET" });
};