import {
  Button,
  Card,
  DatePicker,
  Divider,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import confirm from "antd/lib/modal/confirm";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { db } from "utils/firebase";

const AssignForm = ({ file }) => {
  const [annotatorList, setAnnotatorList] = useState([]);
  const [reviewerList, setReviewerList] = useState([]);
  const [approverList, setApproverList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [annotator, setAnnotator] = useState(file.annotator ?? "");
  const [annotatorDeadline, setAnnotatorDeadline] = useState(
    file.annotatorDeadline ? moment(file.annotatorDeadline) : null
  );

  const [reviewer, setReviewer] = useState(file.reviewer ?? "");
  const [reviewerDeadline, setReviewerDeadline] = useState(
    file.reviewerDeadline ? moment(file.reviewerDeadline) : null
  );

  const [approver, setApprover] = useState(file.approver ?? "");
  const [approverDeadline, setApproverDeadline] = useState(
    file.approverDeadline ? moment(file.approverDeadline) : null
  );

  const [assigning, setAssigning] = useState(false);
  const [resetting, setResetting] = useState(false);

  const handleAssign = async () => {
    setAssigning(true);
    try {
      await db
        .collection("files")
        .doc(file.id)
        .update({
          annotator: annotator ?? null,
          annotatorDeadline: annotatorDeadline
            ? annotatorDeadline.valueOf()
            : null,
          reviewer: reviewer ?? null,
          reviewerDeadline: reviewerDeadline
            ? reviewerDeadline.valueOf()
            : null,
          approver: approver ?? null,
          approverDeadline: approverDeadline
            ? reviewerDeadline.valueOf()
            : null,
        });
      message.success("Assign task successfully!");
    } catch {}
    setAssigning(false);
  };

  const handleResetAll = async () => {
    setResetting(true);
    try {
      await db.runTransaction(async (transaction) => {
        const fileRef = db.collection("files").doc(file.id);
        transaction.update(fileRef, {
          annotator: null,
          annotatorSubmitted: false,
          annotatorEdited: false,
          annotatorDeadline: null,
          reviewer: null,
          reviewerSubmitted: false,
          reviewerEdited: false,
          reviewerDeadline: null,
          approver: null,
          approverSubmitted: false,
          approverEdited: false,
          approverDeadline: null,
        });

        transaction.delete(fileRef.collection("edited").doc("annotator"));
        transaction.delete(fileRef.collection("edited").doc("reviewer"));
        transaction.delete(fileRef.collection("edited").doc("approver"));
      });
      message.success("Reset all data successfully!");
    } catch (err) {
      console.log(err);
    }
    setResetting(false);
  };

  const handleResetAnnotator = async () => {
    setResetting(true);
    try {
      await db.runTransaction(async (transaction) => {
        const fileRef = db.collection("files").doc(file.id);
        transaction.update(fileRef, {
          annotator: null,
          annotatorSubmitted: false,
          annotatorEdited: false,
          annotatorDeadline: null,
        });

        transaction.delete(fileRef.collection("edited").doc("annotator"));
      });
      message.success("Reset annotator data successfully!");
    } catch (err) {
      console.log(err);
    }
    setResetting(false);
  };

  const handleResetReviewer = async () => {
    setResetting(true);
    try {
      await db.runTransaction(async (transaction) => {
        const fileRef = db.collection("files").doc(file.id);
        transaction.update(fileRef, {
          reviewer: null,
          reviewerSubmitted: false,
          reviewerEdited: false,
          reviewerDeadline: null,
        });

        transaction.delete(fileRef.collection("edited").doc("reviewer"));
      });
      message.success("Reset reviewer data successfully!");
    } catch (err) {
      console.log(err);
    }
    setResetting(false);
  };

  const handleResetApprover = async () => {
    setResetting(true);
    try {
      await db.runTransaction(async (transaction) => {
        const fileRef = db.collection("files").doc(file.id);
        transaction.update(fileRef, {
          approver: null,
          approverSubmitted: false,
          approverEdited: false,
          approverDeadline: null,
        });

        transaction.delete(fileRef.collection("edited").doc("approver"));
      });
      message.success("Reset approver data successfully!");
    } catch (err) {
      console.log(err);
    }
    setResetting(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const users = (await db.collection("users").get()).docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setAnnotatorList(
          users.filter((user) => user.email.endsWith("@annotator.ai"))
        );
        setReviewerList(
          users.filter((user) => user.email.endsWith("@reviewer.ai"))
        );
        setApproverList(
          users.filter((user) => user.email.endsWith("@approver.ai"))
        );
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Card size="small" style={{ width: 400 }}>
      <h2>Assign Task</h2>
      <Divider />
      {loading ? (
        <Spin />
      ) : (
        <>
          <Space direction="vertical">
            <div>Annotator:</div>
            <Select
              disabled={file.annotatorSubmitted}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={annotator}
              onChange={setAnnotator}
              style={{ width: "100%" }}
              options={[
                { label: "None", value: "" },
                ...annotatorList.map((annotator) => ({
                  label: annotator.email,
                  value: annotator.email,
                })),
              ]}
            />
            <Space>
              <div>Deadline:</div>
              <DatePicker
                disabled={file.annotatorSubmitted}
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                value={annotatorDeadline}
                onChange={setAnnotatorDeadline}
              />
            </Space>
            <Button
              onClick={() => {
                confirm({
                  title: "Reset",
                  content: "Are you sure to reset all data?",
                  onOk: handleResetAnnotator,
                });
              }}
              loading={resetting}
            >
              Reset
            </Button>
          </Space>
          <Divider />
          <Space direction="vertical">
            <div>Reviewer:</div>
            <Select
              disabled={!file.annotatorSubmitted || file.reviewerSubmitted}
              style={{ width: "100%" }}
              value={reviewer}
              onChange={setReviewer}
              options={[
                { label: "None", value: "" },
                ...reviewerList.map((reviewer) => ({
                  label: reviewer.email,
                  value: reviewer.email,
                })),
              ]}
            />
            <Space>
              <div>Deadline:</div>
              <DatePicker
                disabled={!file.annotatorSubmitted || file.reviewerSubmitted}
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                value={reviewerDeadline}
                onChange={setReviewerDeadline}
              />
            </Space>
            <Button
              onClick={() => {
                confirm({
                  title: "Reset",
                  content: "Are you sure to reset all data?",
                  onOk: handleResetReviewer,
                });
              }}
              loading={resetting}
            >
              Reset
            </Button>
          </Space>
          <Divider />
          <Space direction="vertical">
            <div>Approver:</div>
            <Select
              disabled={!file.reviewerSubmitted || file.approverSubmitted}
              style={{ width: "100%" }}
              value={approver}
              onChange={setApprover}
              options={[
                { label: "None", value: "" },
                ...approverList.map((approver) => ({
                  label: approver.email,
                  value: approver.email,
                })),
              ]}
            />
            <Space>
              <div>Deadline:</div>
              <DatePicker
                disabled={!file.reviewerSubmitted || file.approverSubmitted}
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                value={approverDeadline}
                onChange={setApproverDeadline}
              />
            </Space>
            <Button
              onClick={() => {
                confirm({
                  title: "Reset",
                  content: "Are you sure to reset all data?",
                  onOk: handleResetApprover,
                });
              }}
              loading={resetting}
            >
              Reset
            </Button>
          </Space>
          <Divider orientationMargin={2} />
          <Row justify="end">
            <Space>
              <Button
                onClick={() => {
                  confirm({
                    title: "Reset all",
                    content: "Are you sure to reset all data?",
                    onOk: handleResetAll,
                  });
                }}
                loading={resetting}
              >
                Reset all
              </Button>
              <Button
                disabled={file.reviewerSubmitted && file.approverSubmitted}
                onClick={handleAssign}
                loading={assigning}
                type="primary"
              >
                Assign
              </Button>
            </Space>
          </Row>
        </>
      )}
    </Card>
  );
};

export default AssignForm;
